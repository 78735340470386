.pac-container {
    box-sizing: content-box;
    box-shadow: none;
    border-radius: 0;
    //border: 1px solid var(--em-light-blue);
    border: 1px solid $primary-500;
    border-top: none;
    margin-top: -1px;
    font-family: inherit;
    z-index: 100001;
}

.pac-icon {
    display: none;
}

.pac-item {
    padding: 8px 12px;
    min-height: 2rem;
    line-height: 2rem;
    font-size: 15px;
    // color: var(--em-dark-gray);
    border-top: 1px solid $ui-200;

    &:first-child {
        border-top: 1px solid $primary-500;
    }

    &:last-child {
        border-bottom: 1px solid $ui-200;
    }

    &:hover {
        background-color: #deebff;
    }
}

.pac-item-query {
    font-size: 17px;
    // color: var(--em-black);
    padding-right: 5px;
}

.pac-matched {
    @include font-weight(semi-bold);
}
