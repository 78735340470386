:root {
    /**
     * @styleguide Colors
     *  Text
     *  Footer background
     *  Link hover states
     *  Blue backgrounds
     */
    --em-black: #02024e;
    --brand-dark: #02024e;

    /**
     * @styleguide Colors
     *  Nav 1
     */
    --em-dark-gray: #666493;
    --neutral-primary: #666493;

    /**
     * @styleguide Colors
     *  Form lines
     *  Box outlines
     *  Divider lines
     *  Shadows
     */
    --em-medium-gray: #9b99bf;
    --neutral-shade: #9b99bf;

    /**
     * @styleguide Colors
     *  Gray text
     *  Dotted outlines on cards
     *  Inactive nav / icons
     *  Comparisson data
     *  Disabled text
     */
    --em-medium-gray2: #c8c7e1;
    --neutral-medium: #c8c7e1;

    /**
    *  @styleguide Colors
    *  Box shadow
    */
    --em-medium-gray3: #e7e9ec;

    /**
    *  @styleguide Colors
    *  Date picker
    */
    --em-light-gray: #ebedff;
    --neutral-light: #ebedff;

    /**
     * @styleguide Colors
     *  Background
     */
    --em-lightest-gray: #f7f8fc;
    --neutral-white: #f7f8fc;

    /**
     * @styleguide Colors
     *  Primary nav background
     *  Text on dark background
     */
    --em-white: #ffffff;
    --neutral-plain: #ffffff;

    /**
     * @styleguide Colors
     *  Roll over styles
     */
    --em-med-blue: #199fd5;
    --brand-primary: #199fd5;
    --brand-shade: #0794be;

    /**
     * @styleguide Colors
     *  Icons
     *  Buttons
     *  Links
     */
    --em-light-blue: #47c6ec;
    --brand-medium: #47c6ec;

    /**
     * @styleguide Colors
     *  TBD
     */
    --em-lightest-blue: #bfebf8;
    --brand-light: #bfebf8;
    /**
     * @styleguide Colors
     *  TBD
     */
    --em-alt-blue: #0794be;

    /**
     * @styleguide Colors
     *  TBD
     */
    --em-red: #ca3c5c;

    /**
     * @styleguide Colors
     *  Alert
     */
    --em-gold: #ffac28;
    --warning-primary: #ffac28;

    /**
     * @styleguide Colors
     *  Error
     */
    --em-error-red: #ca3c5c;
    --negative-primary: #ca3c5c;

    /**
     * @styleguide Colors
     *  Go
     */
    --em-green: #1abca6;
    --positive-primary: #1abca6;
    --positive-shade: #00ad93;
    --positive-light: #6fd0c0;

    /* font variables */

    /* rather big font stack but the idea is to make FOUC as less apparent as possible */
    --font-family-base: 'Calibre', 'Geneva-Bold', Helvetica, Frutiger, 'Frutiger Linotype', Univers,
        Calibri, 'Gill Sans', 'Gill Sans MT', 'Myriad Pro', Myriad, 'DejaVu Sans Condensed',
        'Liberation Sans', 'Nimbus Sans L', Tahoma, Geneva, 'Helvetica Neue', Helvetica, Arial,
        sans-serif;

    --font-size-base: 16px;
    --line-height-base: 1.41;

    --font-size-heading-h1-big: 3.5rem; /*56px*/
    --font-size-heading-h1: 3rem; /*48px*/
    --font-size-heading-h2: 2.5rem; /*40px*/
    --font-size-heading-h3: 2rem; /*32px*/
    --font-size-heading-extra-large: 3.5rem; /*56px*/
    --font-size-p1: 1rem; /*16px*/
    --font-size-p2: 0.875rem; /*14px*/
    --font-size-p3: 0.75rem; /*12px*/
    --font-size-nav1: 0.875rem; /*14px*/
    --font-size-nav2: 1.0625rem; /*17px*/
    --font-size-table-title: 1rem; /*16px*/
    --font-size-table-title-heading: 0.875rem; /*14px*/
    --font-size-p: 1rem; /*16px*/
    --font-size-mobile: 1rem; /*16px*/

    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;

    --font-weight-base: var(--font-weight-normal);

    /* links */

    --link-color: var(--em-light-blue);
    --link-font-size: var(--font-size-p1);
    --link-small-font-size: var(--font-size-p2);
    --link-text-decoration-valid: underline;
    --link-text-decoration-invalid: none;
    --link-color-disabled: var(--em-medium-gray2);

    /* font base - general */

    /* border variables */

    --border-color: var(--em-medium-gray3);
    --border-shadow-color: var(--em-medium-gray);
    --border-drop-shadow: 0 1px 3px 0 var(--border-shadow-color);
    --border-solid-gray: 1px solid var(--em-medium-gray);
    --border-dotted-blue: underline dotted var(--em-light-blue); /* for text decoration */

    /* width values */

    --width-medium: 825px;

    /* spacing values */
    --spacing-xtiny: 0.25rem; /* 4px */
    --spacing-tiny: 0.5rem; /* 8px */
    --spacing-xsmall: 0.75rem; /* 12px */
    --spacing-small: 1.125rem; /* 18px */
    --spacing-main: 1.5rem; /* 24px */
    --spacing-32: 2rem; /* 32px */
    --spacing-medium: 2.25rem; /* 36px */
    --spacing-large: 3rem; /* 48px */
    --spacing-xlarge: 4.75rem; /* 76px */

    /* z-index base values */

    --z-index-base: 1;
    --z-index-dropdown: 100;
    --z-index-sticky: 200;
    --z-index-fixed: 300;
    --z-index-modal-backdrop: 400;
    --z-index-modal: 420;
    --z-index-popover: 500;
    --z-index-tooltip: 600;

    /* component variables */

    --page-background-color: var(--em-lightest-gray);
    --page-header-background-color: var(--em-white);
    --page-header-border-color: var(--em-medium-gray);
    --page-content-padding-sides: var(--spacing-medium);
    --page-content-padding-sides-thin: var(--spacing-small);
    --page-content-margin-sides: auto;
    --page-content-padding-bottom: var(--spacing-xlarge);
    --page-content-padding-bottom-thin: var(--spacing-main);
    --page-content-padding-top: var(--spacing-main);
    --page-content-padding-top-thin: var(--spacing-small);

    /* footer */

    --page-footer-background-color: var(--em-black);

    /* navigation */

    --nav-main-link-color: var(--em-dark-gray);
    --nav-main-link-active-color: var(--em-light-blue);
    --nav-main-link-disabled-color: var(--em-medium-gray2);
    --nav-main-font-size: 0.875rem; /* 14px */
    --nav-main-font-weight: var(--font-weight-semibold);
    --nav-main-link-cta-font-size: 0.9375rem; /* 15px */
    --nav-main-link-cta-font-weight: var(--font-weight-bold);
    --nav-main-link-cta-color: var(--em-light-blue);
    --nav-main-link-cta-background-color: var(--em-white);

    --nav-user-link-color: var(--em-black);
    --nav-user-link-active-color: var(--em-black);
    --nav-user-link-disabled-color: var(--em-medium-gray2);
    --nav-user-link-font-size: 0.937rem;
    --nav-user-non-link-font-size: 1.062rem;
    --nav-user-dropdown-background-color: var(--em-white);

    /* buttons */

    --button-font-size: 0.875rem; /* 14px */
    --button-font-size-large: 1.1875rem; /* 19px */
    --button-color: var(--em-white);
    --button-text-color: var(--em-light-blue);
    --button-text-hover-color: var(--em-med-blue);
    --button-background-color: var(--em-light-blue);
    --button-border-color: var(--em-light-blue);
    --button-background-hover-color: var(--em-med-blue);
    --button-background-disabled-color: var(--em-medium-gray2);
    --button-border-disabled-color: var(--em-medium-gray2);
    --button-background-outline-color: var(--em-white);
    --button-background-outline-hover-color: var(--em-med-blue);
    --button-border-outline-hover-color: var(--em-med-blue);

    /* featured option */

    --featured-option-border: var(--border-solid-gray);
    --featured-option-background: var(--em-white);
    --featured-option-border-color-selected: var(--em-light-blue);

    /* checkbox */

    --checkbox-label-font-size: 1.0625rem; /* 17px */
    --checkbox-input-top-position: 0.125rem; /* 2px */
    --checkbox-input-left-position: 0.125rem; /* 2px */
    --checkbox-label-padding: var(--spacing-xsmall) 0; /* 12px 0 */
    --checkbox-label-color: var(--em-black);
    --checkbox-label-disabled-color: var(--em-medium-gray2);
    --checkbox-label-before-width: 1.125rem; /*18px*/
    --checkbox-label-before-height: 1.125rem; /*18px*/
    --checkbox-label-before-border: 1px solid #85878a;
    --checkbox-label-before-margin-right: 1rem; /*16px*/
    --checkbox-label-before-checked-and-focused: 1px solid var(--em-light-blue);

    /* radio button */

    --radio-input-top-position: 0.125rem; /* 2px */
    --radio-input-left-position: 0.125rem; /* 2px */
    --radio-label-padding: var(--spacing-tiny) var(--spacing-tiny) var(--spacing-tiny) 0; /* 12px 12px 12px 0 */
    --radio-label-before-width: 1.125rem; /*18px*/
    --radio-label-before-height: 1.125rem; /*18px*/
    --radio-label-before-border: 1px solid var(--em-medium-gray3);
    --radio-label-before-magin-right: 1rem; /*16px*/
    --radio-label-before-checked-and-focused: 1px solid var(--em-light-blue);

    /* form fields */

    --form-field-description-color: var(--em-dark-gray);
    --form-field-label-min-height: 1em;
    --form-field-min-width: 6.25rem; /* 100px */
    --form-field-max-width: 21.625rem; /* 346px */

    /* text field */

    --text-field-label-font-size: 1.0625rem; /* 17px */
    --text-field-title-font-size: 1.25rem; /* 20px */
    --text-field-font-size: 1.25rem; /* 20px */
    --text-field-label-margin-bottom: 0.75rem; /* 12px */
    --text-field-padding: 0 0.625rem; /* 0px 10px*/
    --text-field-height: 2.75rem; /* 44px */
    --text-field-height-tall: 3.375rem; /* 54px */

    --text-field-label-font-color: var(--em-black);
    --text-field-font-color: var(--em-black);
    --text-field-background: var(--em-white);
    --text-field-border: 1px solid var(--em-medium-gray);

    --text-field-font-weight: 300;

    /* accordions */

    --collapsable-heading-font-size: 1.0625rem; /* 17px */
    --collapsable-heading-font-weight: var(--font-weight-normal);
    --collapsable-content-font-size: 1.0625rem; /* 17px */
    --collapsable-cross-color: var(--em-light-blue);

    /* validation */

    --validation-font-color: var(--em-error-red);
    --validation-border-color: var(--em-error-red);
    --validation-font-size: var(--font-size-p3);

    /* pagination */

    --paginator-border-color: var(--em-medium-gray);
    --paginator-border-color-active: var(--em-light-blue);
    --paginator-border-color-disabled: var(--em-medium-gray);
    --paginator-border-color-active-hover: var(--em-med-blue);
    --paginator-background-color: var(--em-white);
    --paginator-background-color-active: var(--em-light-blue);
    --paginator-background-color-active-hover: var(--em-med-blue);
    --paginator-background-color-hover: var(--em-medium-gray);
    --paginator-background-color-disabled: var(--em-lightest-gray);
    --paginator-font-color: var(--em-black);
    --paginator-font-color-active: var(--em-white);
    --paginator-font-size: 0.941rem;
    --paginator-item-size: 2.25rem; /* 36px */
    --paginator-item-ellipsis-size: 2.8125rem; /*45px*/

    /* tabs */

    --tabs-width: 15.625rem; /*250px*/
    --tabs-height: 3.75rem; /*60px*/
    --tabs-inner-height: 3.875rem; /*62px*/
    --tabs-items-negative-margin: -0.125rem; /*-2px*/

    /* modals */

    --small-modal-backdrop: rgba(255, 255, 255, 0.9);
    --small-modal-margin-top: 6.25rem; /*100px*/
    --small-modal-width: 32.875rem; /*526px*/
    --small-modal-min-height: 15.625rem; /*250px*/
    --small-modal-box-shadow: 1px 1px 16px 0 var(--em-medium-gray);
    --small-modal-border: 1px solid var(--em-medium-gray);
    --small-modal-padding: 3rem; /*48px*/
    --small-modal-close-size: 1.125rem; /*18px*/
    --small-modal-close-margin: 1.5rem; /*24px*/

    --large-modal-width: 75rem; /*1200px*/
    --large-modal-close-size: 1.125rem; /*48px*/
    --large-modal-close-margin: 3rem; /*48px*/
    --large-modal-content-padding: 3rem; /*48px*/

    /* tooltips */

    --tooltip-background-color: var(--em-white);
    --tooltip-border: 1px solid var(--em-medium-gray);
    --tooltip-box-shadow: 1px 1px 16px 0 var(--em-medium-gray);
    --tooltip-padding: 1.5rem; /*24px*/
    --tooltip-after-box-shadow: 1px 1px 1px 0 var(--em-medium-gray);
    --tooltip-heading-font-weight: 500;
    --tooltip-font-size: 1.0625rem; /*17px*/
    --tooltip-line-height: 1.5rem; /*24px*/
    --tooltip-link-color: var(--em-light-blue);
    --tooltip-word-underline: 2px dotted var(--em-light-blue);

    /* select */

    --select-padding: 0 0.625rem; /*0px 10px*/
    --select-border-color: var(--em-medium-gray);
    --select-border-focus-color: var(--em-light-blue);
    --select-border-error-color: var(--em-error-red);
    --select-arrow-right: 0.625rem; /*10px*/
    --select-font-size: 1.375rem; /*22px*/
    --select-font-weight: 300;
    --select-height: 2.75rem; /* 44px */
    --select-height-tall: 3.375rem; /* 54px */
    --select-label-height: 1.1875rem; /*19px*/
    --select-item-height: 3rem; /*48px*/

    /* textarea */

    --textarea-border: 1px solid var(--em-medium-gray);
    --textarea-border-focused: var(--em-light-blue);
    --textarea-height: 15.625rem; /*250px*/
    --textarea-min-height: 3.75rem; /*60px*/
    --textarea-max-height: 15.625rem; /*250px*/
    --textarea-border-disabled: var(--em-medium-gray);
    --textarea-color: var(--em-black);
    --textarea-font-size: 1.0625rem; /*17px*/
    --textarea-color-disabled: var(--em-dark-gray);
    --textarea-padding: 1.25rem; /*20px*/
    --textarea-background-color-disabled: var(--em-lightest-gray);

    /* toasts - notifications */

    --toast-negative-position: -30rem; /*-480px*/
    --toast-width: 23.5rem; /*365px*/
    --toast-padding: 4.1875rem; /*67px*/
    --toast-message-width: 3.75rem; /*60px*/
    --toast-minimum-height: 7.75rem; /*124px*/
    --toast-close-distance: 0.75rem; /*12px*/
    --toast-close-size: 0.875rem; /*14px*/
    --toast-background-color: var(--em-white);
    --toast-box-shadow: 0 1px 3px 0 var(--em-medium-gray);
    --toast-border: solid 1px var(--em-medium-gray);

    /* select companies */

    --select-company-width: 21.25rem; /*340px*/
    --select-company-border: 1px solid var(--em-medium-gray);
    --select-company-items-height: 12rem; /*192px*/
    --select-company-item-height: 3rem; /*48px*/
    --select-company-item-padding: 1rem; /*16px*/
    --select-company-item-active-padding: 0.9375rem; /*15px*/
    --select-company-item-color: var(--em-black);
    --select-company-item-background: var(--em-white);
    --select-company-item-active-background: rgba(102, 115, 255, 0.05);
    --select-company-item-active-border-left: 1px solid var(--em-light-blue);
    --select-company-item-border: 1px solid var(--em-medium-gray);
    --select-company-text-color: var(--em-black);
    --select-company-text-size: var(--font-size-p1);
    --select-company-heading-size: var(--font-size-heading-h1);
    --select-company-heading-weight: var(--font-weight-bold);
    --select-company-text-margin: 2rem; /*32px*/
    --select-company-heading-margin: 1.125rem; /*18px*/
    --select-company-button-margin: 1.125rem; /*18px*/
    --select-company-text-input: 1.125rem; /*18px*/
    --select-company-link-margin: 1.125rem; /*18px*/

    /* password criteria */

    --password-criteria-li-line-height: 1.41;
    --password-criteria-validators-margin: 1.5rem; /*24px*/
    --password-criteria-font-size: var(--font-size-p2);
    --password-criteria-msg-grey-color: var(--em-medium-gray2);
    --password-criteria-msg-color: var(--em-black);
    --password-criteria-checkbox-margin: 0.75rem 0 0; /*12px 0 0*/
    --password-criteria-msg-before-width: 0.75rem; /*24px*/
    --password-criteria-msg-before-height: 0.75rem; /*24px*/
    --password-criteria-li-left-padding: 1.5rem; /*24px*/
    --password-criteria-msg-before-top-position: 0.375rem; /*6px*/

    /* page dimensions */

    --page-max-width: 1080px;

    /* columns */

    --gutter: var(--spacing-main);

    /* date picker */

    --date-picker-background-color: var(--em-light-gray);
    --date-picker-border-color: var(--em-light-blue);
    --date-picker-input-font-size: var(--text-field-font-size);
    --date-picker-active-date: var(--em-light-blue);
    --date-picker-today-date: var(--em-light-blue);
}

/* breakpoints */

:root {
    --button-size: 48px;
    --button-size-large: 48px;
    --button-min-width: var(--form-field-min-width);
    --button-max-width: 200px;
    --button-large-min-width: 200px;
    --button-large-max-width: var(--form-field-max-width);
}

/**
 * ESP streamlined variables
 */

:root {
    /* colors */
    --esp-streamlined-background-color: var(--em-white);

    /* text */
    --esp-streamlined-paragraph-font-size: 1.0625rem; /* 17px */
    --esp-streamlined-label-font-size-normal: var(--font-size-base); /* 16px */
    --esp-streamlined-label-font-size-small: 0.875rem; /* 14px */

    /* borders */
    --esp-streamlined-border-style: var(--border-solid-gray);
    --esp-streamlined-drop-shadow: var(--border-drop-shadow);
}

.s-form-engine {
    &.c-form-layout {
        max-width: 768px;
        width: 100%;
    }
}

.s-form-engine {
    overflow: visible;

    @media only screen and (any-pointer: coarse) {
        overflow: auto;
    }

    &.em-h3--overline {
        h3 {
            margin-top: 20px;

            @media (max-width: 767px) {
                margin-top: 0;
            }
        }
        .em-form-page-wrapper > :not(h3):first-child {
            margin-top: 70px;

            @media (max-width: 767px) {
                margin-top: 50px;
            }
        }
    }

    .c-input-field {
        box-sizing: border-box;
    }

    .c-radio {
        font-family: Inter;
        font-size: var(--font-size-p2);
    }

    .c-alert,
    .em-note {
        margin-top: var(--spacing-xtiny);
    }

    .c-input__field--readonly {
        padding: 0 0.625rem;
        padding: var(--text-field-padding);
    }

    label {
        display: inline-block;
    }

    .btn-default {
        cursor: pointer;
        outline: none;
    }

    .btn-default:hover {
        background: #4654e2;
        background: var(--em-med-blue);
    }

    .em-button {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        font-weight: normal;
        display: flex-inline;
        font-size: 0.9375rem;
        font-size: var(--button-font-size);
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        outline: none;
        cursor: pointer;
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        outline: none;
    }

    .em-button > * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
    }

    .em-button[disabled] {
        pointer-events: none;
    }

    .em-button-default {
        height: 48px;
        height: var(--button-size);
        line-height: 28px;
        line-height: var(--button-size);
        padding: 0 1.5rem;
        padding: 0 var(--spacing-main);
        width: -webkit-min-content;
        width: -moz-min-content;
        font-weight: 700;
        width: min-content;
        min-width: 6.25rem;
        min-width: var(--button-min-width);
        max-width: 220px;
        max-width: var(--button-max-width);
        border: 1px solid #199fd5;
        border: 1px solid var(--brand-primary);
        border-radius: 28px;
        border-radius: var(--button-size);
    }

    .em-button-text {
        height: 28px;
        height: var(--button-size);
        line-height: 28px;
        line-height: var(--button-size);
        font-weight: bold;
        display: inline;
        outline: none;
        background: none;
        text-overflow: unset;
    }

    .em-button-text:hover,
    .em-button-text:focus {
        text-decoration: none;
    }

    .em-button-text:disabled {
        -webkit-filter: grayscale(1) opacity(0.5);
        filter: grayscale(1) opacity(0.5);
    }

    .em-button-text-large {
        font-size: 14px;
        font-size: var(--button-font-size);
    }

    .em-button-text-underline {
        height: 28px;
        height: var(--button-size);
        line-height: 28px;
        line-height: var(--button-size);
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        text-decoration: underline;
    }

    .em-button-outline,
    .em-button-outline-strong {
        height: 28px;
        height: var(--button-size);
        line-height: 28px;
        line-height: var(--button-size);
        padding: 0 1rem;
        font-size: 15px;
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
        min-width: 6.25rem;
        min-width: var(--button-min-width);
        max-width: 220px;
        max-width: var(--button-max-width);
        border: 1px solid #47c6ec;
        border: 1px solid var(--button-border-color);
        border-radius: 28px;
        border-radius: var(--button-size);
    }

    .em-button-outline:hover,
    .em-button-outline:focus,
    .em-button-outline-strong:hover,
    .em-button-outline-strong:focus {
        border: 1px solid #4654e2;
        border: 1px solid var(--button-border-outline-hover-color);
    }

    .em-button-outline-strong {
        font-weight: 600;
        font-weight: var(--font-weight-bold);
    }

    .em-button-custom {
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
    }

    .em-button-size-large {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 14px;
        font-size: var(--button-font-size);
        font-family: Inter;
        height: 56px;
        height: var(--button-size-large);
        border-radius: 28px;
        border-radius: var(--button-size-large);
        line-height: 48px;
        line-height: var(--button-size-large);
        padding: 0 24px;
        padding: 0 var(--spacing-main);
        min-width: 200px;
        min-width: var(--button-large-min-width);
        max-width: 21.625rem;
        max-width: var(--button-large-max-width);
    }

    .em-button-outline.short {
        max-width: 130px;
    }

    .em-check-box {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }

    /* reset property value, clickable area should not be longer than the text */

    .em-field-checkbox .c-tooltip {
        display: inline-block;
        padding-left: 8px;
    }

    .em-field-checkbox .c-input--checkbox {
        width: auto;
    }

    .em-field-checkbox .c-input--checkbox:not(.c-input--checkbox--with-border) {
        display: inline-flex;
    }

    .em-check-box input {
        opacity: 0;
        position: absolute;
        top: 0.125rem;
        top: var(--checkbox-input-top-position);
        left: 0.125rem;
        left: var(--checkbox-input-left-position);
    }

    .em-check-box label {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        z-index: 0;
        font-size: 1.0625rem;
        font-size: var(--checkbox-label-font-size);
        line-height: 1.41;
        line-height: var(--line-height-base);
        padding: 0.75rem 0;
        padding: var(--checkbox-label-padding);
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }

    .em-check-box label::before {
        content: ' ';
        width: 1.125rem;
        width: var(--checkbox-label-before-width);
        min-width: 1.125rem;
        min-width: var(--checkbox-label-before-width);
        height: 1.125rem;
        height: var(--checkbox-label-before-height);
        border: 1px solid #85878a;
        border: var(--checkbox-label-before-border);
        margin-right: 1rem;
        margin-right: var(--checkbox-label-before-margin-right);
        margin-top: calc(0.5em * 1.41 - 1.125rem / 2);
        margin-top: calc(0.5em * var(--line-height-base) - var(--checkbox-label-before-height) / 2);
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        left: 0;
        -webkit-transition: background-color 0.5s, border 0.5s;
        transition: background-color 0.5s, border 0.5s;
        z-index: 1;
    }

    .em-check-box input:checked + label::before {
        content: ' ';
        background-size: 80%;
        border: 1px solid #47c6ec;
        border: var(--checkbox-label-before-checked-and-focused);
    }

    .em-check-box label.is-checked::before {
        content: ' ';
        background-size: 80%;
        background-position: 1px 2px;
        background-repeat: no-repeat;
        border: 1px solid #47c6ec;
        border: var(--checkbox-label-before-checked-and-focused);
    }

    .em-check-box input:focus + label::before {
        content: ' ';
        border: 1px solid #47c6ec;
        border: var(--checkbox-label-before-checked-and-focused);
    }

    .em-check-box input:disabled + label {
        cursor: not-allowed;
    }

    .em-check-box--vertical label {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-check-box--vertical label::before {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin-top: 10px;
    }

    .em-check-box .em-help {
        top: 16px;
    }

    .em-short-form.em-check-box,
    .em-short-form .em-check-box {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    /* can grow now, because there's a visible border */

    .em-short-form.em-check-box label,
    .em-short-form .em-check-box label {
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        background: #ffffff;
        background: var(--em-white);
        padding: 0.75rem;
        padding: var(--spacing-xsmall);
        width: 100%;
    }

    .em-read-only.em-check-box label,
    .em-read-only .em-check-box label {
        cursor: not-allowed;
    }

    :root {
        --collapsable-indent: var(--spacing-main);
        --collapsable-spacing: var(--spacing-small);
        --collapsable-cross-size: 10px;
    }

    .em-collapsable {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        padding-left: 1.5rem;
        padding-left: var(--collapsable-indent);
        cursor: pointer;
    }

    .em-collapsable-heading {
        font-size: 1.0625rem;
        font-size: var(--collapsable-heading-font-size);
        font-weight: 400;
        font-weight: var(--collapsable-heading-font-weight);
        line-height: 26px;
        cursor: pointer;
        position: relative;
    }

    .em-collapsable-heading::before {
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        position: absolute;
        top: 50%;
        left: calc(-1 * 1.5rem);
        left: calc(-1 * var(--collapsable-indent));
        content: '';
        display: block;
        height: 10px;
        height: var(--collapsable-cross-size);
        width: 10px;
        width: var(--collapsable-cross-size);
        line-height: 0;
        border-top: 2px solid #47c6ec;
        border-top: 2px solid var(--collapsable-cross-color);
        border-right: 2px solid #47c6ec;
        border-right: 2px solid var(--collapsable-cross-color);
        -webkit-transform-origin: calc(10px / 2) 0;
        transform-origin: calc(10px / 2) 0;
        -webkit-transform-origin: calc(var(--collapsable-cross-size) / 2) 0;
        transform-origin: calc(var(--collapsable-cross-size) / 2) 0;
    }

    .em-collapsable-heading::before {
        -webkit-transform: rotate(45deg) translateY(-50%);
        transform: rotate(45deg) translateY(-50%);
    }

    .em-collapsable-content {
        font-size: 1.0625rem;
        font-size: var(--collapsable-content-font-size);
        margin-top: 1.125rem;
        margin-top: var(--collapsable-spacing);
        display: none;
        cursor: default;
    }

    .em-collapsable-expanded .em-collapsable-content {
        display: block;
    }

    .em-collapsable-expanded .em-collapsable-heading::before {
        -webkit-transform: rotate(135deg) translateY(-50%);
        transform: rotate(135deg) translateY(-50%);
    }

    .flex-layout {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .flex-layout-horizontal {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .flex-layout-child {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
    }

    .flex-layout-child-align-auto {
        -ms-flex-item-align: auto;
        align-self: auto;
    }

    .flex-layout-child-align-start {
        -ms-flex-item-align: start;
        align-self: flex-start;
    }

    .flex-layout-child-align-end {
        -ms-flex-item-align: end;
        align-self: flex-end;
    }

    .flex-layout-child-align-baseline {
        -ms-flex-item-align: baseline;
        align-self: baseline;
    }

    .flex-layout-child-align-center {
        -ms-flex-item-align: center;
        align-self: center;
    }

    .flex-layout-child-align-stretch {
        -ms-flex-item-align: stretch;
        align-self: stretch;
    }

    .flex-layout-child-flex-0 {
        -webkit-box-flex: 0;
        -ms-flex: 0 1;
        flex: 0 1;
    }

    .flex-layout-child-flex-1 {
        -webkit-box-flex: 1;
        -ms-flex: 1 1;
        flex: 1 1;
    }

    .flex-layout-child-flex-2 {
        -webkit-box-flex: 2;
        -ms-flex: 2 1;
        flex: 2 1;
    }

    .flex-layout-child-flex-3 {
        -webkit-box-flex: 3;
        -ms-flex: 3 1;
        flex: 3 1;
    }

    .flex-layout-child-flex-4 {
        -webkit-box-flex: 4;
        -ms-flex: 4 1;
        flex: 4 1;
    }

    .flex-layout-child-flex-5 {
        -webkit-box-flex: 5;
        -ms-flex: 5 1;
        flex: 5 1;
    }

    .flex-layout-child-order-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .flex-layout-child-order-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .flex-layout-child-order-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .flex-layout-child-order-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .flex-layout-child-order-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }

    .flex-layout-child-order-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }

    .flex-layout-child-order-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }

    .flex-layout-child-order-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }

    /* column rules */

    .col-2-3 {
        width: calc(100% / 3 * 2);
        margin-bottom: 18px;
    }

    .col-1-3 {
        width: calc(100% / 3 * 1);
        margin-bottom: 18px;
    }

    .col-1-2 {
        width: calc(100% / 2 * 1);
        margin-bottom: 18px;
    }

    .col-1-2.no-bottom-margin {
        margin-bottom: 0;
    }

    /* grid gutters */

    .form-grid-row-narrow {
        width: 66.66%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .form-grid-row .grid-item {
        min-width: 136px;
        max-width: 323px;
        width: 100%;
        margin: 0 24px 18px 0;
    }

    .form-grid-row .grid-item-static-label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
    }

    .form-grid-row .grid-item:last-of-type {
        margin-right: 0;
    }

    .form-grid-row .em-field-label {
        height: 21px;
    }

    .em-icon {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
    }

    .em-icon-user {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-arrow-down {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
    }

    .em-icon-chevron-down {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMTMgMUw2Ljk0NSA3IDEgMSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiM2NjczRkYiIGZpbGw9Im5vbmUiLz4KPC9zdmc+Cg==);
        height: 8px;
        width: 13px;
    }

    .em-icon-check-white {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
        height: 10px;
        width: 13px;
    }

    .em-icon-check-gray {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
    }

    .em-icon-check-gray2 {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
    }

    .em-icon-x-gray {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
    }

    .em-icon-check-gray-round {
        height: 18px;
        width: 18px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZmlsbD0iI0U2RThFQiIgZD0iTTkgMEM0LjA1IDAgMCA0LjA1IDAgOXM0LjA1IDkgOSA5IDktNC4wNSA5LTktNC4wNS05LTktOXoiLz4KICAgICAgICA8cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTQgMTIuOTRMMTIuOTQgMTQgOSAxMC4wNiA1LjA2IDE0IDQgMTIuOTQgNy45NCA5IDQgNS4wNiA1LjA2IDQgOSA3Ljk0IDEyLjk0IDQgMTQgNS4wNiAxMC4wNiA5eiIvPgogICAgPC9nPgo8L3N2Zz4K);
    }

    .em-icon-check-green {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
    }

    .em-icon-check-green-round {
        height: 18px;
        width: 18px;
    }

    .em-icon-check-blue {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
    }

    .em-icon-check-blue-round {
        height: 18px;
        width: 18px;
    }

    .em-icon-lock {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
    }

    .em-icon-download-doc {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDE2IDIwIj4KICAgIDxnIGZpbGw9IiM2NjczRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTQuNTAxIDB2Ny45NjhMMCA3Ljk2MyA3Ljk5IDE2IDE2IDcuOTgxbC00LjY3LS4wMDVWMHpNMCAxNy45MjNoMTZ2MkgweiIvPgogICAgPC9nPgo8L3N2Zz4K);
        height: 20px;
    }

    .em-icon-user-inactive {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-company {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-company-inactive {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-notification {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 13px;
    }

    .em-icon-info-tooltip {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        width: 13px;
        height: 13px;
    }

    .em-icon-dog {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        width: 42px;
        background-size: contain;
        height: 54px;
    }

    .em-icon-logo {
        height: 27px;
        width: 200px;
    }

    .em-icon-phone {
        height: 20px;
        width: 14px;
    }

    .em-icon-chat {
        height: 17px;
        width: 18px;
    }

    .em-icon-close {
        height: 17px;
        width: 18px;
    }

    /* What We Offer Icons (no margin) below */

    .em-icon-analyze {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 48px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCA1OCA1MCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I2ZjNWYzN308L3N0eWxlPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0wIDN2NDdoMzZWMTUuOUwyNC42IDNIMHptMTggNDVWMzMuNWg0VjQ4aC00em0xNiAwaC00VjIyLjVoNFY0OHptMC0yNy41aC02VjQ4aC00VjMxLjVoLThWNDhIMlY1aDIxLjd2MTEuNkgzNHYzLjl6TTQwIDI2LjVWNTBoOFYyNi41aC04ek00NiA0OGgtNFYyOC41aDRWNDh6Ii8+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGg1OHY1MEgweiIvPjwvc3ZnPg==);
    }

    .em-icon-support {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 48px;
    }

    .em-icon-match {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 48px;
    }

    /* LOB Icons below */

    .em-icon-LineOfBusinessCodeListAuto {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListFiduciary {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListProperty {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListCrime {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListCyber {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListDirectorsAndOfficers {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListEmploymentPractices {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListTravel {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListGeneralLiability {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListProfessionalLiability {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListProductLiability {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListUmbrella {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListWorkersCompensation {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-LineOfBusinessCodeListOther {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    /* App Type Icons below */

    .em-icon-AppTypeCodeListAuto {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListFiduciary {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListProperty {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListCrime {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListCyber {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListDirectorsAndOfficers {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListEmploymentPractices {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListTravel {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListGeneralLiability {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListProfessionalLiability {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListProductLiability {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListUmbrella {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListWorkersCompensation {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListOther {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListVentureCapitalAssetProtection {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListHomeownersAssociation {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-AppTypeCodeListBOP {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-add {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-circle-yellow {
        height: 10px;
        width: 10px;
        background-color: #ffd23b;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        display: inline-block;
    }

    .em-icon-circle-red {
        height: 10px;
        width: 10px;
        background-color: #ff3832;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        display: inline-block;
    }

    .em-icon-circle-green {
        height: 10px;
        width: 10px;
        background-color: #a0cb21;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        display: inline-block;
    }

    .em-icon-circle-blue {
        height: 10px;
        width: 10px;
        background-color: #49b2ff;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        display: inline-block;
    }

    .em-icon-upload {
        height: 22px;
        width: 17px;
    }

    .em-icon-notify {
        height: 14px;
        width: 18px;
    }

    .em-icon-trash {
        height: 21px;
        width: 14px;
    }

    .em-icon-missing-doc {
        height: 32px;
        width: 24px;
    }

    .em-icon-missing-doc-hover {
        height: 32px;
        width: 24px;
    }

    .em-icon-page-hover {
        height: 35px;
        width: 28px;
    }

    .em-icon-page {
        height: 34px;
        width: 32px;
    }

    .em-icon-download {
        height: 34px;
        width: 26px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAWCAYAAAAmaHdCAAAAAXNSR0IArs4c6QAAASpJREFUOBFjvHDhwn8GCgEThfpHteMIAUZ08cePH3O+ffv2G7o4jM/IyGihr69/EsYH0VSJnVFDkIMUwqZKmLD8//+fBWgePKrv3bvHhmkXQoSZmZnr/v37HDARFhYWRkZgLu4ECpTBBEmhgWnmPxC3gl1w6dKlVKCLZgAx0d4Dav4HtDANmPDmwr1x8eLFCKAhi4ESIO8RAr+BCmINDAxWghTCDQFxgF7zAdqwGmgY3M8gcWQAlP/BxMQUoquruxUmjmIISPDKlSuOf//+3QQ0iAemCEYDDfgCDFg/HR2d/TAxEI1hCEjw6tWrZn/+/NkONEgIxAcBoAHvgZQneuYDy4EIbAAY2LpAQ3YBsQTQgFdAF7gBXXARm1q8YkAXqQAD/AwwrNTwKQQAe+5jYymkyTgAAAAASUVORK5CYII=)
            center center no-repeat;
    }

    /*  Industry icons */

    .em-icon-IndustryTypeCodeListAccounting {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListCannabis {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListConstruction {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListFinance {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListHealthcare {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListLawFirm {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListManufacturing {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListProfessionalServices {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListRealEstate {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListRestaurant {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListRetail {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListTechnology {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListVentureCapitalOrPrivateEquity {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListWholesale {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-IndustryTypeCodeListOther {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 42px;
        width: 42px;
        background-size: contain;
    }

    .em-icon-copy {
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 16px;
        width: 16px;
    }

    .em-link {
        cursor: pointer;
        text-decoration: none;
        -webkit-text-decoration: var(--link-text-decoration-invalid);
        text-decoration: var(--link-text-decoration-invalid);
        font-size: 1.0625rem;
        font-size: var(--link-font-size);
    }

    .em-link-underline {
        text-decoration: underline;
        -webkit-text-decoration: var(--link-text-decoration-valid);
        text-decoration: var(--link-text-decoration-valid);
    }

    .em-link-small {
        cursor: pointer;
        text-decoration: none;
        -webkit-text-decoration: var(--link-text-decoration-invalid);
        text-decoration: var(--link-text-decoration-invalid);
        font-size: 0.9375rem;
        font-size: var(--link-small-font-size);
    }

    .em-link-small-underline {
        text-decoration: underline;
        -webkit-text-decoration: var(--link-text-decoration-valid);
        text-decoration: var(--link-text-decoration-valid);
    }

    .em-password-group {
        display: -webkit-box;

        display: -ms-flexbox;

        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        position: relative;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    @media (max-width: 360px) {
        .em-password-group {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }
    }

    .em-password-group-fields {
        display: -webkit-box;

        display: -ms-flexbox;

        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex: 1 1;
        flex: 1 1;
    }

    @media (max-width: 360px) {
        .em-password-group-fields {
            width: 100%;
        }
    }

    .em-password-group-validators {
        font-size: 0.9375rem;

        font-size: var(--password-criteria-font-size);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
        -webkit-box-flex: 1;
        -ms-flex: 1 1;
        flex: 1 1;
    }

    @media (max-width: 360px) {
        .em-password-group-validators {
            width: 100%;
            margin-left: 0;
        }
    }

    .em-password-group-validators ul {
        padding: 0;
        margin: 0 0 0 1.5rem;
        margin: 0 0 0 var(--password-criteria-validators-margin);
        list-style: none;
    }

    .em-password-group-validators ul li {
        position: relative;
        padding-left: 1.5rem;
        padding-left: var(--password-criteria-li-left-padding);
        line-height: 1.41;
        line-height: var(--password-criteria-li-line-height);
    }

    .em-password-group-validators ul .em-password-criteria-msg-red::before {
        content: ' ';
        display: block;
        width: 0.75rem;
        width: var(--password-criteria-msg-before-width);
        height: 0.75rem;
        height: var(--password-criteria-msg-before-height);
        position: absolute;
        left: 0;
        top: 0.375rem;
        top: var(--password-criteria-msg-before-top-position);
    }

    .em-password-group-validators ul .em-password-criteria-msg-disabled::before {
        content: ' ';
        display: block;
        width: 0.75rem;
        width: var(--password-criteria-msg-before-width);
        height: 0.75rem;
        height: var(--password-criteria-msg-before-height);
        position: absolute;
        left: 0;
        top: 0.375rem;
        top: var(--password-criteria-msg-before-top-position);
    }

    .em-password-group-validators ul .em-password-criteria-msg-green::before {
        content: ' ';
        display: block;
        width: 0.75rem;
        width: var(--password-criteria-msg-before-width);
        height: 0.75rem;
        height: var(--password-criteria-msg-before-height);
        position: absolute;
        left: 0;
        top: 0.375rem;
        top: var(--password-criteria-msg-before-top-position);
    }

    .em-password-text-field {
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .password-change-wrap .em-password-checkbox label {
        z-index: 1;
    }

    .password-change-wrap .em-validation-message {
        top: -52px;
        z-index: 0;
    }

    .password-change-wrap .em-button-size-large {
        margin: 24px auto 0;
    }

    .em-radio-button label {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        z-index: 0;
        padding: 0.5rem 0.5rem 0.5rem 0;
        padding: var(--radio-label-padding);
        padding-left: 25px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .em-short-form.em-radio-button,
    .em-short-form .em-radio-button {
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
        height: 2.75rem;
        height: var(--text-field-height);
        padding: 0 0.625rem;
        padding: var(--text-field-padding);
        background: #ffffff;
        background: var(--text-field-background);
        border: 1px solid #e6e8eb;
        border: var(--text-field-border);
        border-radius: 2.75rem;
        border-radius: var(--text-field-height);
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0.5rem !important;
        margin-bottom: var(--spacing-tiny) !important;
    }

    .em-read-only.em-radio-button label,
    .em-read-only .em-radio-button label,
    .em-read-only.em-radio-button label input,
    .em-read-only .em-radio-button label input {
        cursor: not-allowed;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-short-form .em-radio-button {
            height: 3.375rem;
            height: var(--text-field-height-tall);
        }
    }

    .security-q-wrap .label-security-question {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        position: relative;
        z-index: 1;
        float: left;
    }

    .security-q-wrap .em-field-label {
        display: inherit;
    }

    .security-q-wrap .em-select {
        margin-bottom: 18px;
    }

    .em-select {
        position: relative;
    }

    .em-select-field {
        min-width: 160px;
        max-width: 320px;
    }

    .em-select-field__placeholder {
        font-size: 20px;
        font-weight: 300;
        font-weight: var(--font-weight-light);
    }

    .em-select-field__menu-list {
        overflow-x: hidden;
    }

    .em-select-field__option {
        word-wrap: break-word;
    }

    .em-select-wrap {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;

        background: #ffffff;

        background: var(--em-white);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 2.75rem;
        height: var(--select-height);

        border: 1px solid #e6e8eb;

        border: 1px solid var(--select-border-color);
        position: relative;
        z-index: 0;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-select-wrap {
            height: 3.375rem;
            height: var(--select-height-tall);
        }
    }

    .em-select-wrap,
    .em-select-dd {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-select .em-field-label {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .em-select .em-field-label .em-validation-message-gray {
        position: relative;
    }

    .em-select input[type='text'],
    .em-select input[type='email'],
    .em-select input[type='password'],
    .em-select input[type='number'] {
        font-size: 1.375rem;
        font-size: var(--select-font-size);
        font-weight: 300;
        font-weight: var(--select-font-weight);
        outline: none;
        width: 100%;
        border: none;
        height: 2.75rem;
        height: var(--select-height);

        padding: 0 0.625rem;

        padding: var(--select-padding);
        display: block;
        cursor: pointer;
        z-index: 1;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-select input[type='text'],
        .em-select input[type='email'],
        .em-select input[type='password'],
        .em-select input[type='number'] {
            height: 3.375rem;
            height: var(--select-height-tall);
        }
    }

    .em-select .em-select-arrow {
        position: absolute;
        right: 0.625rem;
        right: var(--select-arrow-right);
        top: calc(50% - 8px);
        z-index: 0;
    }

    .em-select-dd {
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        background: #ffffff;
        background: var(--em-white);
        z-index: 100;
        max-height: 192px;
        overflow-y: scroll;
    }

    .em-select-dd--hide {
        display: none !important; /*fix for FF*/
        border: none;
    }

    .em-select-dd--show {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border: 1px solid #47c6ec;
        border: 1px solid var(--select-border-focus-color);
    }

    .em-select-item {
        height: 3rem;
        height: var(--select-item-height);
        min-height: 3rem;
        min-height: var(--select-item-height);
        border-bottom: 1px solid #e6e8eb;
        border-bottom: 1px solid var(--select-border-color);
        font-size: 1.375rem;
        font-size: var(--select-font-size);
        line-height: 3rem;
        line-height: var(--select-item-height);
        padding: 0 0.625rem;
        padding: var(--select-padding);
        font-weight: 300;
        font-weight: var(--select-font-weight);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        cursor: default;
    }

    .em-select-item-active {
        line-height: 3rem;
        line-height: var(--select-item-height);
        min-height: 3rem;
        min-height: var(--select-item-height);
        height: 3rem;
        height: var(--select-item-height);
        border-bottom: 1px solid #e6e8eb;
        border-bottom: 1px solid var(--select-border-color);
        font-size: 1.375rem;
        font-size: var(--select-font-size);
        padding: 0 0.625rem;
        padding: var(--select-padding);
        font-weight: 300;
        font-weight: var(--select-font-weight);
        cursor: default;
    }

    .em-select-item:last-of-type {
        border-bottom: none;
    }

    /* forms engine */

    .ui.selection.dropdown.em-select-field {
        position: relative;
        border-radius: 0;
        height: auto;
        padding-right: 32px;
        padding-left: 2px;
        line-height: 2.75rem;
        line-height: var(--select-height);
        min-height: 2.75rem;
        min-height: var(--select-height);

        border: 1px solid #e6e8eb;

        border: 1px solid var(--select-border-color);
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 100%;
        cursor: pointer;
        word-wrap: break-word;
        white-space: normal;
        outline: 0;
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
        font-size: 20px;
        font-weight: 300;
        display: inline-block;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .ui.selection.dropdown.em-select-field {
            line-height: 3.375rem;
            line-height: var(--select-height-tall);
            min-height: 3.375rem;
            min-height: var(--select-height-tall);
        }
    }

    .ui.selection.dropdown.em-select-field .visible.transition {
        display: block !important;
        visibility: visible !important;
        overflow-y: scroll;
    }

    .ui.selection.dropdown.em-select-field input.search {
        position: relative;
        font-size: 1.375rem;
        font-size: var(--select-font-size);
        font-weight: 300;
        font-weight: var(--select-font-weight);
        outline: none;
        border: none;
        cursor: text;
        top: 0;
        left: 1px;
        width: calc(100% - 1px);
        height: 2.75rem;
        height: var(--select-height);
        line-height: 2.75rem;
        line-height: var(--select-height);
        min-height: 2.75rem;
        min-height: var(--select-height);

        padding: 0 0.625rem !important;

        padding: var(--select-padding) !important;
        z-index: 2;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .ui.selection.dropdown.em-select-field input.search {
            height: 3.375rem;
            height: var(--select-height-tall);
            line-height: 3.375rem;
            line-height: var(--select-height-tall);
            min-height: 3.375rem;
            min-height: var(--select-height-tall);
        }
    }

    .ui.selection.dropdown.em-select-field .text {
        padding: 0 20px 0 10px;
        cursor: text;
        position: absolute;
        left: 1px;
        top: 0;
        z-index: 3;
    }

    .ui.selection.dropdown.em-select-field .icon {
        position: absolute;
        right: -8px;
        top: -2px;
    }

    .ui.selection.dropdown.em-select-field .icon::before {
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        line-height: 0;
        position: absolute;
        border-top: 2px solid #47c6ec;
        border-top: 2px solid var(--em-light-blue);
        border-right: 2px solid #47c6ec;
        border-right: 2px solid var(--em-light-blue);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: 0.6125em;
        right: 1em;
        -webkit-transition: opacity 0.1s ease;
        transition: opacity 0.1s ease;
        z-index: 3;
    }

    .ui.selection.active.dropdown.em-select-field .menu,
    .ui.selection.active.dropdown.em-select-field:hover .menu,
    .ui.selection.dropdown.em-select-field .menu {
        border-top: 1px solid #e6e8eb !important;
        border-top: 1px solid var(--select-border-color) !important;
        border-top-width: 1px !important;
    }

    .ui.dropdown.em-select-field .menu {
        cursor: auto;
        position: absolute;
        display: none;
        outline: none;
        top: 100%;
        /* min-width: max-content; */
        margin: 0;
        padding: 0;
        background: #ffffff;
        background: var(--em-white);
        font-size: 1em;
        text-shadow: none;
        text-align: left;
        -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
        box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
        -webkit-transition: opacity 0.1s ease;
        transition: opacity 0.1s ease;
        z-index: 11;
        backface-visibility: hidden;
        will-change: transform, opacity;
        left: 0;
    }

    .ui.selection.dropdown.em-select-field.visible,
    .ui.selection.dropdown.em-select-field.active {
        z-index: 10;
    }

    .ui.search.dropdown.em-select-field.active > .text,
    .ui.search.dropdown.em-select-field.visible > .text {
        pointer-events: none;
    }

    .ui.selection.dropdown.em-select-field {
        overflow: hidden;
    }

    .ui.selection.dropdown.em-select-field.visible,
    .ui.selection.dropdown.em-select-field.active {
        overflow: visible;
    }

    .ui.selection.active.dropdown.em-select-field .menu,
    .ui.selection.active.dropdown.em-select-field:hover .menu,
    .ui.selection.dropdown.em-select-field .menu {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        max-height: 12.0625rem;
        border-top-width: 0;
        outline: none;
        margin: 0px -1px;
        min-width: calc(100% + 2px);
        width: calc(100% + 2px);
        -webkit-transition: opacity 0.1s ease;
        transition: opacity 0.1s ease;
    }

    .ui.selection.dropdown.em-select-field .menu > .item {
        border-bottom: 1px solid #e6e8eb;
        border-bottom: 1px solid var(--select-border-color);
        font-size: 1.375rem;
        font-size: var(--select-font-size);
        padding: 0.45rem 0;
    }

    .ui.selection.dropdown.em-select-field .menu > .item .text {
        position: relative;
        font-weight: 300;
        font-family: 'Calibre';
        padding: 0;
        margin: 0 0.625rem !important;
        margin: var(--select-padding) !important;
        display: -webkit-box;
    }

    .em-simple-table {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .em-simple-table-head {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;

        -ms-flex-positive: 1;

        flex-grow: 1;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 20px 0;
        margin-bottom: 20px;
        border-bottom: 1px solid #e6e8eb;
        border-bottom: 1px solid var(--em-medium-gray);
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
        text-transform: uppercase;
    }

    .em-simple-table-head-item {
        text-align: left;
        width: 100%;
    }

    .em-simple-table-row {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;

        -ms-flex-positive: 1;

        flex-grow: 1;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-align: left;
        line-height: 24px;
    }

    .em-simple-table-row-item {
        text-align: left;
        width: 100%;
    }

    .em-simple-table-row:last-of-type {
        margin-bottom: 36px;
    }

    .em-tabs-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        background: #ffffff;
        background: var(--em-white);
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-tabs-wrap-vertical {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .em-tabs-wrap-vertical .em-tabs-navigation-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        border: 1px solid #e6e8eb;
        border: 1px solid var(--em-medium-gray);
    }

    .em-tabs-wrap-vertical .em-tabs-navigation-item {
        cursor: pointer;
        position: relative;
        z-index: 1;
        min-width: 15.625rem;
        min-width: var(--tabs-width);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 1em;
        border-style: solid;
        border-width: 1px;
        border-bottom: none;
        margin-left: -0.125rem;
        margin-left: var(--tabs-items-negative-margin);
    }

    .em-tabs-wrap-vertical .em-tabs-navigation-item:first-of-type {
        border-top: none;
    }

    .em-tabs-wrap-vertical .em-tabs-navigation-item:last-of-type {
        border-bottom: 1px solid #e6e8eb;
        border-bottom: 1px solid var(--em-medium-gray);
    }

    .em-tabs-wrap-vertical .em-tabs-navigation-item-active::before {
        position: absolute;
        display: block;
        content: ' ';
        border-left: 2px solid #47c6ec;
        border-left: 2px solid var(--em-light-blue);
        height: 100%;
        top: 0;
        left: 0;
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-line-pack: center;
        align-content: center;
        border: 1px solid #e6e8eb;
        border: 1px solid var(--em-medium-gray);
        height: 3.75rem;
        height: var(--tabs-height);
        position: relative;
        z-index: 1;
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-item {
        position: relative;
        z-index: 10;
        cursor: pointer;
        max-width: 100%;
        width: 15.625rem;
        width: var(--tabs-width);
        height: 3.875rem;
        height: var(--tabs-inner-height);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 20px;
        border-style: solid;
        border-width: 1px;
        border-right: none;
        margin-top: -0.125rem;
        margin-top: var(--tabs-items-negative-margin);
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-item:first-of-type {
        border-left: none;
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-item:last-of-type {
        border-right: 1px solid #e6e8eb;
        border-right: 1px solid var(--em-medium-gray);
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-item-active::before {
        position: absolute;
        display: block;
        content: ' ';
        border-top: 2px solid #47c6ec;
        border-top: 2px solid var(--em-light-blue);
        width: 100%;
        top: 0;
        left: 0;
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-item-active::after {
        position: absolute;
        display: block;
        content: ' ';
        border-top: 1px solid #ffffff;
        border-top: 1px solid var(--em-white);
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-item-link {
        position: relative;
        z-index: 10;
        cursor: pointer;
        max-width: 100%;
        width: 15.625rem;
        width: var(--tabs-width);
        height: 3.875rem;
        height: var(--tabs-inner-height);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 10px;
        border-style: solid;
        border-width: 1px;
        border-right: none;
        margin-top: -0.125rem;
        margin-top: var(--tabs-items-negative-margin);
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-item-link:first-of-type {
        border-left: none;
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-item-link:last-of-type {
        border-right: 0;
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-item-link-active::before {
        position: absolute;
        display: block;
        content: ' ';
        border-bottom: 2px solid #47c6ec;
        border-bottom: 2px solid var(--em-light-blue);
        width: 100%;
        top: 0;
        left: 0;
    }

    .em-tabs-wrap-horizontal .em-tabs-navigation-item-link-active::after {
        position: absolute;
        display: block;
        content: ' ';
        border-bottom: 1px solid #ffffff;
        border-bottom: 1px solid var(--em-white);
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .em-tab-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1 1;
        flex: 1 1;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 1.5rem 2rem 2rem;
        border-style: solid;
        border-width: 1px;
    }

    .em-tab-wrap-vertical {
        border-left: none;
        border-top: 1px solid #e6e8eb;
        border-top: 1px solid var(--em-medium-gray);
        border-bottom: 1px solid #e6e8eb;
        border-bottom: 1px solid var(--em-medium-gray);
    }

    .em-tab-wrap-horizontal {
    }

    body .em-tabs-wrap-link .em-tabs-navigation-wrap {
        position: relative;
        height: 36px;
        line-height: 36px;
        background: #ffffff;
        background: var(--em-white);
        padding: 0 1.125rem;
        padding: 0 var(--spacing-small);
        border: none;
        border-bottom: 2px solid #e6e8eb;
        border-bottom: 2px solid var(--em-medium-gray);
        border-left: 1px solid #e6e8eb;
        border-left: 1px solid var(--em-medium-gray);
        border-right: 1px solid #e6e8eb;
        border-right: 1px solid var(--em-medium-gray);
        margin-top: -1px;
    }

    body
        .em-tabs-wrap-link
        .em-tabs-navigation-wrap
        .em-tabs-navigation-item.em-tabs-navigation-item-link {
        text-align: center;
        margin: 0 1.125rem;
        margin: 0 var(--spacing-small);
        padding: 10px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border: none;
        height: 36px;
        width: auto;
    }

    body .em-tabs-wrap-link .em-tabs-navigation-wrap .em-tabs-navigation-item-active::before {
        position: absolute;
        display: block;
        content: ' ';
        border-bottom: 2px solid #47c6ec;
        border-bottom: 2px solid var(--em-light-blue);
        border-top: none;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    body .em-tabs-wrap-link .em-tabs-navigation-wrap .em-tabs-navigation-item-active::after {
        position: absolute;
        display: block;
        content: ' ';
        border-bottom: 2px solid #47c6ec;
        border-bottom: 2px solid var(--em-light-blue);
        border-top: none;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .em-text-area {
        width: 100%;
        max-width: 100%;
        min-height: 3.75rem;
        min-height: var(--textarea-min-height);
        border: 1px solid #e6e8eb;
        border: var(--textarea-border);
        font-size: 1.0625rem;
        font-size: var(--textarea-font-size);
        padding: 1.25rem;
        padding: var(--textarea-padding);
        outline: none;
        font-weight: 400;
    }

    .em-text-area--nonresizable {
        resize: none;
    }

    .em-text-area-size-large {
        height: 15.625rem;
        height: var(--textarea-height);
    }

    .em-text-area-type-indented {
        width: calc(100% - 3rem);
        margin-left: 3rem;
    }

    .em-text-area.disabled,
    .em-text-area[disabled] {
        pointer-events: none;
        cursor: not-allowed;
    }

    textarea.em-text-area::-webkit-input-placeholder {
        font-weight: 400;
    }

    textarea.em-text-area:-ms-input-placeholder {
        font-weight: 400;
    }

    textarea.em-text-area::-ms-input-placeholder {
        font-weight: 400;
    }

    textarea.em-text-area::placeholder {
        font-weight: 400;
    }

    .em-text-input-wrap {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    label,
    .em-field-static {
        font-size: 1.25rem;
        font-size: var(--text-field-title-font-size);
    }

    .em-table-row > .em-field-wrap > .em-field-label > label,
    .em-field-section .em-field-label > label,
    .em-indented .em-field-label > label {
        font-size: 1.5rem;
        line-height: 1.32;
        /* font-weight: bold; */
    }

    .em-table-row > .em-field-wrap > .em-field-label > label,
    .em-field-section .em-field-label > label,
    .em-indented .em-field-label > label span {
        font-size: 16px;
        line-height: 1.5;
    }

    .em-table-row .em-field-static.em-table-heading {
        margin-bottom: 12px;
    }

    .em-table-row .em-field-static.em-table-heading .em-field-label {
        margin-bottom: 0;
        font-size: 14px !important;
        font-weight: 700;
    }
    .em-table-row .em-table-heading {
        font-size: 0.875rem;
        font-size: var(--font-size-table-title-heading);
        line-height: 1.3;
    }

    /*
        Fixing alignment of the tooltip in `Field.Title`
        Since the Tooltip (24px) is bigger that the text (19.5px), the alignment is off.
        What would make more sense is to have `vertical-align: middle;`,
        but for some reason the first parent that is a block (`display: block;`) will have the height miscalculated by a ~1.5px (~25.5px),
        making layout a bit off since it should take largest height (Tooltip = 24px).
    */
    .em-title-section .c-tooltip,
    .em-title-section .c-tooltip > svg {
        vertical-align: top;
    }

    .em-field-section > .em-field-label > label,
    .em-form-page
        > .em-field-section.without-title
        > .em-group
        > .em-field-wrap:not(.em-indented)
        > .em-field-label
        > label {
        font-size: 1rem;
        font-family: Inter;
        margin-bottom: var(--text-field-label-margin-bottom);
    }

    .em-text-input {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        font-family: 'Calibre';
        font-size: 1.25rem;
        font-size: var(--text-field-font-size);
        font-weight: 300;
        font-weight: var(--text-field-font-weight);
        padding: 0 0.625rem;
        padding: var(--text-field-padding);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 2.75rem;
        height: var(--text-field-height);

        border: 1px solid #e6e8eb;

        border: var(--text-field-border);
        position: relative;
        outline: none;
    }

    .em-text-input:-webkit-autofill {
        font-size: 1.25rem;
        font-size: var(--text-field-font-size);
        font-weight: 300;
        font-weight: var(--text-field-font-weight);
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-text-input {
            height: 3.375rem;
            height: var(--text-field-height-tall);
        }
    }

    .em-text-input::-webkit-input-placeholder {
        font-weight: 300;
    }

    .em-text-input:-ms-input-placeholder {
        font-weight: 300;
    }

    .em-text-input::-ms-input-placeholder {
        font-weight: 300;
    }

    .em-text-input::placeholder {
        font-weight: 300;
    }

    .em-text-input[disabled] {
        background: #fbfcff;
        background: var(--em-lightest-gray);
        pointer-events: none;
        cursor: not-allowed;
    }

    .em-text-input-short {
        width: 130px;
    }

    /*label form rule*/

    .em-field-label {
        display: block;
    }

    .em-validation-message {
        font-size: var(--validation-font-size);
        margin-top: 4px;
        line-height: 1.125rem;
    }

    .em-validation-box {
        position: relative;
    }

    .em-validation-box .em-validation-message {
        bottom: 4px;
    }

    .locations-form {
        position: relative;
    }

    .locations-form .invalid-form {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        top: 10px;
        left: 10px;
    }

    .em-error-container .em-validation-message {
        position: relative;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-validation-message {
            font-size: 1.125rem;
            font-size: var(--font-size-mobile);
            line-height: 1.41;
            line-height: var(--line-height-base);
        }
    }

    #em-tooltip,
    .em-tooltip {
        background: #ffffff;
        background: var(--tooltip-background-color);
        border: 1px solid #e6e8eb;
        border: var(--tooltip-border);
        -webkit-box-shadow: 1px 1px 16px 0 #e6e8eb;
        box-shadow: 1px 1px 16px 0 #e6e8eb;
        -webkit-box-shadow: var(--tooltip-box-shadow);
        box-shadow: var(--tooltip-box-shadow);
        padding: 1.5rem;
        padding: var(--tooltip-padding);
        position: absolute;
        z-index: 100;
        font-size: 1.0625rem;
        font-size: var(--tooltip-font-size);
        line-height: 1.5rem;
        line-height: var(--tooltip-line-height);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: left;
        word-wrap: break-word;
        max-width: 440px;
    }

    #em-tooltip h2,
    .em-tooltip h2 {
        font-size: 1.0625rem;
        font-size: var(--tooltip-font-size);
        line-height: 1.5rem;
        line-height: var(--tooltip-line-height);
        font-weight: 500;
        font-weight: var(--tooltip-heading-font-weight);
        margin: 0 0 12px;
    }

    #em-tooltip a,
    .em-tooltip a {
        text-decoration: none;
    }

    .em-tooltip-inline-dotted {
        border-bottom: 1px dashed #4654e2;
        border-bottom: 1px dashed var(--em-med-blue);
        cursor: default;
    }

    .em-tooltip-inline-dotted.click-tooltip {
        cursor: pointer;
    }

    .em-tooltip-text--normal {
        font-weight: 400;
        font-weight: var(--font-weight-normal);
    }

    #em-tooltip:after, .em-tooltip:after /* triangle decoration */ {
        width: 16px;
        height: 16px;
        background: #ffffff;
        background: var(--tooltip-background-color);
        border-style: solid;
        border-width: 1px;
        -webkit-transform: translateY(0) rotate(45deg);
        transform: translateY(0) rotate(45deg);
        -webkit-box-shadow: 1px 1px 1px 0 #e6e8eb;
        box-shadow: 1px 1px 1px 0 #e6e8eb;
        -webkit-box-shadow: var(--tooltip-after-box-shadow);
        box-shadow: var(--tooltip-after-box-shadow);
        content: '';
        position: absolute;
        left: 50%;
        bottom: -8px;
        margin-left: -8px;
    }

    #em-tooltip.top-tooltip:after {
        -webkit-transform: translateY(0) rotate(-135deg);
        transform: translateY(0) rotate(-135deg);
        top: -8px;
        bottom: auto;
    }

    #em-tooltip.left-tooltip:after,
    .em-tooltip::after {
        left: 10px;
        margin: 0;
    }

    #em-tooltip.right-tooltip:after {
        right: 10px;
        left: auto;
        margin: 0;
    }

    /* headings */

    .em-h1 {
        font-size: 1.75rem;
        font-size: var(--font-size-heading-h3);
        line-height: 1.41;
        line-height: var(--line-height-base);
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
    }

    .em-h1-l {
        font-size: 52px;
        font-weight: 600;
    }

    .em-h1-xl {
        font-size: 100px;
        font-weight: 600;
    }

    .em-h1-heading {
        font-size: 3rem;
        font-size: var(--font-size-heading-h1-big);
        font-weight: 300;
        font-weight: var(--font-weight-light);
    }

    .em-h1-large {
        font-size: 36px;
        font-weight: 600;
        font-weight: var(--font-weight-bold);
    }

    .em-h1-extra-large {
        font-size: 3.05rem;
        font-size: var(--font-size-heading-extra-large);
        font-weight: 600;
        font-weight: var(--font-weight-bold);
    }

    .em-h1-title-border {
        padding-bottom: 1.5rem;
        padding-bottom: var(--spacing-main);
        margin-bottom: 1.5rem;
        margin-bottom: var(--spacing-main);
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--border-solid-gray);
    }

    .em-h2 {
        font-size: 1.5rem;
        font-size: var(--font-size-heading-h2);
        line-height: 1.41;
        line-height: var(--line-height-base);
        font-weight: 300;
        font-weight: var(--font-weight-light);
    }

    .em-h2-strong {
        font-weight: 600;
        font-weight: var(--font-weight-bold);
    }

    .em-h3 {
        font-size: 0.875rem;
        font-size: var(--font-size-heading-h3);
        line-height: 1.41;
        line-height: var(--line-height-base);
        font-weight: 300;
        font-weight: var(--font-weight-light);
    }


    .em-h3-strong {
        font-weight: 600;
        font-weight: var(--font-weight-bold);
    }

    /* text */

    .em-p {
        font-size: 1.25rem;
        font-size: var(--font-size-p);
        line-height: 1.41;
        line-height: var(--line-height-base);
    }

    .em-p1 {
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        line-height: 1.41;
        line-height: var(--line-height-base);
        font-weight: 400;
        font-weight: var(--font-weight-normal);
    }

    .em-p1-strong {
        font-weight: 600;
    }

    .em-p1-semi {
        font-weight: 500;
    }

    .em-p1-green {
        min-width: 100%;
    }

    .em-p1-upper {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
    }

    .em-p1-full-width {
        min-width: 300px;
    }

    .em-p1-text-right {
        text-align: right;
    }

    .em-p2 {
        font-size: 0.9375rem;
        font-size: var(--font-size-p2);
        line-height: 1.41;
        line-height: var(--line-height-base);
        font-weight: 400;
        font-weight: var(--font-weight-normal);
    }

    .em-p2-gray {
        text-transform: none;
    }

    .em-p2-strong {
        font-weight: 600;
    }

    .text-heading-medium {
        font-size: 22px;
        font-weight: 300;
    }

    .text-heading-big {
        font-size: 28px;
        font-weight: 600;
    }

    .text-heading-large {
        font-size: 52px;
        font-weight: 600;
    }

    .text-heading-x-large {
        font-size: 100px;
        font-weight: 600;
    }

    .text-heading strong {
        font-weight: 600;
    }

    .text-p-small {
        font-size: 15px;
        font-weight: 400;
    }

    .text-p-normal {
        font-size: 17px;
        font-weight: 400;
    }

    .text-p-big {
        font-size: 19px;
        font-weight: 400;
    }

    .text-p strong {
        font-weight: 600;
    }

    .em-uppercase {
        text-transform: uppercase;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        html,
        body,
        div,
        span,
        p,
        a,
        ul,
        ol,
        li,
        label {
            font-size: 1.125rem;
            font-size: var(--font-size-mobile);
            line-height: 1.41;
            line-height: var(--line-height-base);
        }

        .em-h1 {
            text-align: left;
        }
    }

    /*!
 * https://github.com/YouCanBookMe/react-datetime
 */

    .rdt {
        position: relative;
    }

    .rdtPicker {
        display: none;
        position: absolute;
        width: 250px;
        padding: 4px;
        margin-top: 1px;
        z-index: 99999 !important;
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        border: 1px solid #f9f9f9;
    }

    .rdtOpen .rdtPicker {
        display: block;
    }

    .rdtStatic .rdtPicker {
        -webkit-box-shadow: none;
        box-shadow: none;
        position: static;
    }

    .rdtPicker .rdtTimeToggle {
        text-align: center;
    }

    .rdtPicker table {
        width: 100%;
        margin: 0;
    }

    .rdtPicker td,
    .rdtPicker th {
        text-align: center;
        height: 28px;
    }

    .rdtPicker td {
        cursor: pointer;
    }

    .rdtPicker td.rdtDay:hover,
    .rdtPicker td.rdtHour:hover,
    .rdtPicker td.rdtMinute:hover,
    .rdtPicker td.rdtSecond:hover,
    .rdtPicker .rdtTimeToggle:hover {
        background: #eeeeee;
        cursor: pointer;
    }

    .rdtPicker td.rdtToday {
        position: relative;
    }

    .rdtPicker td.rdtToday:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #428bca;
        position: absolute;
        bottom: 4px;
        right: 4px;
    }

    .rdtPicker td.rdtActive,
    .rdtPicker td.rdtActive:hover {
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }


    .rdtPicker td.rdtDisabled,
    .rdtPicker td.rdtDisabled:hover {
        background: none;
        cursor: not-allowed;
    }

    .rdtPicker td span.rdtDisabled,
    .rdtPicker td span.rdtDisabled:hover {
        background: none;
        cursor: not-allowed;
    }

    .rdtPicker th {
        border-bottom: 1px solid #f9f9f9;
    }

    .rdtPicker .dow {
        width: 14.2857%;
        border-bottom: none;
        cursor: default;
    }

    .rdtPicker th.rdtSwitch {
        width: 100px;
    }

    .rdtPicker th.rdtNext,
    .rdtPicker th.rdtPrev {
        font-size: 21px;
        vertical-align: top;
    }

    .rdtPrev span,
    .rdtNext span {
        display: block;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
    }

    .rdtPicker th.rdtDisabled,
    .rdtPicker th.rdtDisabled:hover {
        cursor: not-allowed;
    }

    .rdtPicker thead tr:first-child th {
        cursor: pointer;
    }

    .rdtPicker thead tr:first-child th:hover {
        background: #eeeeee;
    }

    .rdtPicker tfoot {
        border-top: 1px solid #f9f9f9;
    }

    .rdtPicker button {
        border: none;
        background: none;
        cursor: pointer;
    }

    .rdtPicker thead button {
        width: 100%;
        height: 100%;
    }

    td.rdtMonth,
    td.rdtYear {
        height: 50px;
        width: 25%;
        cursor: pointer;
    }

    td.rdtMonth:hover,
    td.rdtYear:hover {
        background: #eee;
    }

    .rdtCounters {
        display: inline-block;
    }

    .rdtCounters > div {
        float: left;
    }

    .rdtCounter {
        height: 100px;
    }

    .rdtCounter {
        width: 40px;
    }

    .rdtCounterSeparator {
        line-height: 100px;
    }

    .rdtCounter .rdtBtn {
        height: 40%;
        line-height: 40px;
        cursor: pointer;
        display: block;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
    }

    .rdtCounter .rdtBtn:hover {
        background: #eee;
    }

    .rdtCounter .rdtCount {
        height: 20%;
        font-size: 1.2em;
    }

    .rdtMilli {
        vertical-align: middle;
        padding-left: 8px;
        width: 48px;
    }

    .rdtMilli input {
        width: 100%;
        font-size: 1.2em;
        margin-top: 37px;
    }

    .rdtTime td {
        cursor: default;
    }

    /* business profile */

    .em-business-profile {
        max-width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        margin-bottom: 48px;
    }

    .em-business-profile-form {
        max-width: 716px;
    }

    .em-business-profile .em-h1 {
        margin: 50px 0;
    }

    /* .form-row class from <em-flex-layout> - used only in Business profile;
    * If it is needed somewhere else then write general styles for it as well.
    */

    .em-business-profile .form-row {
        width: 100%;
        max-width: 716px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .em-business-profile .form-row:first-child {
        margin-top: -18px;
    }

    .em-business-profile .form-row-item {
        min-width: 6.25rem;
        min-width: var(--form-field-min-width);
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
        width: 100%;
        margin: 18px 24px 0 0;
    }

    .em-business-profile .form-row-item:last-of-type {
        margin-right: 0;
    }

    .em-business-profile .form-row-item-static-label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
    }

    .em-business-profile .form-row-item-full-width {
        max-width: 716px;
    }

    .em-business-profile .form-row .em-field-label {
        height: 21px;
        font-size: 15px;
    }

    .em-business-profile-location {
        padding: 1.125rem 0;
        padding: var(--spacing-small) 0;
    }

    .em-business-profile-location-wrap {
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--border-solid-gray);
        display: block;
    }

    .em-business-profile-location-wrap:first-child {
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
    }

    .em-business-profile-location-wrap:last-of-type {
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .em-business-profile-location-title {
        width: 100%;
        padding-left: 2.25rem;
        padding-left: var(--spacing-medium);
    }

    .em-business-profile-location-title-wrap {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        font-weight: 400;
        font-weight: var(--font-weight-normal);
    }

    .em-business-profile-location-city-state-zip .form-row-item:nth-child(1) {
        width: 346px;
    }

    .em-business-profile-location-city-state-zip .form-row-item:nth-child(2) {
        width: 210px;
    }

    .em-business-profile-location-city-state-zip .form-row-item:nth-child(3) {
        /* margin-right: 0; */
        width: 112px;
    }

    .em-business-profile .em-collapsable {
        padding-left: 0;
    }

    /* border-bottom: 1px solid red; */

    .em-business-profile .em-collapsable:first-child {
    }

    .em-business-profile .em-collapsable-heading::before {
        position: absolute;
        left: 0;
    }

    .em-business-profile-save-button-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        padding: 2.25rem;
        padding: var(--spacing-medium);
        margin: 0 0 2.25rem;
        margin: 0 0 var(--spacing-medium);
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        border-top: none;
        background: #ffffff;
        background: var(--em-white);
    }

    .em-business-profile-revenue-year-row .em-field-label {
        text-transform: uppercase;
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    .em-business-profile-revenue-year-row .form-row-item-static-label {
        font-size: 1.25rem;
        font-size: var(--text-field-font-size);
        height: 2.75rem;
        height: var(--text-field-height);
        -ms-flex-item-align: end;
        align-self: flex-end;
    }

    .em-business-profile-revenue-year-row:last-of-type {
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .em-location-collapse-wrap {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        font-weight: 400;
        font-weight: var(--font-weight-normal);
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 1.125rem 0;
        padding: var(--spacing-small) 0;
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
    }

    .em-location-collapse-wrap .em-table-row-remove {
        position: static !important;
        position: initial !important;
    }

    .em-location-collapse-title {
        width: 100%;
        padding-left: 1.125rem;
        padding-left: var(--spacing-small);
    }

    .em-location-field {
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .em-location-field .em-field-wrap.em-text-input-wrap {
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .em-location-field .em-field-wrap.em-text-input-wrap:last-of-type {
        margin-right: 0;
    }

    .em-location-field .em-required-field label::after {
        position: absolute;
        content: '*';
        font-size: 16px;
    }

    .em-location-address-fields,
    .em-location-city-state-zip {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .em-location-city-state-zip {
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .em-location-city-state-zip .em-field-wrap.em-text-input-wrap {
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .em-location-city-state-zip .em-field-wrap.em-text-input-wrap:nth-child(1) {
        max-width: 346px;
    }

    .em-location-city-state-zip .em-field-wrap.em-text-input-wrap:nth-child(2) {
        width: 208px;
    }

    .em-location-city-state-zip .em-field-wrap.em-zip-input-wrap {
        width: 112px;
    }

    .em-location-city-state-zip
        .em-field-wrap.em-zip-input-wrap
        .em-input.em-text-input.em-zip-input {
        width: 112px;
    }

    .em-location-collapse-button::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-right: 2px solid #47c6ec;
        border-right: 2px solid var(--em-light-blue);
        border-bottom: 2px solid #47c6ec;
        border-bottom: 2px solid var(--em-light-blue);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .em-location-collapse-button-expanded::before {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .admin-rc-accordion .accordion-header {
        cursor: pointer;
    }

    .admin-rc-accordion .accordion-header .row-layout {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .admin-rc-accordion .accordion-header .accordion-sprite-layout {
        margin-right: 12px;
    }

    .admin-rc-accordion .accordion-header .accordion-header-label {
        font-size: 20px;
    }

    .admin-rc-accordion .accordion-header .accordion-header-component {
        width: 100%;
    }

    .em-select.em-search-naics .em-select-item {
        display: block;
        min-height: auto;
        height: auto;
        cursor: pointer;
        text-align: left;
        overflow: visible;
        padding: 0.25rem 0.625rem;
    }

    .em-select.em-search-naics .em-select-item-first-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        font-size: 1.375rem;
        font-size: var(--select-font-size);
        line-height: 24px;
    }

    .em-select.em-search-naics .em-select-item .em-select-item-second-row {
        font-size: 12px;
        line-height: 1.41;
        line-height: var(--line-height-base);
        margin-top: 0.25rem;
    }

    .em-select.em-search-naics
        .em-select-item
        .em-select-item-second-row
        .naics-matches
        span:after {
        content: ', ';
    }

    .em-select.em-search-naics
        .em-select-item
        .em-select-item-second-row
        .naics-matches
        span:last-child:after {
        content: '';
    }

    .em-select.em-search-naics input {
        min-width: 535px;
        border: 1px solid #e6e8eb;
        border: var(--text-field-border);
    }

    .em-select.em-search-naics .em-select-dd {
        top: 100%;
        border: 1px solid #e6e8eb;
        border: 1px solid var(--select-border-color);
        max-height: 320px;
        overflow-y: auto;
    }

    .em-select.em-search-naics .em-select-dd .em-highlight {
        font-weight: 500;
    }

    .em-read-only .em-search-naics input {
        cursor: default;
    }

    /*********** form styles ***********/

    .em-form-renewal-container {
        margin-left: auto;
        margin-right: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .em-form-renewal-message {
        margin-bottom: 20px;
    }

    .em-form-renewal-button-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 100px;
    }

    .em-form-renewal-button-container .em-form-renewal-button {
        margin-right: 40px;
    }

    .em-form-container {
        margin-top: calc(2.25rem * -1);
        margin-top: calc(var(--spacing-medium) * -1);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .em-form {
        width: 100%;
    }

    .em-forms-page-wrap {
        width: 100%;
    }

    .em-form-page > .em-field-wrap {
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .em-form-page > .em-field-section {
        max-width: 100%;
    }

    .em-field-section > .em-field-label {
        min-height: 0;
    }

    .em-field-wrap {
        position: relative;
    }

    .em-field-wrap,
    .em-form-page > .em-field-section .em-group {
        width: 100%;
    }

    .em-group .em-field-wrap {
        max-width: 100%;
    }

    .em-three-columns .em-input {
        max-width: 200px;
        min-width: 20px;
    }

    .em-three-columns > .em-question-spacing {
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .em-input {
        min-width: 6.25rem;
        min-width: var(--form-field-min-width);
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
        width: 100%;
    }

    .em-input--wide {
        max-width: none;
        width: 100%;
    }

    .em-input.em-text-area {
        max-width: none;
    }

    input[type='number'] {
        width: 100%;
    }

    .em-field-hidden {
        display: none;
    }

    .em-date-input-wrap > .em-input.em-text-input {
        min-width: 6.25rem;
        min-width: var(--form-field-min-width);
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
        width: 100%;
    }

    .em-table-row.em-direction-row
        .vertical-radios.em-select-input-wrap
        .select-field-option-wrapper.em-indented {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    /* table headings - rows and columns */

    .em-group.em-table-row.em-direction-row .em-table-row > .em-table-row .em-row-heading,
    .em-table-row > .em-table-row .em-table-row-remove {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    /* navigation */

    .em-nav-container {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        border-right: solid 1px #e8e7e7;
        width: 290px;
        height: 100%;
    }

    .em-nav-container .em-nav-container {
        border: none;
        height: auto;
    }

    .em-nav-container .em-nav-container .em-nav-text {
        min-height: 0;
        padding: 6px 40px 6px 40px;
    }

    .em-nav-container .em-nav-container .em-nav-item-is-invalid .em-nav-text:after {
        content: '!';
        position: absolute;
        right: 11px;
        font-weight: 600;
        font-weight: var(--font-weight-bold);
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        line-height: 18px;
        text-align: center;
    }

    .em-nav-container .em-nav-text + .em-nav-container {
        margin-top: -20px;
        padding-bottom: 20px;
    }

    .em-nav-container .em-nav-text {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 80px;
        padding: 20px;
        font-weight: 400;
        font-size: 16px;
        margin-top: 1px;
    }

    .em-nav-container a.em-nav-text:hover span {
        position: relative;
    }

    .em-nav-container a.em-nav-text:hover span:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid currentColor;
    }

    /* vertical spacing */

    .em-question-spacing + .em-question-spacing {
        margin-top: 0.75rem;
        margin-top: var(--spacing-xsmall);
    }

    .em-question-spacing + .em-question-spacing.em-field-section {
        margin-top: 2rem;
        margin-top: var(--spacing-32);
    }

    .em-question-spacing + .em-description {
        margin-top: 0.75rem;
        margin-top: var(--spacing-xsmall);
        margin-bottom: 0.75rem;
        margin-bottom: var(--spacing-xsmall);
    }

    .em-form-page > .em-question-spacing + .em-question-spacing {
        margin-top: 2.25rem;
        margin-top: 30px;
    }

    .em-table-row > .em-field-section + .em-field-section {
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    /* horizontal */

    .em-direction-row.em-field-group,
    .em-direction-row.em-field-section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-direction-row.em-field-group .em-group .em-question-spacing:last-child,
    .em-direction-row.em-field-section .em-group .em-question-spacing:last-child {
        margin-right: 0;
    }

    .em-direction-row.em-field-group .em-group,
    .em-direction-row.em-field-section .em-group {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .em-direction-row.em-field-group .em-group .em-select-input-wrap,
    .em-direction-row.em-field-section .em-group .em-select-input-wrap {
        width: 100%;
        /* max-width: 320px; */
    }

    /*********** fields styles ***********/

    .em-field-label {
        min-height: 1em;
        font-size: 1rem !important;
        line-height: 1.5;
        min-height: var(--form-field-label-min-height);
    }

    .form-button-wrapper {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .form-button-wrapper .em-button {
        -webkit-transform: translateZ(0);
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }

    .form-button-wrapper div {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        text-align: left;
    }

    .form-button-wrapper div:last-child {
        text-align: right;
    }

    /* icons */

    .em-icon {
        display: inline-block;
    }

    .em-icon-drop-zone {
        width: 29px;
        height: 29px;
        min-width: 29px;
        min-height: 29px;
        /* background: url(../assets/images/page-drop-icon.svg) no-repeat; */
    }

    .em-icon-retry-upload {
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
        /* background: url(../assets/images/reset.png) no-repeat; */
        background-size: 14px 14px;
    }

    .em-icon-remove-file {
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
        /* background: url(../assets/images/close.svg) no-repeat; */
    }

    .em-icon-document {
        width: 17px;
        height: 21px;
        min-width: 17px;
        min-height: 21px;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDE2IDIxIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZmlsbD0iIzY2NzNGRiIgZD0iTTE1LjUzIDcuOTdMOC44NC40NTZILjQ3MXYyMC4wODdoMTUuMDU4eiIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNGRUZFRkUiIGQ9Ik0xMy43NzIgOC4wNzNIOC42NzNWMi4zNjd6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=)
            no-repeat;
    }

    button .em-icon {
        -webkit-box-flex: 0;
        -ms-flex: 0 0;
        flex: 0 0;
    }

    /* file upload */

    .em-file-upload-drop-zone {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 12px 20px;
        min-height: 72px;
        border: 1px solid #e6e8eb;
        background: #fff;
        margin-top: 10px;
        position: relative;
    }

    .em-file-upload-drop-zone-is-active {
        -webkit-box-shadow: 0 1px 10px 0 rgba(102, 115, 255, 0.4);
        box-shadow: 0 1px 10px 0 rgba(102, 115, 255, 0.4);
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .em-file-upload-drop-zone-is-not-allowed {
        -webkit-box-shadow: 0 1px 10px 0 rgba(255, 112, 102, 0.4);
        box-shadow: 0 1px 10px 0 rgba(255, 112, 102, 0.4);
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .em-file-upload-drop-zone-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-left: 20px;
        border-left: 1px solid #d4d4d4;
        padding-left: 20px;
        height: 100%;
        cursor: default;
    }

    .em-file-upload-drop-zone-info-large {
        padding: 60px;
        width: 100%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-file-upload-drop-zone-info .em-icon {
        margin: 0 10px;
    }

    .em-file-upload-drop-zone .em-button {
        max-width: -webkit-min-content;
        max-width: -moz-min-content;
        max-width: min-content;
    }

    .em-file-upload-file-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .em-file-upload a {
        font-weight: 400;
        font-weight: var(--font-weight-normal);
        margin-top: 0.75rem;
        margin-top: var(--spacing-xsmall);
        margin-bottom: 0.5rem;
        margin-bottom: var(--spacing-tiny);
    }

    .em-file-upload a:hover {
        text-decoration: underline !important;
    }

    .em-file-tile {
        display: inline-block;
        border: 1px solid #d4d4d4;
        height: 36px;
        margin: 10px 10px 0 0;
        white-space: nowrap;
        overflow: hidden;
        cursor: normal;
        position: relative;
        max-width: 50em;
    }

    .em-file-tile-contents {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 10px;
    }

    .em-file-tile-controls {
        max-width: 60px;
        min-width: 60px;
        text-align: right;
    }

    .em-file-tile-controls button {
        border: none;
        background: none;
        margin-left: 10px;
        cursor: pointer;
        opacity: 0.8;
    }

    .em-file-tile-controls button:hover {
        opacity: 1;
    }

    .em-file-tile-progress {
        position: absolute;
        left: -1px;
        bottom: -1px;
        height: 2px;
        background: #47c6ec;
        border-left: 1px solid #47c6ec;
        border-right: 1px solid #47c6ec;
    }

    .em-file-tile-icon {
        margin-right: 5px;
    }

    .em-file-tile a {
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .em-file-tile a:hover {
        text-decoration: underline !important;
    }

    .em-file-tile span {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    /* table styles */

    .em-form-page:first-child {
        max-width: 100%;
    }

    .em-business-profile .em-form-page {
        max-width: 788px;
    }

    .form-button-wrapper.em-direction-row {
        padding: 24px 0px;
        border-top: 1px solid #e7e9ec;
        border-top: 1px solid var(--border-color);
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    /* when we have 3 tables inside one another that have vertical alignment */

    .em-field-table .em-group .em-group .em-group.em-direction-column {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-table-row > .em-table-row.em-direction-row.em-direction-column {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-table-row > .em-table-row.em-direction-row.em-direction-column .em-question-spacing,
    .em-table-row
        > .em-table-row.em-direction-row.em-direction-column
        .em-question-spacing
        .c-radio-group__list {
        width: 100%;
        /* min-width: 120px; */
    }

    .em-form-page > .em-question-spacing {
        margin-right: 0;
    }

    .without-title > .em-direction-row > .em-field-static {
        margin-top: 19px;
    }

    .em-table .em-table-row.em-group:not(.em-column-heading) .em-table-heading {
        margin-bottom: auto;
    }

    .em-group.em-table > .em-question-spacing {
        margin-right: 0;
    }

    .em-table-button-row {
        padding: 0.625rem 0;
        height: auto;
        -ms-flex-item-align: baseline;
        align-self: baseline;
    }

    .em-table-row > .em-table-row.em-table-row-border {
        padding-bottom: 1.125rem;
        padding-bottom: var(--spacing-small);
        /* XXX: disabled due to duplicate borders, investigate if safe to remove (including the styleHint) */
    }

    /* border-bottom: 1px solid var(--border-color); */

    .em-table-row > .em-table-row.em-table-row-border .em-table-row-remove {
        display: block;
        height: auto;
    }

    .em-table-row > .em-table-row.em-column-heading {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        padding: 0.625rem 0;
        border-bottom: 1px solid #ebedff;
        border-bottom: 1px solid var(neutral-light);
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: 500;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .em-table-row > .em-table-row .em-row-heading,
    .em-table-row > .em-table-row .em-table-row-remove {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        min-height: 36px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -ms-flex-item-align: center;
        align-self: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .em-table-row > .em-table-row .em-row-heading .em-button,
    .em-table-row > .em-table-row .em-table-row-remove .em-button {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }

    .em-table-row > .em-table-row .em-table-row-remove {
        padding-left: 8px;
    }

    .em-table .em-table-row-removable {
        margin-bottom: 1.5rem;
        margin-bottom: var(--spacing-main);
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--border-solid-gray);
        padding-bottom: 1.5rem;
        padding-bottom: var(--spacing-main);
    }

    .em-table .em-table-row-removable .em-table-row-remove {
        margin-left: auto;
    }

    .em-table .em-table-row-removable .em-table-button-row {
        margin-top: -24px;
    }

    /* .em-table .em-table-row-removable:first-child {
        margin-top: calc(-1 * 1.5rem);
        margin-top: calc(-1 * var(--spacing-main));
    } */

    .em-table .em-table-row-removable + .em-table-row-removable {
        margin-top: calc(-1 * 0.75rem);
        margin-top: calc(-1 * var(--spacing-xsmall));
    }

    .em-table.table-column-grid .em-table-row-remove {
        -webkit-box-ordinal-group: 101;
        -ms-flex-order: 100;
        order: 100;
    }

    .em-row-heading-wrap .em-row-heading {
        min-width: 223px;
    }

    .em-multiselect-coverage-full-width {
        max-width: 100%;
    }

    .multiselect-option-list-coverage {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        background: #ffffff;
        background: var(--em-white);
    }

    .multiselect-option-list-coverage .em-check-box {
        position: relative;
        padding: 1.5rem;
        padding: var(--spacing-main);
        margin: 0 1.5rem 1.5rem 0;
        margin: 0 var(--spacing-main) var(--spacing-main) 0;
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        min-height: 220px;
        width: 319px;
        max-width: 319px;
        min-width: unset;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .multiselect-option-list-coverage .em-check-box:nth-child(3n) {
        margin-right: 0;
    }

    .multiselect-option-list-coverage .em-check-box.is-checked::before {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        position: absolute;
        top: 0;
        left: 0;
        background: #47c6ec;
        background: var(--em-light-blue);
    }

    .multiselect-option-list-coverage .em-check-box .em-icon {
        margin-bottom: 0.75rem;
        margin-bottom: var(--spacing-xsmall);
    }

    .multiselect-option-list-coverage .em-check-box .em-h1 {
        margin: 0 0 15px 0;
        padding: 0;
    }

    .multiselect-option-list-coverage .em-check-box .em-coverage-checkbox-description {
        min-height: 230px;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
    }

    .multiselect-option-list-coverage .em-check-box label {
        position: absolute;
        bottom: 1.125rem;
        bottom: var(--spacing-small);
        right: 0;
        left: 0;
        width: 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

    .em-two-columns .multiselect-option-list,
    .em-two-columns .em-group {
        -webkit-columns: 2;
        columns: 2;
    }

    .em-two-columns .em-group {
        -webkit-columns: 2;
        columns: 2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .em-two-columns .em-check-box {
        display: block;
        -webkit-column-break-inside: avoid;
        break-inside: avoid-column;
        min-height: auto;
    }

    .em-two-columns.em-table-row .em-table-heading {
        max-width: 223px;
    }

    .em-four-columns.em-table-row .em-table-heading,
    .em-four-columns.em-table-row .em-input {
        max-width: 155px;
    }

    .em-text-area-minimal .em-text-area {
        min-height: 36px;
        max-height: 36px;
        padding: 0 0.625rem;
        line-height: 1.41;
    }

    .em-sub-heading {
        margin: 1rem 0;
        padding: 1.5rem 0;
        border-bottom: 1px solid #e7e9ec;
        border-bottom: 1px solid var(--border-color);
        font-size: 17px;
        font-weight: normal;
    }

    .em-sub-heading > * {
        margin: 0;
    }

    .em-sub-heading-no-border {
        margin: 1.5rem 0 1rem;
        padding-bottom: 1rem;
        font-size: 17px;
        font-weight: 500;
        border: none;
    }

    .em-section-title > .em-field-label label {
        margin: 1.125rem 0;
        margin: var(--spacing-small) 0;
        font-size: 20px;
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
    }

    .em-direction-row.em-field-group .em-group {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .em-group.em-table-row-removeable.em-table-row.em-direction-row.em-pl-row-rules {
        /* TODO: 'em-pl-row-rules' is selector assigned directly to spec element in order to adjust spacing issues on About Your Staff page. To be removed after TD tasks */
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        position: relative;
    }

    .em-group.em-table-row-removeable.em-table-row.em-direction-row.em-pl-row-rules
        .em-check-box
        label {
        margin-left: 36px;
    }

    .em-group.em-table-row-removeable.em-table-row.em-direction-row.em-pl-row-rules
        .em-table-row-remove {
        position: absolute;
        right: 0;
        -ms-flex-item-align: baseline;
        align-self: baseline;
    }

    .em-group.em-table-row-removeable.em-table-row.em-direction-row.em-pl-row-rules .em-input {
        min-width: 310px;
    }

    /* layout fixes */

    .em-table-row > .em-table-row.em-column-heading,
    .em-table-row > .em-table-row .em-row-heading {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: flex-end;
        -ms-flex-align: flex-end;
        align-items: flex-end;
        justify-content: flex-start;
        margin-top: 0;
        padding: 0;
    }

    .em-table-row.em-column-heading .em-table-heading .em-field-label {
        font-size: 0.75rem !important;
        font-weight: 700;
    }

    .em-table-row.em-direction-row .em-select-input-wrap .select-field-option-wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .em-group.em-table.table-column-grid {
        width: 100%;
        max-width: 100%;
    }

    .em-group.em-table.table-column-grid .em-table-row > .em-table-row .em-question-spacing,
    .em-group.em-table.table-column-grid .em-table-row > .em-table-row input[type='date'],
    .em-group.em-table.table-column-grid .em-direction-row .em-text-input-wrap,
    .em-group.em-table.table-column-grid .em-direction-row .em-date-input-wrap {
        max-width: none;
        min-width: 100px;
        width: 100%;
    }

    .em-group.em-table.table-column-grid .em-table-row-removable {
        border-bottom: none;
        padding: 0;
    }

    /* END - table styles */

    /* admin modal - form wrapper */

    .modal-content .em-forms-page-wrap {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
    }

    .em-tooltip-word {
        border-bottom: 2px dotted #47c6ec;
        border-bottom: var(--tooltip-word-underline);
        position: relative;
        display: inline-block;
    }

    .em-tooltip-word-undecorated {
        position: relative;
        display: inline-block;
    }

    #em-tooltip,
    .embroker-quotes-page #em-tooltip {
        bottom: calc(100% + 10px);
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        font-weight: 300;
        font-weight: var(--font-weight-light);
        text-transform: none;
        min-width: 350px;
        position: absolute;
    }

    .shopping-wrap #em-tooltip::after,
    .embroker-quotes-page #em-tooltip::after {
        left: 50%;
    }

    .em-coverage-tabs .em-coverage-tab-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        width: 344px;
        margin: 1.5rem auto 3rem;
        margin: var(--spacing-main) auto var(--spacing-large);
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        position: relative;
        z-index: 1;
    }

    .em-coverage-tabs .em-coverage-tab-list .em-coverage-tab {
        font-size: 1.25rem;
        font-size: var(--font-size-p);
        width: 100%;
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        padding: 0.75rem;
        padding: var(--spacing-xsmall);
        text-align: center;
        position: relative;
        z-index: 1;
        cursor: pointer;
    }

    .em-coverage-tabs .em-coverage-tab-list .em-coverage-tab:first-of-type {
        margin-right: -1px;
    }

    .em-coverage-tabs .em-coverage-tab-list .em-coverage-tab-active {
        position: relative;
        z-index: 10;
    }

    .em-tooltip-dropdown {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .em-tooltip-dropdown > div {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .em-description {
        font-size: 1rem;
    }

    .em-help {
        display: inline-block;
        position: relative;
        width: 15px;
        height: 14px;
        margin-left: 0.5em;
    }

    .em-help .em-tooltip {
        position: absolute;
        display: none;
        bottom: calc(100% + 12px);
        left: -12px;
        width: 300px;
    }

    .em-help .em-tooltip .definition-list dt {
        font-weight: bold;
    }

    .em-help:hover .em-tooltip {
        display: block;
    }

    label {
        font-size: 1.0625rem;
        font-size: var(--font-size-p);
        margin-bottom: 8px;
    }

    label:active {
        background: none; /* reset mobile */
    }

    /* disabled and readOnly inputs */

    .em-select-input-wrap.em-read-only .ui.disabled.selection.dropdown.em-select-field {
        cursor: default;
    }

    .em-read-only .em-text-input,
    .em-read-only .em-text-area,
    .em-read-only .em-select-field {
        cursor: default;
    }

    .em-text-input.em-read-only {
        pointer-events: none;
    }

    .em-text-input.em-previous-value {
        border: none;
    }

    .em-read-only .em-button {
        pointer-events: none;
    }

    /* multiselect styles */

    .ui.multiple.dropdown > .label {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        font-size: 1em;
        padding: 0.35714286em 0.78571429em;
        margin: 0.14285714rem 0.28571429rem 0.14285714rem 0;
        -webkit-box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
        box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
        font-weight: 400;
        font-weight: var(--font-weight-normal);
    }

    .ui.selection.dropdown.em-select-field .ui.label > .close.icon,
    .ui.selection.dropdown.em-select-field .ui.label > .delete.icon {
        cursor: pointer;
        margin-right: 0;
        margin-left: 0.5em;
        font-size: 0.92857143em;
        opacity: 0.5;
        -webkit-transition: background 0.1s ease;
        transition: background 0.1s ease;
        position: relative;
    }

    .ui.selection.dropdown.em-select-field .ui.label > .delete.icon::before {
        content: '';
        /* background: center center no-repeat url(../assets/images/icon-close.svg); */
        width: 11px;
        height: 11px;
        background-size: 11px 11px;
        display: block;
        border: none;
        -webkit-transform: none;
        transform: none;
        right: -7px;
        top: 4px;
    }

    /* react-select styles */

    .em-select-field {
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
    }

    .em-select-field:not(.c-input-field--select) {
        height: 2.75rem;
        height: var(--select-height);
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-select-field {
            height: 3.375rem;
            height: var(--select-height-tall);
        }
    }

    .em-select-field .em-select-field__control,
    .em-select-field .em-select-field__menu {
        -webkit-box-shadow: none;
        box-shadow: none;
        margin: 0;
        border-radius: 0;
        background: #fff;
        width: 100%;
        min-width: 6.25rem;
        min-width: var(--form-field-min-width);
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
        min-height: 2.75rem;
        min-height: var(--select-height);
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-select-field .em-select-field__control,
        .em-select-field .em-select-field__menu {
            min-height: 3.375rem;
            min-height: var(--select-height-tall);
        }
    }

    /* cursor styles */

    .em-select-field .em-select-field__value-container .css-1g6gooi {
        font-size: 20px;
    }

    .em-select-field .em-select-field__single-value {
        height: 25px;
        font-size: 20px;
        font-weight: 300;
        font-weight: var(--font-weight-light);
        top: unset;
        -webkit-transform: none;
        transform: none;
    }

    .em-select-field .em-select-field__menu,
    .em-select-field .em-select-field__menu:hover {
        margin-top: -2px;
    }

    .em-select-field .em-select-field__menu .em-select-field__option,
    .em-select-field .em-select-field__menu:hover .em-select-field__option {
        min-height: 2rem;
        line-height: 2rem;
        font-size: 20px;
        font-weight: 300;
        font-weight: var(--font-weight-light);
        border-bottom: 1px solid #e6e8eb;
        border-bottom: 1px solid var(--em-medium-gray);
    }

    .em-select-field .em-select-field__menu {
        z-index: 100;
        z-index: var(--z-index-dropdown);
    }

    .em-select-field .em-select-field__menu .css-11unzgr.em-select-field__menu-list {
        padding-top: 0;
    }

    .em-select-field .em-select-field__indicator-separator {
        display: none;
    }

    .em-select-field .em-select-field__dropdown-indicator {
        padding: 6px;
    }

    .em-select-field .em-select-field__dropdown-indicator svg {
        fill: #47c6ec;
        fill: var(--em-light-blue);
    }

    /* date picker styles */

    .form-control {
        border-radius: 0;
        font-size: 1.25rem;
        font-size: var(--date-picker-input-font-size);
        border: 1px solid #e6e8eb;
        border: var(--textarea-border);
        -webkit-box-shadow: none;
        box-shadow: none;
        font-weight: 300;
        font-weight: var(--text-field-font-weight);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        height: 2.75rem;
        height: var(--text-field-height);
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .form-control {
            height: 3.375rem;
            height: var(--text-field-height-tall);
        }
    }

    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .rdt {
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
    }

    .rdt::after {
        content: '';
        width: 17px;
        height: 17px;
        display: block;
        position: absolute;
        /* background: no-repeat url(../assets/images/date-picker.svg); */
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 8px;
        pointer-events: none;
    }

    .rdtPicker {
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 1.125rem;
        padding: var(--spacing-small);
    }

    .rdtOpen .rdtPicker {
        background: #fff;
        width: auto;
    }

    .rdtPicker table {
        background: #fff;
    }

    .rdtPicker td.rdtActive,
    .rdtPicker td.rdtActive:hover {
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
    }

    .rdtPicker td.rdtToday::before {
        border-bottom: 7px solid #47c6ec;
        border-bottom: 7px solid var(--date-picker-today-date);
    }

    .rdtPrev span,
    .rdtNext span {
        font-size: 2rem;
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
    }

    .rdtSwitch {
        font-weight: 600;
        letter-spacing: 1px;
    }

    .rdtPicker td {
        cursor: pointer;
        border: 1px solid #bcbdc3;
        border: 1px solid var(--em-medium-gray2);
        padding: 0.75rem;
        padding: var(--spacing-xsmall);
    }

    /* styles for bullets inside of a question label */

    .em-field-label span ul {
        padding-top: 8px;
        margin-left: 8px;
    }

    .em-field-label span ul li {
        padding-bottom: 5px;
        list-style-type: disc;
        margin-left: 10px;
        padding-left: 0;
    }

    .em-kick-out-wrap {
        max-width: 600px;
        min-height: 250px;
        margin: 0 auto;
        text-align: center;
    }

    /* Google Places Widget styles */

    .pac-container {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        border: 1px solid #47c6ec;
        border: 1px solid var(--em-light-blue);
        border-top: none;
        margin-top: -1px;
        font-family: inherit;
        z-index: 100001;
    }

    .pac-icon {
        display: none;
    }

    .pac-item {
        padding: 8px 12px;
        min-height: 2rem;
        line-height: 2rem;
        font-size: 15px;
    }

    .pac-item-query {
        font-size: 17px;
        padding-right: 5px;
    }

    .pac-matched {
        font-weight: 600;
        font-weight: var(--font-weight-bold);
    }

    .grid-col-1 .em-select-field,
    .grid-col-1 .em-select-field__control,
    .grid-col-2 .em-select-field,
    .grid-col-2 .em-select-field__control,
    .grid-col-3 .em-select-field,
    .grid-col-3 .em-select-field__control,
    .grid-col-4 .em-select-field,
    .grid-col-4 .em-select-field__control,
    .grid-col-5 .em-select-field,
    .grid-col-5 .em-select-field__control,
    .grid-col-6 .em-select-field,
    .grid-col-6 .em-select-field__control,
    .grid-col-7 .em-select-field,
    .grid-col-7 .em-select-field__control,
    .grid-col-8 .em-select-field,
    .grid-col-8 .em-select-field__control,
    .grid-col-9 .em-select-field,
    .grid-col-9 .em-select-field__control,
    .grid-col-10 .em-select-field,
    .grid-col-10 .em-select-field__control,
    .grid-col-11 .em-select-field,
    .grid-col-11 .em-select-field__control,
    .grid-col-12 .em-select-field,
    .grid-col-12 .em-select-field__control {
        max-width: 100%;
        min-width: 0;
        width: 100%;
    }

    label.em-radio-button,
    label.em-check-box {
        font-size: 1.0625rem !important;
        font-size: var(--text-field-label-font-size) !important;
    }

    .em-inline-yes-no {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
    }

    .em-inline-yes-no:first-child {
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    .em-inline-yes-no .em-field-label {
        margin: 0;
    }

    .em-inline-yes-no .select-field-option-wrapper {
        width: 100%;
    }

    .em-inline-yes-no .select-field-option-wrapper ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .em-inline-yes-no .select-field-option-wrapper.em-radio-button li {
        width: 50%;
    }
    .em-inline-yes-no .select-field-option-wrapper.em-radio-button li:not(:last-child) {
        margin-right: 0.75rem;
        margin-right: var(--spacing-xsmall);
        width: 50%;
    }

    .em-direction-column {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .em-direction-row {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .em-direction-row > .em-question-spacing {
        margin-top: 0.5rem;
    }

    .em-direction-row > .em-question-spacing {
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .em-direction-row > .em-question-spacing:last-child {
        margin-right: 0;
    }

    .em-direction-row.em-direction-column > .em-question-spacing {
        margin-right: 0;
    }

    /* If we have a table row that has a group which has vertical styleHints */

    .em-direction-row.em-field-group .em-group.em-direction-column {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-direction-row.em-two-columns .em-field-label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        width: 223px;
    }

    .em-field-wrap.em-spacing > div:first-child {
        margin-bottom: 10px;
    }

    /* For sections that are not styled with styleHint="horizontal" but only have "twoColumns" */

    .em-two-columns .em-field-wrap {
        margin-right: 0;
        width: calc(50% - 1.5rem);
        width: calc(50% - var(--gutter));
    }

    .em-two-columns .em-field-wrap:nth-child(2n) {
        margin-right: 0;
    }

    .em-h1 {
        margin: 1.5rem 0;
        margin: var(--spacing-32) 0;
        border-top: none;
        margin-top: 0;
        font-weight: bold;
        font-family: var(--font-family-base);
    }

    .em-h1 .select-field-option-wrapper {
        font-weight: 400;
        font-weight: var(--font-weight-normal);
    }

    .em-h1 > .em-field-label.em-question-spacing label span {
        font-size: 28px;
        margin: 1.5rem 0;
        margin: var(--spacing-main) 0;
        display: inline-block;
    }

    .em-h1 > .em-group .em-field-wrap .em-field-label label span,
    .em-h1 .em-error-container .em-validation-message {
        font-weight: 400;
    }

    .em-h1 > .em-h1.em-group {
        margin: 0;
        padding: 0;
    }

    .em-static-label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: -13px;
    }

    /* .em-question-spacing .em-h1 {
        margin: 1.5rem 0;
        margin: var(--spacing-main) 0;
        padding-top: 1.5rem;
        padding-top: var(--spacing-main);
        max-width: 100%;
    } */

    /* Enables horizontal styleHint to be used on select fields */

    .em-field-select.em-direction-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-field-select.em-direction-row .select-field-option-wrapper {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        padding-left: 0;
    }

    /* when multiple static fields represent one heading (to remove after table styles rewrite) */

    .em-direction-row.em-table-row-headings .em-field-static {
        min-width: 0 !important; /* to remove !important after table styles rewrite */
        width: auto !important; /* to remove !important after table styles rewrite */
        font-size: 17px;
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
        margin-bottom: 20px;
    }

    .em-bottom-border {
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--border-solid-gray);
        padding-bottom: 40px;
    }

    /* adds extra spacings between the fields in tables and groups */

    .em-group.em-extra-spacing .em-field-wrap {
        margin-bottom: 10px;
    }

    /* removes extra spacing inside questions with the "yes/no/explain" question type */

    .em-group.em-remove-extra-spacing-yes-no-explain {
        margin-top: 0;
        padding-left: 0;
    }

    .em-group.em-remove-extra-spacing-yes-no-explain .em-select-input-wrap .em-field-label {
        margin-top: -18px;
    }

    .em-group.em-direction-row.em-string-row {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }

    .em-group.em-direction-row.em-string-row .em-field-static.em-field-wrap.em-question-spacing {
        margin-right: 6px;
        position: relative;
        font-weight: 500;
        min-width: unset;
        width: auto;
    }

    .em-group.em-direction-row.em-string-row
        .em-field-static.em-field-wrap.em-question-spacing:last-of-type {
        margin-right: 0;
    }

    .em-optional-marker {
        font-style: italic;
        text-align: center;
    }

    .em-wizard-subtitle {
        text-align: center;
        width: 460px;
        margin: auto;
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        line-height: 24px;
    }

    /* Use this class when a section inside a table row has two select fields, and
they need to fill 1/3 and 2/3 of section width, respectively */

    .em-table-row-select-fields-one-third-two-thirds .em-field-wrap:last-child {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 470px;
        flex: 1 0 470px;
    }

    .em-table-row-select-fields-one-third-two-thirds .em-field-wrap:last-child .em-select-field {
        max-width: 100%;
    }

    .em-table-row-select-fields-one-third-two-thirds
        .em-field-wrap:last-child
        .em-select-field__control,
    .em-table-row-select-fields-one-third-two-thirds
        .em-field-wrap:last-child
        .em-select-field__menu {
        max-width: 100%;
    }

    /* Use this styleHint when you need fields width of: 346px, 210px, 110px respectively */

    .em-city-state-zip > .em-field-wrap:nth-child(1) input {
        width: 346px;
    }

    .em-city-state-zip > .em-field-wrap:nth-child(2) .em-select-field {
        width: 210px;
    }

    .em-city-state-zip > .em-field-wrap:nth-child(3) {
        margin-right: 0;
    }

    .em-city-state-zip > .em-field-wrap:nth-child(3) input {
        width: 112px;
    }

    .em-section-item-alignment-top {
        -ms-flex-item-align: start;
        align-self: flex-start;
    }

    .em-section-item-alignment-middle {
        -ms-flex-item-align: center;
        align-self: center;
    }

    .em-label-semibold .em-field-label label span {
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
    }

    .em-centered,
    .em-centered .em-field-wrap {
        margin-left: auto;
        margin-right: auto;
    }

    .em-centered.em-indented,
    .em-centered .em-indented,
    .em-centered .em-field-wrap.em-indented,
    .em-centered .em-field-wrap .em-indented {
        padding-left: 0;
    }

    .em-wizard-page {
        width: 600px;
        margin: auto;
        padding: 0;
    }

    .em-wizard-page .em-h1 {
        text-align: center;
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
        margin-top: 1.5rem;
        margin-top: var(--spacing-main);
    }

    .em-wizard-page ~ .form-button-wrapper {
        border-top: none;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        padding: 0;
    }

    .em-wizard-page ~ .form-button-wrapper div:not(:empty) {
        margin: 1.125rem;
        margin: var(--spacing-small);
    }

    .em-wizard-page ~ .form-button-wrapper button {
        width: 280px;
    }

    .em-wizard-page .em-radio-button,
    .em-wizard-page .em-text-input-wrap,
    .em-wizard-page .em-field-select {
        width: 280px;
    }

    .em-wizard-page .em-field-multiselect {
        width: 560px;
    }

    .em-field-multiselect .em-checklist.multiselect-option-list {
        margin-top: 0.75rem;
    }

    .em-wizard-page .multiselect-option-list {
        -webkit-column-count: 2;
        column-count: 2;
        grid-column-gap: 1.5rem;
        -webkit-column-gap: 1.5rem;
        column-gap: 1.5rem;
        grid-column-gap: var(--gutter);
        -webkit-column-gap: var(--gutter);
        column-gap: var(--gutter);

        /* sprinkle some grid magic for browsers that support it */
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
    }

    .em-wizard-page .em-no-title > .em-field-label.em-question-spacing,
    .em-wizard-page .em-question-spacing:empty {
        display: none;
    }

    .em-wizard-page .em-display-none {
        display: none;
    }

    .em-wizard-page .em-table-button-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .em-wizard-page .em-table-row-removable.em-table-row {
        border-top: none;
    }

    .em-wizard-page .em-field-label {
        margin-bottom: 0;
    }

    .em-wizard-page .em-field-label ul {
        padding-left: 18px;
    }

    .em-wizard-page .em-field-label ul li {
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
        list-style: disc;
        display: list-item;
    }

    .em-wizard-page .em-field-label ul li span:nth-child(1) {
        margin-right: 12px;
    }

    .em-wizard-page .em-field-label ul li:last-of-type {
        margin-bottom: 0;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-wizard-subtitle {
            width: 100%;
        }

        .em-wizard-page {
            width: 100%;
        }

        .em-wizard-page .em-h1 {
            margin-top: 0;
        }

        .em-wizard-page .em-two-columns .em-group,
        .em-wizard-page .em-direction-row .em-group {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .em-wizard-page ~ .form-button-wrapper div {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }

        .em-wizard-page .multiselect-option-list {
            -webkit-column-count: 1;
            column-count: 1;

            /* reset grid */
            display: block;
        }

        .em-wizard-page .em-validation-message {
            padding-top: 8px;
        }

        .em-wizard-page .em-field-wrap,
        .em-wizard-page .em-input,
        .em-wizard-page .em-radio-button,
        .em-wizard-page .em-select-field {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            max-width: 21.625rem;
            max-width: var(--form-field-max-width);
        }
    }

    @media (max-width: 360px) {
        .em-wizard-page .ml-quotes-modal {
            padding-top: 0;
            margin: 0;
        }
    }

    /* Scrollable Container */

    .em-scrollable-container {
        position: relative;
        border: 1px solid #e6e8eb;
        padding: 24px 24px 48px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        min-height: 100px;
        margin-bottom: 18px;
    }

    .em-scrollable-container .em-text-container {
        max-height: 126px;
        line-height: 1.41;
        overflow-y: scroll;
        margin-bottom: 36px;
    }

    .em-scrollable-container .em-container {
        position: absolute;
        bottom: 48px;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: block;
        width: 16px;
        margin-bottom: 24px;
        margin-left: -16px;
    }

    /* ML Thank You Page */

    .em-ml-thank-you-page {
        margin: 140px auto;
        max-width: 500px;
        display: block;
        text-align: center;
    }

    .em-ml-thank-you-page .em-p1:nth-child(1) {
        font-weight: 500;
        margin-bottom: 24px;
    }

    #ml-quotes-container {
        max-width: 1080px;
        margin: 60px auto 24px;
    }

    .ml-quotes {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    /* .border-bottom-dotted-blue {
        border-bottom: var(--border-dotted-blue);
    } */

    .ml-quotes-summary {
        width: 100%;
        max-width: 786px;
        background: #ffffff;
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 1.5rem 0;
        padding: var(--spacing-main) 0;
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: var(--border-drop-shadow);
        box-shadow: var(--border-drop-shadow);
    }

    .ml-quotes-summary h1,
    .ml-quotes-summary p,
    .ml-quotes-summary .em-details-column-names,
    .ml-quotes-summary .details-coverages {
        padding: 0 1.5rem;
        padding: 0 var(--spacing-main);
    }

    .ml-quotes-summary h1 {
        line-height: 100%;
    }

    .ml-quotes-details {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
    }

    .ml-quotes-premium {
        min-width: 250px;
        background: #ffffff;
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 1.5rem 0;
        padding: var(--spacing-main) 0;
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: var(--border-drop-shadow);
        box-shadow: var(--border-drop-shadow);
    }

    .ml-quotes-details .em-details-column-names {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-size: var(--font-size-nav1);
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
        padding-bottom: 5px;
    }

    .ml-quotes-details .em-details-column-names span {
        position: relative;
    }

    .ml-quotes-details .em-details-column-names-item {
        width: 100%;
    }

    .ml-quotes-details .em-details-column-names-item:nth-child(1) {
        max-width: 195px;
        width: 195px;
        min-width: 195px;
    }

    .ml-quotes-details .em-details-column-names-item:nth-child(2) {
        text-align: center;
    }

    .ml-quotes-details .em-details-column-names-item:nth-child(3) {
        max-width: 160px;
        min-width: 160px;
        width: 160px;
    }

    .ml-quotes-details .em-details-column-names-item:nth-child(4) {
        max-width: 160px;
        min-width: 160px;
        width: 160px;
    }

    .ml-quotes-details .details-coverages {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 0;
    }

    .ml-quotes-details .details-coverages .em-check-box {
        display: inline-block;
        max-width: 196px;
    }

    .ml-quotes-details .details-coverages .em-check-box label {
        z-index: 0;
        padding: 0.75rem 0;
        padding: var(--checkbox-label-padding);
        /*white-space: nowrap;*/
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        display: inline;
    }

    .ml-quotes-details .details-coverages .em-check-box input {
        opacity: 0;
        position: absolute;
        top: 0.125rem;
        top: var(--checkbox-input-top-position);
        left: 0.125rem;
        left: var(--checkbox-input-left-position);
    }

    .ml-quotes-details .details-coverages .ml-coverage {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
        padding: 1.5rem;
        padding: var(--spacing-main);
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }

    .ml-quotes-details .details-coverages .ml-coverage-item {
        width: 100%;
    }

    .ml-quotes-details .details-coverages .ml-coverage-item:nth-child(1) {
        max-width: 195px;
        width: 195px;
        min-width: 195px;
    }

    .ml-quotes-details .details-coverages .ml-coverage-item:nth-child(2) {
        text-align: center;
    }

    .ml-quotes-details .details-coverages .ml-coverage-item:nth-child(3) {
        max-width: 160px;
        min-width: 160px;
        width: 160px;
    }

    .ml-quotes-details .details-coverages .ml-coverage-item:nth-child(4) {
        max-width: 160px;
        min-width: 160px;
        width: 160px;
    }

    .ml-quotes-details .details-coverages .ml-coverage span {
        font-size: 22px;
        font-weight: 300;
        font-weight: var(--font-weight-light);
        text-align: left;
    }

    .ml-quotes-details .details-coverages .ml-coverage .download-policy {
        width: 20px;
        height: 20px;
        display: inline-block;
        background: no-repeat center
            url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDE2IDIwIj4KICAgIDxnIGZpbGw9IiM2NjczRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTQuNTAxIDB2Ny45NjhMMCA3Ljk2MyA3Ljk5IDE2IDE2IDcuOTgxbC00LjY3LS4wMDVWMHpNMCAxNy45MjNoMTZ2MkgweiIvPgogICAgPC9nPgo8L3N2Zz4K);
    }

    .ml-quotes-details .details-coverages .ml-coverage .em-select-field {
        height: 2.75rem;
        height: var(--select-height);
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .ml-quotes-details .details-coverages .ml-coverage .em-select-field {
            height: 3.375rem;
            height: var(--select-height-tall);
        }
    }

    .ml-quotes-details .details-coverages .ml-coverage .em-select-field .em-select-field__control,
    .ml-quotes-details .details-coverages .ml-coverage .em-select-field .em-select-field__menu {
        -webkit-box-shadow: none;
        box-shadow: none;
        margin: 0;
        border-radius: 0;
        width: 100%;
        min-width: 100px;
        max-width: 140px;
        min-height: 2.75rem;
        min-height: var(--select-height);
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .ml-quotes-details
            .details-coverages
            .ml-coverage
            .em-select-field
            .em-select-field__control,
        .ml-quotes-details .details-coverages .ml-coverage .em-select-field .em-select-field__menu {
            min-height: 3.375rem;
            min-height: var(--select-height-tall);
        }
    }

    .ml-quotes-details
        .details-coverages
        .ml-coverage
        .em-select-field
        .em-select-field__single-value {
        height: 25px;
        font-size: 20px;
        font-weight: 300;
        font-weight: var(--font-weight-light);
    }

    .ml-quotes-details .details-coverages .ml-coverage .em-select-field .em-select-field__menu,
    .ml-quotes-details
        .details-coverages
        .ml-coverage
        .em-select-field
        .em-select-field__menu:hover {
        margin-top: -2px;
    }

    .ml-quotes-details
        .details-coverages
        .ml-coverage
        .em-select-field
        .em-select-field__menu
        .em-select-field__option,
    .ml-quotes-details
        .details-coverages
        .ml-coverage
        .em-select-field
        .em-select-field__menu:hover
        .em-select-field__option {
        min-height: 2rem;
        line-height: 2rem;
        font-size: 20px;
        font-weight: 300;
        font-weight: var(--font-weight-light);
        border-bottom: 1px solid #e6e8eb;
        border-bottom: 1px solid var(--em-medium-gray);
    }

    .ml-quotes-details
        .details-coverages
        .ml-coverage
        .em-select-field
        .em-select-field__menu
        .css-11unzgr.em-select-field__menu-list {
        padding-top: 0;
    }

    .ml-quotes-details
        .details-coverages
        .ml-coverage
        .em-select-field
        .em-select-field__indicator-separator {
        display: none;
    }

    .ml-quotes-details
        .details-coverages
        .ml-coverage
        .em-select-field
        .em-select-field__dropdown-indicator {
        padding: 6px;
    }

    .ml-quotes-details .details-coverages .ml-coverage .ui.checkbox.disabled,
    .ml-quotes-details .details-coverages .ml-coverage .ui.dropdown.disabled {
        background: transparent;
    }

    .ml-quotes-details .details-coverages .ml-coverage span {
        padding: 0;
    }

    .ml-quotes-details .details-coverages .ml-coverage span:last-child {
        margin: 0;
    }

    .ml-quotes-details .coverage-start-date {
        padding: 1.5rem;
        padding: var(--spacing-main);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .ml-quotes-details .coverage-start-date * {
        /*margin-right: 20px;*/
    }

    .ml-quotes-details .coverage-start-date span:first-of-type {
        margin-right: 20px;
    }

    .ml-quotes-details .coverage-start-date span,
    .ml-quotes-details .coverage-start-date .rdt,
    .ml-quotes-details .coverage-start-date span.end-date {
        display: inline-block;
    }

    .ml-quotes-details .coverage-start-date .end-date {
        width: 154px;
        height: 34px;
        padding: 6px 12px 6px 24px;
        position: relative;
        display: inline-block;
        border: none;
        margin-left: 24px;
    }

    .ml-quotes-details .coverage-start-date .end-date::before {
        content: '-';
        position: absolute;
        left: 0;
    }

    .ml-quotes-details .coverage-start-date .rdt .form-control {
        width: 154px;
    }

    .ml-quotes-details .coverage-start-date .rdt::after {
        left: 125px;
    }

    .ml-heading {
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
        margin: 0;
    }

    .ml-quotes-modal {
        position: fixed; /* Stay in place */
        z-index: 100000; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
    }

    /* Black w/ opacity */

    .ml-quotes-modal strong {
        font-weight: 700;
    }

    .ml-quotes-modal .ml-quotes-modal-content {
        margin: auto auto 3rem;
        margin: auto auto var(--spacing-large);
        padding: 3rem;
        padding: var(--spacing-large);
        border: 1px solid 1px solid #e6e8eb;
        border: 1px solid var(--border-solid-gray);
        -webkit-box-shadow: 1px 1px 16px 0 #e6e8eb;
        box-shadow: 1px 1px 16px 0 #e6e8eb;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .ml-quotes-modal .ml-quotes-modal-close {
        float: right;
        font-size: 28px;
        font-weight: bold;
        margin-top: -32px;
        margin-right: -23px;
    }

    .ml-quotes-modal-close:hover,
    .ml-quotes-modal-close:focus {
        text-decoration: none;
        cursor: pointer;
    }

    .ml-quotes-premium-calculations .ui.input {
        padding-bottom: 9px;
    }

    .ml-quotes-premium p {
        padding: 0;
    }

    .ml-quotes-premium-calculations,
    .ml-quotes-premium-total,
    .ml-quotes-premium-subtotal-and-total {
        padding: 1.5rem;
        padding: var(--spacing-main);
    }

    .ml-quotes-premium-calculations p,
    .ml-quotes-premium-total p,
    .ml-quotes-premium-subtotal-and-total p {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        /* text-align: left; */
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        padding: 5px 0;
    }

    .ml-quotes-premium-calculations p span,
    .ml-quotes-premium-total p span,
    .ml-quotes-premium-subtotal-and-total p span {
        text-align: left;
    }

    .ml-quotes-premium-calculations p span:last-child,
    .ml-quotes-premium-total p span:last-child,
    .ml-quotes-premium-subtotal-and-total p span:last-child {
        text-align: right;
        -webkit-box-flex: 2;
        -ms-flex-positive: 2;
        flex-grow: 2;
    }

    .ml-quotes-premium-calculations h1 {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-size: var(--font-size-nav1);
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
        line-height: 24px;
        margin-bottom: 1.5rem;
        margin-bottom: var(--spacing-main);
    }

    .ml-quotes-premium-calculations .ui.input {
        padding-bottom: 9px;
    }

    .ml-quotes-premium-calculations .ui.input input {
        font-size: 20px;
        width: 100%;
        height: 34px;
        padding-left: 5px;
    }

    .ml-quotes-premium-calculations .em-partner-code {
        font-size: 15px;
        padding: 0;
        margin-top: 10px;
    }

    .ml-quotes-premium-calculations .em-partner-code span {
        text-align: left;
    }

    .ml-quotes-premium-calculations .em-partner-code span:last-child {
        text-align: left;
    }

    .ml-quotes-premium-calculations .em-base-premium {
        font-weight: 600;
        font-weight: var(--font-weight-bold);
        position: relative;
        padding-top: 40px;
        margin-top: 20px;
    }

    .ml-quotes-premium-calculations .em-base-premium::before {
        content: '';
        position: absolute;
        width: 100%;
        display: block;
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--border-solid-gray);
        height: 1px;
        top: 20px;
    }

    .ml-quotes-premium-total {
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
        padding-top: 1.5rem;
        padding-top: var(--spacing-main);
    }

    .ml-quotes-premium-total .total span:last-child {
        font-size: 1.75rem;
        font-size: var(--font-size-heading-h1);
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
    }

    .ml-quotes-right-side p {
        padding: 0;
        position: relative;
    }

    .ml-quotes-right-side-calculations,
    .ml-quotes-right-side-total {
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
        padding: 1.5rem;
        padding: var(--spacing-main);
    }

    .ml-quotes-right-side-calculations p,
    .ml-quotes-right-side-total p {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        /* text-align: left; */
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        padding: 5px 0;
    }

    .ml-quotes-right-side-calculations p span,
    .ml-quotes-right-side-total p span {
        text-align: left;
    }

    .ml-quotes-right-side-calculations p span:last-child,
    .ml-quotes-right-side-total p span:last-child {
        text-align: right;
        -webkit-box-flex: 2;
        -ms-flex-positive: 2;
        flex-grow: 2;
    }

    .ml-quotes-right-side-total p span:first-child {
        width: 90px;
    }

    .ml-quotes-right-side-total p span:last-child {
        font-size: 1.75rem;
        font-size: var(--font-size-heading-h1);
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
    }

    .ml-quotes-right-side .bold-font {
        font-weight: 600;
        font-weight: var(--font-weight-bold);
    }

    .continue-section {
        margin-top: 1.5rem;
        margin-top: var(--spacing-main);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 2.25rem;
        padding: var(--spacing-medium);
        background: #ffffff;
        background: var(--em-white);
        border: none;
    }

    .continue-section-boxes span {
        font-size: 15px;
    }

    .continue-section-cta {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-line-pack: end;
        align-content: flex-end;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    .continue-section .em-button {
        display: inline-block;
        max-width: 100px;
        margin-left: 1.5rem;
        margin-left: var(--spacing-main);
        height: 48px;
        height: var(--button-size-large);
        line-height: 48px;
        line-height: var(--button-size-large);
        font-size: 1.1875rem;
        font-size: var(--button-font-size-large);
    }

    .continue-section .em-button-default {
        max-width: 250px;
        max-width: var(--button-large-min-width);
    }

    /* TO DO */

    /* Remove the hard coded styles for opening tooltips */

    .ml-coverage-item #em-tooltip {
        min-width: 190px;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .ml-quotes-modal {
            width: 100%;
            padding-top: 0;
        }

        .ml-quotes-modal .ml-quotes-modal-content {
            padding: 48px 15px;
            margin-bottom: 0;
            width: 100%;
        }

        .ml-quotes-modal .ml-quotes-modal-close {
            margin-right: 0;
        }
    }

    /*modal content max-width setings*/

    .embroker-warranty-page {
        width: 100%;
        max-width: 730px;
    }

    .embroker-law-firm-page {
        width: 100%;
        max-width: 530px;
    }

    .em-quotes-modal-content {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        /*modal content max-width setings*/
        width: 100%;
        max-width: 1013px;
    }

    .em-quotes-modal-content .em-h1 {
        margin: 0 0 1.5rem 0;
        margin: 0 0 var(--spacing-main) 0;
        line-height: 1.2;
    }

    /* button row */

    .ml-quotes-modal-buttons {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }

    .ml-quotes-modal-buttons .em-button {
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .ml-quotes-modal-buttons .em-button:last-of-type {
        margin-right: 0;
    }

    .em-quotes-modal-content-section {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 1.5rem 0;
        padding: var(--spacing-main) 0;
    }

    .em-quotes-modal-content-section-left {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .em-quotes-modal-content-section-right {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        min-width: 200px;
        margin-left: 1.5rem;
        margin-left: var(--spacing-main);
    }

    .em-quotes-modal-content-section-right div {
        margin: 0 auto;
    }

    .em-quotes-modal-content-section-right .em-icon-close {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0.5;
    }

    .em-quotes-modal-content-section--gray {
        padding: 0;
    }

    .em-quotes-modal-content-section--gray .em-quotes-modal-content-section-left {
        padding: 1.5rem;
        padding: var(--spacing-main);
    }

    .em-quotes-modal-content-section--gray .em-quotes-modal-content-section-right {
        padding: 1.5rem 0;
        padding: var(--spacing-main) 0;
    }

    .em-quotes-modal-content-section .em-p1 {
        line-height: 1.5rem;
    }

    .em-quotes-modal-content-section ol {
        padding-left: 2.25rem;
        padding-left: var(--spacing-medium);
    }

    .em-quotes-modal-content-section ol li {
        margin: 1.125rem 0;
        margin: var(--spacing-small) 0;
        padding-left: 1.125rem;
        padding-left: var(--spacing-small);
        list-style-type: lower-alpha;
    }

    /* Purchase confirmation modal */

    .ml-quotes-modal-content-purchase-confirmation {
        min-width: 500px;
        padding-bottom: 100px;
    }

    .ml-quotes-modal-content-purchase-confirmation .em-h1 {
        line-height: unset;
        margin-bottom: 0.5rem;
        margin-bottom: var(--spacing-tiny);
        font-size: 28px;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .ml-quotes-modal-buttons {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .ml-quotes-modal-buttons .em-button {
            margin-top: 1.5rem;
            margin-top: var(--spacing-main);
            height: 48px;
            height: var(--button-size-large);
            border-radius: 48px;
            border-radius: var(--button-size-large);
            line-height: 48px;
            line-height: var(--button-size-large);
            padding: 0 1.5rem;
            padding: 0 var(--spacing-main);
            min-width: 250px;
            min-width: var(--button-large-min-width);
            font-size: 1.1875rem;
            font-size: var(--button-font-size-large);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            max-width: 21.625rem;
            max-width: var(--form-field-max-width);
            width: 100%;
        }
        .em-quotes-modal-content .em-h1 {
            margin-left: 24px;
        }
        .em-quotes-modal-content-section-right {
            display: none;
        }

        .ml-quotes-modal-content-purchase-confirmation {
            min-width: auto;
        }
    }

    .em-tabs-nav {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--border-solid-gray);
        margin: 1.125rem 1.5rem 1.125rem;
        margin: var(--spacing-small) var(--spacing-main) var(--spacing-small);
    }

    .em-tabs-nav-item {
        font-size: 17px;
        margin: 0 1.125rem;
        margin: 0 var(--spacing-small);
        padding-bottom: 1.125rem;
        padding-bottom: var(--spacing-small);
        position: relative;
        cursor: pointer;
    }

    .em-tabs-nav-item.active::after {
        content: '';
        width: 100%;
        height: 2px;
        background: #47c6ec;
        background: var(--em-light-blue);
        position: absolute;
        bottom: -1px;
        left: 0;
    }

    .em-tabs-nav-item:first-of-type {
        margin-left: 0;
    }

    .em-tabs-nav-item:last-of-type {
        margin-right: 0;
    }

    .em-tab {
        padding: 1.5rem;
        padding: var(--spacing-main);
    }

    .em-ml-cta-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 1.5rem;
        padding: 0 var(--spacing-main);
        height: 125px;
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
    }

    .em-ml-cta-container .em-button-text {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        font-size: 19px;
    }

    .em-ml-cta-container .em-button-text.save-for-later {
        position: absolute;
        right: 320px;
        bottom: 48px;
    }

    .em-ml-cta-container.no-coverages {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-ml-cta-container.no-coverages .please-add-at-least {
        width: 544px;
        height: 104px;
        font-family: Calibre;
        font-size: 48px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.08;
        letter-spacing: normal;
        text-align: center;
        margin: 10% auto 0 auto;
    }

    .em-ml-cta-container.no-coverages .back-button-div {
        width: 100%;
        margin-top: 10%;
    }

    .em-ml-cta-container.no-coverages .back-button-div button {
        margin: 0 auto;
    }

    .em-not-qualify-wrapper.em-ofac-not-qualify {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0 auto;
    }

    .em-not-qualify-wrapper.em-ofac-not-qualify .em-h1 {
        max-width: 600px;
        margin: 0;
        line-height: 64px;
        margin: 47px 0;
    }

    .em-not-qualify-wrapper.em-ofac-not-qualify .em-not-qualify {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .em-not-qualify-wrapper.em-ofac-not-qualify .em-company-intro {
        margin-bottom: 28px;
        padding: 0;
    }

    .em-not-qualify-wrapper.em-ofac-not-qualify .em-not-qualify-plh {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 300px;
    }

    .em-not-qualify-wrapper.em-ofac-not-qualify
        .em-not-qualify-plh
        .em-not-qualify-plh-cta-phone
        .em-button-text,
    .em-not-qualify-wrapper.em-ofac-not-qualify
        .em-not-qualify-plh
        .em-not-qualify-plh-cta-chat
        .em-button-text {
        display: inline;
        text-align: left;
        margin-left: 16px;
        font-weight: 400;
        font-weight: var(--font-weight-normal);
        font-size: 16px;
        font-size: var(--font-size-base);
    }

    .em-not-qualify-wrapper.em-ofac-not-qualify
        .em-not-qualify-plh
        .em-not-qualify-plh-cta-phone
        .em-button-text
        a,
    .em-not-qualify-wrapper.em-ofac-not-qualify
        .em-not-qualify-plh
        .em-not-qualify-plh-cta-chat
        .em-button-text
        a {
        font-weight: 400;
        font-weight: var(--font-weight-normal);
    }

    .em-not-qualify-wrapper.em-ofac-not-qualify .em-not-qualify-plh-cta-phone,
    .em-not-qualify-wrapper.em-ofac-not-qualify .em-not-qualify-plh-cta-chat {
        margin-top: 0;
    }

    .em-nextsteps-page {
        padding-top: 20px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-left: 65px;
    }

    .em-nextsteps-page .em-nextsteps-covered-information {
        -webkit-box-align: left;
        -ms-flex-align: left;
        align-items: left;
    }

    .em-nextsteps-page .em-nextsteps-covered-other-actions {
        -webkit-box-align: left;
        -ms-flex-align: left;
        align-items: left;
        margin-top: 124px;
    }

    .em-nextsteps-page .em-nextsteps-covered-other-actions-button-link {
        padding: 0;
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        text-align: left;
        width: 100%;
    }

    .em-nextsteps-page .em-nextsteps-covered-other-actions .em-button {
        margin-bottom: 0;
    }

    .em-nextsteps-page .em-nextsteps-covered-other-actions-description {
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        line-height: 0;
        padding: 0;
        margin-bottom: 15px;
    }

    .em-nextsteps-page .em-h1 {
        font-size: 3rem;
        font-size: var(--font-size-heading-h1-big);
        font-weight: 300;
        font-weight: var(--font-weight-light);
        padding-bottom: 1.125rem;
        padding-bottom: var(--spacing-small);
        position: relative;
        margin: 0 0 3rem;
        margin: 0 0 var(--spacing-large);
        line-height: 49px;
    }

    .em-nextsteps-page .em-icon-dog {
        position: relative;
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
        top: -8px;
    }

    .em-nextsteps-page p {
        display: inline-block;
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        font-weight: var(--font-weight-regular);
        line-height: 32px;
        max-width: 520px;
        text-align: center;
    }

    .em-nextsteps-page .em-purchased-coverages-text {
        padding: 0;
    }

    .em-nextsteps-page .em-purchased-coverages-list {
        margin-bottom: 2.25rem;
        margin-bottom: var(--spacing-medium);
    }

    .em-nextsteps-page .em-purchased-coverages-list-item {
        font-weight: 600;
        font-weight: var(--font-weight-bold);
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
        line-height: 32px;
    }

    .em-nextsteps-page .em-purchased-coverages-description {
        text-align: left;
        line-height: 20px;
        margin-bottom: 3rem;
        margin-bottom: var(--spacing-large);
        max-width: 450px;
        padding: 0;
    }

    .em-nextsteps-page .em-button {
        margin-bottom: 0.75rem;
        margin-bottom: var(--spacing-xsmall);
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-nextsteps-page .em-h1 {
            font-size: 1.75rem;
            font-size: var(--font-size-heading-h1);
            text-align: left;
        }
        .em-nextsteps-page.kicked-out {
            display: inline-block;
        }
        .em-nextsteps-page.kicked-out .em-not-qualify-plh-cta.kicked-out {
            padding: 0px;
            margin-top: 0px;
        }
    }

    .em-faq {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        max-width: 900px;
        margin: 1.5rem auto;
        margin: var(--spacing-main) auto;
    }

    .em-faq .accordion {
        border: none;
    }

    .em-faq .accordion-item .title:after {
        content: '' !important;
        -webkit-transition: all 0.25s;
        transition: all 0.25s;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 8px;
        height: 8px;
        border-right: 2px solid #47c6ec;
        border-right: 2px solid var(--em-light-blue);
        border-bottom: 2px solid #47c6ec;
        border-bottom: 2px solid var(--em-light-blue);
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 16px;
    }

    .em-faq .accordion-item.active .title:after {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .em-faq .accordion-item .panel {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        margin-bottom: 4px;
    }

    .em-faq-item {
        margin-bottom: 8px;
    }

    .em-compare-table {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        max-width: 900px;
    }

    .em-compare-table-inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-compare-table-inner-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1.125rem 0;
        padding: var(--spacing-small) 0;
    }

    .em-compare-table-inner-head-item {
        width: 100%;
        text-align: center;
    }

    .em-compare-table-inner-head-item:first-of-type {
        text-align: left;
        max-width: 200px;
        width: 200px;
        min-width: 200px;
    }

    .em-compare-table-inner-head-item-bold {
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
    }

    .em-compare-table-inner-head--top {
        font-weight: 400;
        font-weight: var(--font-weight-normal);
    }

    .em-compare-table-inner-rows {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1.125rem 0;
        padding: var(--spacing-small) 0;
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
    }

    .em-compare-table-inner-rows .em-icon-check-blue-round {
        /* margin: auto; */
        display: inline-block;
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
    }

    .em-compare-table-inner-rows-item {
        width: 100%;
        text-align: center;
    }

    .em-compare-table-inner-rows-item:first-of-type {
        text-align: left;
        max-width: 200px;
        width: 200px;
        min-width: 200px;
    }

    .em-modals-cta {
        padding: 0.5rem 0 0 1.5rem;
        padding: var(--spacing-tiny) 0 0 var(--spacing-main);
    }

    .em-modals-cta .em-button {
        margin-right: 1.125rem;
        margin-right: var(--spacing-small);
    }

    .em-size-guide-modal {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-size-guide-modal-heading {
    }

    .em-size-guide-modal-text {
    }

    .em-size-guide-modal-table {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-size-guide-modal-table-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1.125rem 0;
        padding: var(--spacing-small) 0;
    }

    .em-size-guide-modal-table-head-item {
        width: 100%;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        font-weight: var(--font-weight-semibold);
    }

    .em-size-guide-modal-table-rows {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1.125rem 0;
        padding: var(--spacing-small) 0;
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
    }

    .em-size-guide-modal-table-rows-item {
        width: 100%;
    }

    #quotes-ml-container {
        padding-top: 72px;
    }

    #quotes-ml-container .ml-quotes-details .em-details-column-names {
        padding-bottom: 8px;
    }

    #quotes-ml-container .continue-section {
        width: 100%;
        max-width: 1080px;
        margin: -14px auto 0;
    }

    /*TODO: accordion rules - cleanup after components are refactored*/

    .em-ml-container .admin-rc-accordion .row-layout,
    .esp-streamlined--quotes-section .admin-rc-accordion .row-layout {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 36px;
        line-height: 36px;
    }

    .em-ml-container .admin-rc-accordion .row-layout .sprite-main,
    .esp-streamlined--quotes-section .admin-rc-accordion .row-layout .sprite-main {
        margin-right: 22px;
        margin-top: 4px;
    }

    .em-ml-container .admin-rc-accordion .row-layout .accordion-header-label,
    .esp-streamlined--quotes-section .admin-rc-accordion .row-layout .accordion-header-label {
        font-size: 1.1764rem;
    }

    /*TODO: cleanup after streamlined flow is refactored*/

    #quotes-ml-container .rc-accordion,
    .em-ml-streamlined-container .rc-accordion {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
    }

    #quotes-ml-container .continue-section,
    #quotes-ml-container .rc-accordion:last-of-type,
    .em-ml-streamlined-container .continue-section,
    .em-ml-streamlined-container .rc-accordion:last-of-type {
        margin-bottom: 36px;
    }

    #quotes-ml-container .row-layout .sprite.sprite-down,
    .em-ml-streamlined-container .row-layout .sprite.sprite-down {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 8px;
        height: 8px;
        border-right: 2px solid #47c6ec;
        border-right: 2px solid var(--em-light-blue);
        border-bottom: 2px solid #47c6ec;
        border-bottom: 2px solid var(--em-light-blue);
    }

    #quotes-ml-container .row-layout .sprite.sprite-right,
    .em-ml-streamlined-container .row-layout .sprite.sprite-right {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 8px;
        height: 8px;
        border-right: 2px solid #47c6ec;
        border-right: 2px solid var(--em-light-blue);
        border-bottom: 2px solid #47c6ec;
        border-bottom: 2px solid var(--em-light-blue);
    }

    #quotes-ml-container .row-layout .accordion-header-label,
    .em-ml-streamlined-container .row-layout .accordion-header-label {
        font-size: 1.1764rem;
    }

    .esp-streamlined--quotes-section .row-layout .sprite-down {
        width: 12px;
        height: 12px;
    }

    .esp-streamlined--quotes-section .row-layout .sprite-down:not(:hover) {
        background-position: -89px -5px;
    }

    .esp-streamlined--quotes-section .row-layout .sprite-down:hover {
        background-position: -5px -27px;
    }

    .esp-streamlined--quotes-section .row-layout .sprite-right {
        width: 12px;
        height: 12px;
    }

    .esp-streamlined--quotes-section .row-layout .sprite-right:not(:hover) {
        background-position: -115px -35px;
    }

    .esp-streamlined--quotes-section .row-layout .sprite-right:hover {
        background-position: -137px -35px;
    }

    .esp-streamlined--quotes-section .row-layout .accordion-header-label {
        font-size: 1.1764rem;
    }

    .esp-streamlined--quotes-section .row-layout .sprite {
        /* background: url(../assets/images/spritesheet.png) no-repeat; */
        display: inline-block;
    }

    .em-ml-notification-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 740px;
        min-height: 540px;
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: 0 1px 3px 0 var(--em-medium-gray);
        box-shadow: 0 1px 3px 0 var(--em-medium-gray);
        border: solid 1px #e6e8eb;
        border: solid 1px var(--em-medium-gray);
        text-align: center;
    }

    .em-ml-notification-container .em-h1.em-h1-blue.em-h1-heading {
        max-width: 75%;
        margin: 180px 0 110px;
    }

    /* new ML components */

    .em-ml-container {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        max-height: 100%;
        max-width: 100%;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .em-ml-streamlined-container {
        position: relative;
        width: 100%;
        max-width: 1080px;
        margin: 48px auto 48px;
    }

    .em-ml-quotes-section {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        height: 100%;
        max-width: 756px;
        width: 100%;
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: var(--border-drop-shadow);
        box-shadow: var(--border-drop-shadow);
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        margin-right: 20px;
    }

    .em-ml-progress {
        max-width: 300px;
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: var(--border-drop-shadow);
        box-shadow: var(--border-drop-shadow);
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        width: 100%;
    }

    .em-ml-progress-section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
        border: 0;
    }

    .em-ml-progress-section .opened-panel {
        height: 0;
        max-height: 0;
        overflow: hidden;
    }

    .em-ml-progress-section.active .em-ml-progress-section-head-badge {
        background: #47c6ec;
        background: var(--em-light-blue);
    }

    .em-ml-progress-section.active .opened-panel {
        height: 100%;
        max-height: 600px;
        overflow: visible;
        background: #ffffff;
        background: var(--em-white);
    }

    .em-ml-progress-section:last-of-type {
        border-bottom: none;
    }

    .em-ml-progress-section-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        height: 60px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 1.125rem;
        padding: 0 var(--spacing-small);
    }

    .em-ml-progress-section-head-envelop {
        margin: 0 3px 0 2px;
        width: 21px;
        height: 16px;
        display: inline-block;
        background: no-repeat center
            url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDIxIDE2Ij4KICAgIDxnIGZpbGw9IiNEOEQ4RDgiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTS44NjUgMGw5LjcxOSA5Ljc4OEwyMC4zMDQgMHoiLz4KICAgICAgICA8cGF0aCBkPSJNLjYxMSAxNS45MzFoMTkuNzUyYS42MTMuNjEzIDAgMCAwIC42MTItLjYxNVYxLjA2NWwtMTAuMzkgMTAuNDYzTDAgLjg3djE0LjQ0NWMwIC4zNC4yNzQuNjE1LjYxMS42MTUiLz4KICAgIDwvZz4KPC9zdmc+Cg==);
    }

    .em-ml-progress-section-head-badge {
        content: ' ';
        display: block;
        background: #d8d8d8;
        /*TODO set active background color for badge*/
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 100%;
    }

    .em-ml-progress-section-head-label {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 1.125rem;
        margin-left: var(--spacing-small);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .em-ml-progress-section-head-label-total {
        margin-left: auto;
        margin-right: 0;
        font-size: 17px;
    }

    .em-ml-progress-section-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0 18px 0 64px;
    }

    .em-ml-progress-section-content-tooltip {
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    .em-ml-progress-section-content-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .em-ml-progress-section-content-row #em-tooltip {
        bottom: 30px;
        left: -10px;
        min-width: 260px;
    }

    .em-ml-progress-section-content-row #em-tooltip:after {
        left: 24px;
    }

    .em-ml-progress-section-total {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        line-height: 28px;
        margin: 0 1.125rem;
        margin: 0 var(--spacing-small);
        padding: 1.125rem 0;
        padding: var(--spacing-small) 0;
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
        font-weight: 500;
    }

    .em-ml-progress-section-total--sub {
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--border-solid-gray);
        margin: 0 1.125rem 1.125rem;
        margin: 0 var(--spacing-small) var(--spacing-small);
    }

    .em-ml-progress-section-content-tooltip {
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    .em-ml-coverage-info {
        display: block;
    }

    .em-ml-coverage-info ul {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .em-ml-credits {
        padding: 1.5rem;
        padding: var(--spacing-main);
        min-height: 483px;
    }

    .em-ml-credits .em-p1.margin-bottom {
        margin-bottom: 24px;
    }

    .em-ml-credits-code {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .em-ml-credits-code .em-button {
        margin-left: 1.125rem;
        margin-left: var(--spacing-small);
    }

    .em-ml-credits-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 2.25rem;
        margin-top: var(--spacing-medium);
    }

    .em-ml-credits-info-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        line-height: 2rem;
    }

    #quotes-ml-container .em-ml-streamlined-container .coverage-start-date {
        margin-bottom: 36px;
    }

    #quotes-ml-container .em-ml-streamlined-container .continue-section {
        margin-bottom: 0;
    }

    .coverage-start-date {
        padding: 1.125rem 0 0;
        padding: var(--spacing-small) 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .coverage-start-date span,
    .coverage-start-date .rdt,
    .coverage-start-date span.end-date {
        display: inline-block;
    }

    .coverage-start-date .end-date {
        width: 200px;
        height: 34px;
        padding: 6px 12px 6px 24px;
        position: relative;
        display: inline-block;
        border: none;
    }

    .coverage-start-date .start-end-date-label {
        font-size: 1.25rem;
        font-size: var(--text-field-font-size);
    }

    .coverage-start-date .rdt .form-control {
        width: 200px;
        padding-left: 10px;
    }

    .em-ml-total {
        padding: 1.5rem;
        padding: var(--spacing-main);
    }

    .em-ml-total-table {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-bottom: 3rem;
        margin-bottom: var(--spacing-large);
    }

    .em-ml-total-table-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        line-height: 2rem;
    }

    .em-ml-total-table-head-item {
        width: 100%;
    }

    .em-ml-total-table-head-item:first-of-type {
        min-width: 220px;
    }

    .em-ml-total-table-head-item:last-of-type {
        text-align: right;
    }

    .em-ml-total-table-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        line-height: 2rem;
    }

    .em-ml-total-table-row-item {
        width: 100%;
    }

    .em-ml-total-table-row-item:first-of-type {
        min-width: 220px;
    }

    .em-ml-total-table-row-item:last-of-type {
        text-align: right;
    }

    .em-ml-total .em-check-box label {
        padding-left: 2.25rem;
        padding-left: var(--spacing-medium);
    }

    .em-ml-total .em-check-box label::before {
        position: absolute;
        top: 14px;
    }

    .rdt .form-control[readonly] {
        cursor: default;
    }

    .rdt .form-control[disabled] {
        cursor: not-allowed;
    }

    .em-ml-coverages {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;

        height: 100%;
    }

    .em-ml-coverages .em-h1 {
        margin-bottom: 16px;
    }

    .em-ml-coverages-info-button {
        display: block;
    }

    .em-ml-coverages-info-button .em-button {
        margin-right: 10px;
    }

    .em-ml-coverages-info-button .em-icon.em-icon-download-doc {
        vertical-align: middle;
    }

    .em-ml-coverages-options-container {
        width: 100%;
    }

    .em-ml-coverages-options-container-link {
        margin-top: 0.75rem;
        margin-top: var(--spacing-xsmall);
    }

    .em-ml-coverages-options-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-top: 12px;
    }

    .em-ml-coverages-options-row .ml-coverage-item {
        width: 100%;
        margin-right: 24px;
    }

    .em-ml-coverages-options-row .ml-coverage-item .em-p {
        font-size: 22px;
        height: 36px;
        line-height: 36px;
        font-weight: 300;
    }

    .em-ml-coverages-options-row .ml-coverage-item:last-of-type {
        margin-right: 0;
    }

    .em-ml-coverages-options-row .ml-coverage-item .em-select-field {
        min-width: 100px;
    }

    .em-ml-coverages-options-row .ml-coverage-item .em-button-default {
        padding: 0 1rem;
        min-width: 100px;
    }

    /* TODO: clean up after tooltip (forms-engine) tech-debt is done */

    .em-ml-coverages-options .ml-coverage-item #em-tooltip {
        min-width: 350px;
        bottom: 30px;
    }

    .em-ml-coverages-options #em-tooltip:after,
    .em-ml-coverages-options .em-tooltip:after {
        left: 30px;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-ml-coverages-info {
            text-align: center;
        }
        .em-ml-coverages-options-row {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .em-ml-coverages-options-row .ml-coverage-item {
            margin-top: 1.125rem;
            margin-top: var(--spacing-small);
            margin-right: 0;
            max-width: 21.625rem;
            max-width: var(--form-field-max-width);
        }

        .em-ml-coverages-options-container-link {
            text-align: center;
        }
    }

    .em-rc-radio-button {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .em-rc-radio-button .em-inner-button-container {
        width: 20px;
        height: 20px;
        padding: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .em-rc-radio-button .circle-button {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
    }

    .em-rc-radio-button .circle-button:focus {
        outline: none;
    }

    .em-rc-radio-button .circle-button:hover {
        cursor: default;
    }

    .em-rc-radio-button .circle-button-selected {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
    }

    .em-rc-radio-button .circle-button-selected:focus {
        outline: none;
    }

    .em-rc-radio-button .circle-button-selected:hover {
        cursor: default;
    }

    .em-rc-radio-button .checkmark {
        width: 80%;
        height: 80%;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0;
    }

    .em-radio-box-row {
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 30px;
    }

    .em-radio-box {
        height: 160px;
        width: 100%;
        padding: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #c6c6c6;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-bottom: 1.5rem;
        padding-bottom: var(--spacing-main);
    }

    .em-radio-box:hover {
        cursor: pointer;
    }

    .em-radio-box-selected {
        border: 1px solid #47c6ec;
        border-top: 4px solid #47c6ec;
    }

    .em-radio-box-selected:hover {
        cursor: default;
    }

    .em-radio-box-table {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-radio-box-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .em-radio-box-standard {
        width: 66%;
    }

    .em-radio-box-enhanced {
        width: 30%;
    }

    .em-radio-box-decline {
        width: 30%;
    }

    .em-radio-box-premium-amount {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 12px;
        text-align: center;
    }

    .em-radio-box-decline-coverage {
        font-size: 18px;
        margin-bottom: 35px;
        text-align: center;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-radio-box-row {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .em-radio-box {
            padding-bottom: 0;
        }

        .em-radio-box-table {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
            width: 100%;
            height: 100%;
            padding: 0 1.125rem;
            padding: 0 var(--spacing-small);
        }

        .em-radio-box-item {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
        }

        .em-radio-box-item:first-child {
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding-left: 1.5rem;
            padding-left: var(--spacing-main);
        }

        .em-radio-box-item:first-child * {
            margin: 0;
        }

        .em-radio-box-enhanced,
        .em-radio-box-decline,
        .em-radio-box-standard {
            width: 100%;
            height: 74px;
            margin: 10px 0 10px 0;
            max-width: 21.625rem;
            max-width: var(--form-field-max-width);
        }

        .em-radio-box-enhanced .em-radio-box,
        .em-radio-box-decline .em-radio-box,
        .em-radio-box-standard .em-radio-box {
            height: 100%;
        }

        .em-radio-box-premium-amount {
            margin-bottom: 0;
        }

        .em-radio-box-decline-coverage {
            margin: 0;
            text-align: left;
            width: 100%;
        }
    }

    .loader,
    .loader:after {
        border-radius: 50%;
        width: 48px;
        height: 48px;
    }

    .loader {
        z-index: 2000000;
        top: calc(50% - 24px);
        left: calc(50% - 24px);
        font-size: 10px;
        position: fixed;
        margin: 0;
        text-indent: -9999em;
        border-left: 4px solid #4299e1;
        border-top: 4px solid #d8f0ff;
        border-right: 4px solid #d8f0ff;
        border-bottom: 4px solid #d8f0ff;
        -webkit-animation: loader-spin 1.1s infinite linear;
        animation: loader-spin 1.1s infinite linear;
    }

    .loader-title {
        top: calc(50% - 90px);
        left: 0px;
        width: 100%;
        text-align: center;
        position: fixed;
    }

    .loader-subtitle {
        top: calc(50% + 60px);
        left: 0px;
        width: 100%;
        text-align: center;
        position: fixed;
    }

    @-webkit-keyframes loader-spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes loader-spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    /* end of new ML components */

    /* admin endorsements */

    .ml-endorse-change-container {
        display: block;
        width: 100%;
        border: 1px solid #e6e8eb;
        border: 1px solid var(--em-medium-gray);
        padding: 2.25rem;
        padding: var(--spacing-medium);
    }

    .ml-endorse-change-container .em-h1 {
        margin: 0;
    }

    .ml-endorse-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        min-width: 1008px;
        padding: 0.75rem 0;
        padding: var(--spacing-xsmall) 0;
        border-bottom: 1px solid #e6e8eb;
        border-bottom: 1px solid var(--em-medium-gray);
    }

    .ml-endorse-head-item {
        width: 100%;
        text-align: left;
        font-size: 0.875rem;
        font-size: var(--font-size-heading-h3);
        text-transform: uppercase;
        font-weight: 600;
        font-weight: var(--font-weight-bold);
    }

    .ml-endorse-head-item:first-of-type {
        min-width: 250px;
    }

    .ml-endorse-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        min-width: 1008px;
        padding: 0.75rem 0;
        padding: var(--spacing-xsmall) 0;
    }

    .ml-endorse-row-item {
        width: 100%;
        text-align: left;
    }

    .ml-endorse-row-item:first-of-type {
        min-width: 250px;
    }

    .ml-endorse-row-item:last-of-type {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .ml-endorse-row-item .em-button {
        margin-bottom: 18px;
        text-decoration: none;
    }

    .ml-endorse-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 18px;
        width: 100%;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }

    .ml-endorsement-modal-container {
        width: 100%;
        max-width: 600px;
        padding: 3rem 0;
        padding: var(--spacing-large) 0;
    }

    .ml-endorsement-modal-container .em-h1 {
        margin-top: 120px;
    }

    .ml-endorsement-modal {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        border: 1px solid #e6e8eb;
        border: 1px solid var(--em-medium-gray);
        -webkit-box-shadow: 1px 1px 16px 0 #e6e8eb;
        box-shadow: 1px 1px 16px 0 #e6e8eb;
        -webkit-box-shadow: var(--small-modal-box-shadow);
        box-shadow: var(--small-modal-box-shadow);
    }

    .ml-endorsement-modal-section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 1.125rem 3rem;
        padding: var(--spacing-small) var(--spacing-large);
        border-bottom: 1px solid #e6e8eb;
        border-bottom: 1px solid var(--em-medium-gray);
    }

    .ml-endorsement-modal-section .outline {
        padding: 1.125rem;
        padding: var(--spacing-small);
        border: 1px solid #e6e8eb;
        border: 1px solid var(--em-medium-gray);
    }

    .ml-endorsement-modal-section .outline .section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .ml-endorsement-modal-section .outline .section .em-check-box {
        min-width: unset;
    }

    .ml-endorsement-modal-section .outline .section .em-check-box label {
        padding-right: 0;
    }

    .ml-endorsement-modal-section .outline .section .em-button.em-button-text {
        font-size: 17px;
        font-weight: 500;
        height: unset;
        line-height: unset;
        display: inline;
    }

    .ml-endorsement-modal-section:first-of-type {
        padding-top: 3rem;
        padding-top: var(--spacing-large);
    }

    .ml-endorsement-modal-section .em-date-time-picker {
        max-width: 220px;
    }

    .ml-endorsement-modal-section-date {
        margin: 1.125rem 0;
        margin: var(--spacing-small) 0;
        position: relative;
    }

    .ml-endorsement-modal-section-date-validation {
        position: absolute;
    }

    .ml-endorsement-modal-section-date-validation span {
        font-size: 0.875rem;
    }

    .ml-endorsement-modal-section .em-select.em-select-wrap {
        max-width: 150px;
    }

    .ml-endorsement-modal-section .em-button {
        margin: 0 auto;
    }

    .ml-endorsement-modal-section .em-button-size-large {
        margin: 1.125rem auto;
        margin: var(--spacing-small) auto;
    }

    .ml-endorsement-modal-section-title {
        width: 100%;
        text-align: left;
        font-size: 0.875rem;
        font-size: var(--font-size-heading-h3);
        text-transform: uppercase;
        font-weight: 600;
        font-weight: var(--font-weight-bold);
    }

    .ml-endorsement-modal-section-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .ml-endorsement-modal-section-row .em-select-field {
        max-width: 150px;
    }

    .ml-endorsement-modal-section-row-item {
        width: 100%;
    }

    .ml-endorsement-modal-section-row-item:last-of-type {
        text-align: right;
    }

    .emB-full-modal.ml-endorse-modal .modal-content {
        top: 0;
    }

    .emB-full-modal.ml-endorse-modal .modal-content .content-wrapper {
        max-width: unset;
    }

    .em-modal .em-validation-message {
        font-size: 14px;
    }

    /*TODO clean after refactored - used old ModalService with appended noPadding class */

    .modal.noPadding .modal-content {
        max-width: 480px;
        margin: 100px auto 0;
        border-radius: 0;
        -webkit-box-shadow: 1px 1px 16px 0 #e6e8eb;
        box-shadow: 1px 1px 16px 0 #e6e8eb;
        -webkit-box-shadow: var(--small-modal-box-shadow);
        box-shadow: var(--small-modal-box-shadow);
        overflow-y: unset;
    }

    .modal.noPadding .modal-content .text-input--container {
        max-width: unset;
        width: 100%;
    }

    .modal.noPadding .modal-content .emB-emptySpace {
        display: none;
    }

    .modal.noPadding .modal-content .sprite-close {
        position: absolute;
        right: 12px;
        top: 12px;
    }

    .modal.noPadding .modal-content .em-h1 {
        line-height: 1.5rem;
    }

    .modal-backdrop.modal-backdrop-default.fade.in {
        opacity: 0.8;
    }

    /* streamlined ESP */

    /* old streamlined */

    .em-not-qualify-wrapper {
        display: block;
        max-width: 900px;
        width: 100%;
        margin: 180px auto;
    }

    .em-not-qualify {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .em-not-qualify .em-h1-red {
        font-size: 4.235rem;
        font-weight: 600;
        margin-bottom: 72px;
        text-align: center;
    }

    .em-not-qualify-plh {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .em-not-qualify-plh-info {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;

        -ms-flex-positive: 1;

        flex-grow: 1;
        width: 100%;
    }

    .em-not-qualify-plh-info .em-h2 {
        margin-bottom: 36px;
    }

    .em-not-qualify-plh-info .em-p1 {
        font-size: 1.1764rem;
    }

    .em-not-qualify-plh-cta {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;

        -ms-flex-positive: 1;

        flex-grow: 1;
        width: 100%;
        margin-left: 120px;
    }

    .em-not-qualify-plh-cta .em-button.em-button-text {
        font-size: 1.1764rem;
        text-align: left;
        margin-left: 16px;
    }

    .em-not-qualify-plh-cta-phone {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;

        margin-top: 24px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .em-not-qualify-plh-cta-chat {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;

        margin-top: 24px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-not-qualify {
            max-width: 500px;
        }
        .em-not-qualify .em-h1-red {
            text-align: left;
            font-size: 1.75rem;
            font-size: var(--font-size-heading-h1);
            padding: 2.25rem;
            padding: var(--spacing-medium);
            margin-bottom: 0px;
        }

        .em-not-qualify-plh {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .em-not-qualify-plh-info {
            padding: 0px 2.25rem;
            padding: 0px var(--spacing-medium);
        }

        .em-not-qualify-plh-info .em-h2,
        .em-not-qualify-plh-info .em-p1 {
            font-weight: 100;
        }

        .em-not-qualify-plh-cta {
            margin: 0px;
            padding: 0px 2.25rem;
            padding: 0px var(--spacing-medium);

            margin-top: 1.5rem;

            margin-top: var(--spacing-main);
        }

        .em-not-qualify-plh-cta-chat {
            margin-top: 15px;
        }

        .em-not-qualify-plh-cta-chat .em-button:nth-child(2) {
            margin-top: 0px;
        }
        .em-not-qualify-plh-cta-phone .em-button.em-button-text {
            margin-left: 11px;
        }

        .em-not-qualify-plh-cta-phone .em-button:nth-child(2) {
            margin-top: 0px;
        }

        .em-not-qualify-wrapper {
            margin: 130px auto;
        }
    }

    /* new streamlined */

    #package-includes {
        text-align: center;
        font-size: 17px;
        line-height: 24px;
        margin: 1.125rem 0;
        margin: var(--spacing-small) 0;
    }

    #package-includes strong {
        font-weight: bold;
    }

    /**
 * ESP streamlined predefined classes
 * (of elements and sections in the flow)
 */

    .esp-streamlined {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        max-width: 1242px;
        margin: auto;
    }

    .esp-streamlined--quotes-section {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;
        width: 100%;
        border: 1px solid #e6e8eb;
        border: var(--esp-streamlined-border-style);
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: var(--esp-streamlined-drop-shadow);
        box-shadow: var(--esp-streamlined-drop-shadow);
        margin-right: 20px;
    }

    .esp-streamlined--component {
        padding: 2.25rem;
        padding: var(--spacing-medium);
    }

    .esp-streamlined--navigation.em-ml-cta-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        padding: 2.25rem;
        padding: var(--spacing-medium);
    }

    .esp-streamlined--navigation--purchase-button-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }

    .esp-streamlined--navigation .em-button-text {
        margin-right: 1.125rem;
        margin-right: var(--spacing-small);
    }

    .esp-streamlined--page-heading-section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }

    .esp-streamlined--page-heading-section .em-button-text {
        margin-right: 0.75rem;
        margin-right: var(--spacing-xsmall);
        font-size: 1.0625rem;
        font-size: var(--esp-streamlined-paragraph-font-size);
    }

    .esp-streamlined--page-heading-section .em-icon.em-icon-download-doc {
        vertical-align: middle;
    }

    .esp-streamlined--page-heading-is-underlined {
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--esp-streamlined-border-style);
        padding-bottom: 1.5rem;
        padding-bottom: var(--spacing-main);
    }

    /* pull up header spacing */

    .esp-streamlined--page-heading-is-underlined .em-h1 {
        margin-bottom: 0;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .esp-streamlined {
            padding: 0;
            margin: 0;
        }

        .esp-streamlined--quotes-section {
            margin-right: 0;
            border: none;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .esp-streamlined--quotes-section .em-tabs-nav,
        .esp-streamlined--quotes-section .em-ml-coverages-info-button {
            display: none;
        }

        .esp-streamlined--quotes-section .em-tab {
            padding: 0;
        }

        .esp-streamlined--component {
            padding: 0;
        }
        .esp-streamlined--navigation.em-ml-cta-container {
            padding: 0;
            height: auto;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-top: none 0;
        }

        .esp-streamlined--navigation--purchase-button-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 100%;
        }

        .esp-streamlined--navigation button {
            margin: 1.125rem;
            margin: var(--spacing-small);
        }
        .esp-streamlined--page-heading-section {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .esp-streamlined--page-heading-is-underlined {
            border-bottom: 0;
        }
    }

    .esp-streamlined--recommended--content-wrap {
        padding-top: 30px;
    }

    .esp-streamlined--coverage-summary-wrap {
        padding-bottom: 60px;
    }

    .esp-streamlined--coverage-summary--heading {
        font-size: 20px;
        font-weight: 400;
        font-weight: var(--font-weight-normal);
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
        line-height: 1.41;
        line-height: var(--line-height-base);
    }

    .esp-streamlined--coverage-summary--heading-wrap {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        padding-bottom: 10px;
    }

    .esp-streamlined--coverage-summary--heading-wrap button {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        font-size: 16px;
        font-size: var(--font-size-base);
        padding: 0;
    }

    .esp-streamlined--coverage-summary--details p {
        max-width: 560px;
        font-size: 1.0625rem;
        font-size: var(--esp-streamlined-paragraph-font-size);
        padding: 0 0 30px;
    }

    .esp-streamlined--coverage-summary--pricing {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .esp-streamlined--coverage-summary--pricing--items-wrap {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .esp-streamlined--coverage-summary--pricing--item {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        padding-right: 3rem;
        padding-right: var(--spacing-large);
    }

    .esp-streamlined--coverage-summary--pricing--item--items-wrap {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .esp-streamlined--coverage-summary--pricing--item span:first-child {
        font-size: 16px;
        font-size: var(--esp-streamlined-label-font-size-normal);
        line-height: 16px;
        line-height: var(--esp-streamlined-label-font-size-normal);
        padding-bottom: 10px;
    }

    .esp-streamlined--coverage-summary--pricing--item span:last-child {
        font-size: 20px;
    }

    .esp-streamlined--coverage-summary--pricing--action-buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .esp-streamlined--coverage-summary--pricing--action-buttons .em-check-box {
        min-width: auto;
    }

    .esp-streamlined--coverage-summary--pricing--action-buttons .em-check-box label {
        font-size: 16px;
        font-size: var(--esp-streamlined-label-font-size-normal);
        line-height: 16px;
        line-height: var(--esp-streamlined-label-font-size-normal);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        padding: 0;
    }

    .esp-streamlined--coverage-summary--pricing--action-buttons .em-check-box label:before {
        margin-right: 0;
        -ms-flex-item-align: center;
        align-self: center;
    }

    .esp-streamlined--coverage-summary--pricing--action-buttons .em-check-box label span {
        white-space: nowrap;
        padding-bottom: 10px;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {

        .esp-streamlined--recommended--content-wrap {
            padding-top: 0;
        }
        .esp-streamlined--coverage-summary-wrap {
            border: 1px solid #e6e8eb;
            border: var(--featured-option-border);
            background: #ffffff;
            background: var(--featured-option-background);
            padding: 1.125rem;
            padding: var(--spacing-small);
            margin-bottom: 0.75rem;
            margin-bottom: var(--spacing-xsmall);
        }

        .esp-streamlined--coverage-summary--heading-wrap {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .esp-streamlined--coverage-summary--details {
            display: none;
        }

        .esp-streamlined--coverage-summary--pricing {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: normal;
            -ms-flex-align: normal;
            align-items: normal;
        }

        .esp-streamlined--coverage-summary--pricing--item {
            padding-top: 0.75rem;
            padding-top: var(--spacing-xsmall);
            padding-bottom: 0.75rem;
            padding-bottom: var(--spacing-xsmall);
        }

        .esp-streamlined--coverage-summary--pricing--action-buttons {
            -ms-flex-item-align: start;
            align-self: flex-start;
        }
        .esp-streamlined--coverage-summary--pricing--action-buttons .em-check-box label {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            padding: 0.75rem 0;
            padding: var(--checkbox-label-padding);
        }

        .esp-streamlined--coverage-summary--pricing--action-buttons .em-check-box label:before {
            margin-right: 1rem;
            margin-right: var(--checkbox-label-before-margin-right);
        }

        .esp-streamlined--coverage-summary--pricing--action-buttons .em-check-box label span {
            padding-bottom: 0;
        }
    }

    .esp-streamlined--status-sidebar {
        max-width: 360px;
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: var(--border-drop-shadow);
        box-shadow: var(--border-drop-shadow);
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        width: 100%;
        z-index: 1;
        z-index: var(--z-index-base);
    }

    .esp-streamlined--status-sidebar--section {
        padding: 20px;
    }

    .esp-streamlined--status-sidebar--section .em-field-label {
        font-size: 1.0625rem;
        font-size: var(--esp-streamlined-paragraph-font-size);
        line-height: 1.0625rem;
        line-height: var(--esp-streamlined-paragraph-font-size);
    }

    .esp-streamlined--status-sidebar--section--heading {
        font-size: 0.875rem;
        font-size: var(--esp-streamlined-label-font-size-small);
        line-height: 0.875rem;
        line-height: var(--esp-streamlined-label-font-size-small);
        padding: 15px 0;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.5px;
    }

    .esp-streamlined--status-sidebar--section--row,
    .esp-streamlined--status-sidebar--section--row-total {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        font-size: 1.0625rem;
        font-size: var(--esp-streamlined-paragraph-font-size);
        line-height: 1.0625rem;
        line-height: var(--esp-streamlined-paragraph-font-size);
        padding-bottom: 12px;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }

    .esp-streamlined--status-sidebar--section--row:last-child,
    .esp-streamlined--status-sidebar--section--row-total:last-child {
        padding-bottom: 0;
    }

    .esp-streamlined--status-sidebar--section--row-total {
        padding-top: 10px;
    }

    .esp-streamlined--status-sidebar--section--row-total span:first-child {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-size: var(--esp-streamlined-label-font-size-small);
        line-height: 0.875rem;
        line-height: var(--esp-streamlined-label-font-size-small);
        font-weight: 600;
    }

    .esp-streamlined--status-sidebar--section--row input::-webkit-input-placeholder {
        font-size: 17px;
    }

    .esp-streamlined--status-sidebar--section--row input:-ms-input-placeholder {
        font-size: 17px;
    }

    .esp-streamlined--status-sidebar--section--row input::-ms-input-placeholder {
        font-size: 17px;
    }

    .esp-streamlined--status-sidebar--section--row input::placeholder {
        font-size: 17px;
    }

    .esp-streamlined--status-sidebar--section--row input {
        width: 214px;
    }

    .esp-streamlined--status-sidebar--section--row-tooltip {
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    .esp-streamlined--status-sidebar--section--row #em-tooltip {
        bottom: 30px;
        left: 0;
        min-width: 260px;
    }

    .esp-streamlined--status-sidebar--section--row #em-tooltip:after {
        left: 10%;
    }

    .esp-streamlined--status-sidebar--section--row-total {
        padding: 20px 0;
    }

    .esp-streamlined--status-sidebar--section--row-total-final-amount {
        margin-top: 30px;
        padding: 0;
    }

    .esp-streamlined--status-sidebar--section--row-total-final-amount
        .esp-streamlined--status-sidebar--section--row-total {
        padding: 0 20px;
        line-height: 60px;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .esp-streamlined--status-sidebar {
            max-width: 100%;
            padding-bottom: 1.5rem;
            padding-bottom: var(--spacing-main);
            -webkit-box-shadow: none;
            box-shadow: none;
            border: none;
        }

        .esp-streamlined--status-sidebar .em-h1 {
            margin-bottom: 0;
        }

        .esp-streamlined--status-sidebar .em-button-outline.short {
            max-width: 345px;
            height: 44px;
            margin: auto;
        }

        .esp-streamlined--status-sidebar--section {
            padding: 20px 0;
        }

        .esp-streamlined--status-sidebar--section--row {
            padding-top: 12px;
        }

        .esp-streamlined--status-sidebar--section--row-total-final-amount {
            margin-left: calc(-1 * 1.125rem);
            margin-left: calc(-1 * var(--page-content-padding-sides-thin));
            margin-right: calc(-1 * 1.125rem);
            margin-right: calc(-1 * var(--page-content-padding-sides-thin));
        }
    }

    .esp-streamlined--date-sign--start {
        padding: 2.25rem 0;
        padding: var(--spacing-medium) 0;
    }

    .esp-streamlined--date-sign .coverage-start-date {
        padding: 0;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .esp-streamlined--date-sign--start {
            padding: 1.125rem 0;
            padding: var(--spacing-small) 0;
        }

        .esp-streamlined--date-sign .coverage-start-date {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .esp-streamlined--date-sign .coverage-start-date .rdt,
        .esp-streamlined--date-sign .coverage-start-date .rdt .form-control,
        .esp-streamlined--date-sign .coverage-start-date .em-check-box {
            width: 100%;
        }

        .esp-streamlined--date-sign .coverage-start-date .end-date {
            width: 100%;
            max-width: 21.625rem;
            max-width: var(--form-field-max-width);
        }
    }

    .esp-streamlined--sign-up .esp-streamlined--page-heading-section {
        margin: 2.25rem;
        margin: var(--spacing-medium);
    }

    .esp-streamlined--sign-up .em-public-signup-page {
        padding: 0;
    }

    .esp-streamlined--sign-up .em-public-signup-form {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 2.25rem;
        padding: 0 var(--spacing-medium);
    }

    .esp-streamlined--sign-up .em-public-signup-form-item {
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .esp-streamlined--sign-up .em-public-signup-form-item:nth-child(2n + 1) {
        margin-right: 1.5rem;
        margin-right: var(--gutter);
    }

    .esp-streamlined--sign-up .em-public-signup-form-item:nth-child(2n) {
        margin-right: 0;
    }

    .esp-streamlined--sign-up .em-public-signup-form-item .em-validation-message:empty {
        display: none;
    }

    .esp-streamlined--sign-up .em-public-signup-form-item .em-input,
    .esp-streamlined--sign-up .em-public-signup-form-item .em-field-wrap {
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
    }

    .esp-streamlined--sign-up .em-public-signup-form-item--wide {
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
    }

    .esp-streamlined--sign-up .em-public-signup-form-item--wide .em-input,
    .esp-streamlined--sign-up .em-public-signup-form-item--wide .em-field-wrap {
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
    }

    .esp-streamlined--sign-up
        .em-public-signup-form
        .em-city-state-zip
        .em-public-signup-form-item:last-child {
        margin-right: 0;
    }

    .esp-streamlined--sign-up
        .em-public-signup-form
        .em-city-state-zip
        .em-public-signup-form-item:nth-child(2) {
        margin-right: 1.5rem;
        margin-right: var(--gutter);
        width: 222px;
    }

    .esp-streamlined--sign-up
        .em-public-signup-form
        .em-city-state-zip
        .em-public-signup-form-item:nth-child(3) {
        margin-right: 0;
        width: 100px;
    }

    .esp-streamlined--sign-up .em-public-signup-navigation {
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-box-align: inherit;
        -ms-flex-align: inherit;
        align-items: inherit;
        border: none;
        padding-left: 0;
    }

    .esp-streamlined--sign-up .em-public-signup-navigation-wrapper {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 1.5rem;
        padding: 0 var(--spacing-main);
    }

    .esp-streamlined--sign-up .em-public-signup-navigation .em-save-for-later-button {
        text-align: right;
        border: none;
    }

    .esp-streamlined--sign-up .em-public-signup-navigation .em-back-button {
        border: none;
        min-width: auto;
    }

    .esp-streamlined--sign-up--set-password {
        padding: 0 2.25rem;
        padding: 0 var(--spacing-medium);
    }

    .em-field-label-streamlined {
        margin-bottom: 24px;
    }

    .em-other-coverages {
        margin-top: 24px;
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .esp-streamlined--sign-up .esp-streamlined--page-heading-section {
            margin: 0;
        }
        .esp-streamlined--sign-up .em-public-signup-page {
            background: transparent;
        }

        .esp-streamlined--sign-up .em-public-signup-form {
            padding: 1.125rem;
            padding: var(--page-content-padding-sides-thin);
        }

        .esp-streamlined--sign-up .em-public-signup-form-item {
            margin-right: 0;
            max-width: 21.625rem;
            max-width: var(--form-field-max-width);
        }

        .esp-streamlined--sign-up .em-public-signup-form-item:nth-child(2n + 1) {
            margin-right: 0;
        }

        .esp-streamlined--sign-up .em-public-signup-form-item .em-input,
        .esp-streamlined--sign-up .em-public-signup-form-item .em-field-wrap {
            max-width: 100%;
        }
        .esp-streamlined--sign-up .em-public-signup-form-item--wide .em-input,
        .esp-streamlined--sign-up .em-public-signup-form-item--wide .em-field-wrap {
            max-width: 100%;
        }

        .esp-streamlined--sign-up
            .em-public-signup-form
            .em-city-state-zip
            .em-public-signup-form-item:first-child,
        .esp-streamlined--sign-up
            .em-public-signup-form
            .em-city-state-zip
            .em-public-signup-form-item:nth-child(2),
        .esp-streamlined--sign-up
            .em-public-signup-form
            .em-city-state-zip
            .em-public-signup-form-item:nth-child(3) {
            margin-right: 0;
            width: 21.625rem;
            width: var(--form-field-max-width);
        }
        .esp-streamlined--sign-up .em-public-signup-form-row-wrap {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-line-pack: center;
            align-content: center;
        }

        .esp-streamlined--sign-up .em-public-signup-form:nth-child(2) {
            padding: 0px;
        }

        .esp-streamlined--sign-up .em-public-signup-set-password {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-line-pack: center;
            align-content: center;
        }

        .esp-streamlined--sign-up .em-public-signup-password-validation {
            max-width: 21.625rem;
            max-width: var(--form-field-max-width);
            width: 100%;
        }

        .esp-streamlined--sign-up--set-password {
            padding: 0;
        }

        .em-public-signup-form-item label {
            display: block;
            margin-bottom: 8px;
        }
        .esp-streamlined--sign-up .em-public-signup-form {
            padding: 0px;
        }
    }

    /* select coverage wizard */

    .em-select-coverages-wizard-container {
        margin: 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        text-align: center;
    }

    .em-select-coverages-wizard-container .em-button.em-button-default.em-button-size-large {
        width: 280px;
        margin: 48px auto 36px;
    }

    .em-select-coverages-wizard-container .em-text-input {
        width: 280px;
        margin: 0 auto 0.75rem;
        margin: 0 auto var(--spacing-xsmall);
    }

    .em-select-coverages-wizard-container .em-select-field {
        width: 280px;
        margin: 0 auto 0.75rem;
        margin: 0 auto var(--spacing-xsmall);
    }

    .em-select-coverages-wizard-container .em-h1 {
        margin-top: 2.25rem;
        margin-top: var(--spacing-medium);
        margin-bottom: 2.25rem;
        margin-bottom: var(--spacing-medium);
    }

    .em-select-coverages-wizard-container .em-h1-heading {
        margin-top: 110px;
    }

    .em-select-coverages-wizard-container .em-h1-has-subtitle {
        margin-bottom: 0.75rem;
        margin-bottom: var(--spacing-xsmall);
    }

    .em-select-coverages-wizard-container .em-info-text {
        font-size: 1.25rem;
        font-size: var(--font-size-p);
        margin-bottom: 2.25rem;
        margin-bottom: var(--spacing-medium);
    }

    .em-select-coverages-wizard-container .em-select-field__option {
        text-align: left;
    }

    .em-select-coverages-wizard-container .em-intro-title {
        white-space: nowrap;
    }

    .em-select-coverages-wizard-container .em-radio-button {
        border: 1px solid #e6e8eb;
        border: 1px solid var(--em-medium-gray);
        border-radius: 22px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 44px;
        width: 280px;
        margin: 0 auto 0.75rem;
        margin: 0 auto var(--spacing-xsmall);
        padding: 0 0.75rem;
        padding: 0 var(--spacing-xsmall);
    }

    .em-select-coverages-wizard-container .em-radio-button label:hover {
        cursor: pointer;
    }

    .em-select-coverages-wizard-container .em-radio-button:hover {
        cursor: pointer;
    }

    .em-select-coverages-wizard-container .selected {
        border: 1px solid #47c6ec;
        border: 1px solid var(--em-light-blue);
    }

    .em-select-coverages-wizard-container .em-radio-button {
        background: #ffffff;
        background: var(--em-white);
    }

    /* onboarding wizard */

    .em-onboarding-dashboard-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0 auto;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .em-onboarding-dashboard-container .em-h1-heading.em-h1-heading--centered {
        text-align: center;
    }

    .em-onboarding-dashboard-container .em-p.em-p--centered {
        text-align: center;
    }

    .em-onboarding-dashboard-container .em-onboarding-dashboard-options {
        margin: 3rem 0;
        margin: var(--spacing-large) 0;
    }

    .em-onboarding-dashboard-container .em-button.em-button--spaced {
        margin: 0.75rem;
        margin: var(--spacing-xsmall);
    }

    /* sign up */

    /* These are general styles for a content container, to look like they're on the public site */

    .em-public {
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
    }

    .em-public-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        z-index: 1;
        z-index: var(--z-index-base);
    }

    .em-public-title {
        position: relative;
        z-index: 10;
        padding-bottom: 48px;
        margin: auto;
        width: 100%;
        max-width: 1200px;
    }

    .em-public-title::after {
        content: '';
        width: 100vw;
        position: absolute;
        top: -50%;
        left: calc(50% - 50vw);
        right: 0;
        height: 400%;
        z-index: -1;
    }

    .em-public-title-description {
        font-size: 20px;
        font-weight: 300;
    }

    .em-public-title h1 {
        font-size: 28px;
        line-height: 32px;
        font-weight: 300;
        text-align: left;
        margin-top: 26px;
    }

    /* End of public container general styles */

    .em-public-signup-page {
        max-width: 1200px;
    }

    .em-public-signup-content {
        position: relative;
        z-index: 100;
        z-index: var(--z-index-dropdown);
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
    }

    .em-public-signup-form {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        max-width: 825px;
        max-width: var(--width-medium);
        padding: 2.25rem;
        padding: var(--spacing-medium);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .em-public-signup-form-row-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .em-public-signup-form-item {
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
        width: 100%;
        margin-bottom: 0.75rem;
        margin-bottom: var(--spacing-xsmall);
        margin-right: 30px;
    }

    .em-public-signup-form-item .em-input {
        width: 100%;
    }

    .em-public-signup-form-item label {
        margin-bottom: 0.5rem;
        margin-bottom: var(--spacing-tiny);
        position: relative;
    }

    .em-public-signup-form-item .em-required-field label::after {
        position: absolute;
        content: '*';
        font-size: 16px;
    }

    .em-public-signup-form-item--wide {
        max-width: 100%;
    }

    .em-public-signup-form-item--wide .em-input {
        max-width: 100%;
    }

    .em-public-signup-form-set-password {
        max-width: 21.625rem;
        max-width: var(--form-field-max-width);
        padding: 0;
    }

    .em-public-signup-step {
        position: absolute;
        display: inline-block;
        top: 2.25rem;
        top: var(--spacing-medium);
        right: 2.25rem;
        right: var(--spacing-medium);
        z-index: 200;
        z-index: var(--z-index-sticky);
        font-weight: 300;
        font-weight: var(--font-weight-light);
    }

    .em-public-signup-navigation {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        z-index: 10;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 2.25rem;
        padding: var(--spacing-medium);
        -webkit-box-shadow: 0px 40px 60px 0px rgba(20, 23, 51, 0.1);
        box-shadow: 0px 40px 60px 0px rgba(20, 23, 51, 0.1);
        border-top: 1px solid #e7e9ec;
        border-top: 1px solid var(--em-medium-gray3);
    }

    .em-public-signup-navigation .zf-privacy {
        width: 100%;
        position: relative;
    }

    .em-public-signup-navigation-buttons {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        width: 70%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .em-public-signup-set-password {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .em-public-signup-set-password-page {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        min-height: 500px;
        padding: 2.25rem;
        padding: var(--spacing-medium);
        height: unset;
    }

    .em-public-signup-password-validation {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        margin-left: 0.75rem;
        margin-left: var(--spacing-xsmall);
    }

    .em-public-signup-password-validation-item {
        position: relative;
        font-size: 14px;
        margin-bottom: 22px;
        padding-left: 18px;
    }

    .em-public-signup-password-validation-item:last-child {
        margin-bottom: 0;
    }

    .em-public-signup-password-validation-item::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
    }

    .em-public-signup-password-validation-item.em-password-item-valid::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        background: no-repeat center;
    }

    .em-public-signup-form-item--wide {
        max-width: 100%;
    }

    .em-public-signup-form-item .em-validation-message {
        display: inline-block;
        font-size: 0.9375rem;
        font-size: var(--font-size-p2);
        font-weight: 400;
        font-weight: var(--font-weight-normal);
    }

    .user form.em-public-signup-form {
        min-width: unset;
    }

    .em-already-have-account {
        padding: 0px 0px 36px 36px;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }

    .em-text-p1 {
        font-size: 1.0625rem;
        font-size: var(--font-size-p1);
    }

    /* Sign Up media queries */

    @media only screen and (max-width: 1024px) {
        .em-public-signup-form-item {
            width: 100%;
            max-width: 100%;
            margin-right: 0;
        }

        .em-public-signup-form-item .em-input {
            max-width: 100%;
        }

        .em-public-signup-form-item .em-select-field,
        .em-public-signup-form-item .em-select-field__control {
            max-width: 100%;
        }

        .em-public-signup-step {
            top: 0.75rem;
            top: var(--spacing-xsmall);
        }
    }

    @media (max-width: 950px) {
        .em-public-signup-navigation {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            padding-left: 2.25rem;
            padding-left: var(--spacing-medium);
            padding-right: 2.25rem;
            padding-right: var(--spacing-medium);
        }

        .em-public-signup-navigation-buttons {
            -ms-flex-item-align: center;
            align-self: center;
            margin-top: 30px;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .em-public-signup-navigation-buttons .em-button:nth-child(2) {
            margin-top: 0.75rem;
            margin-top: var(--spacing-xsmall);
        }
    }

    @media (max-width: 800px) {
        .em-already-have-account {
            padding: 0 0 1.125rem 2.25rem;
            padding: 0 0 var(--spacing-small) var(--spacing-medium);
        }
    }

    @media (max-width: 375px) {
        .em-public-signup-navigation,
        .em-already-have-account {
            padding-left: 1.125rem;
            padding-left: var(--spacing-small);
            padding-right: 1.125rem;
            padding-right: var(--spacing-small);
        }
    }

    @media (min-width: 361px) and (max-width: 767px), (max-width: 360px) {
        .em-public-signup-form {
            padding: 1.125rem;
            padding: var(--spacing-small);
        }
    }

    /* renewals */

    .esp-renewal--recommended--content-wrap {
        padding-top: 30px;
    }

    .esp-renewal--page-heading-section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }

    .esp-renewal--page-heading-section div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .esp-renewal--page-heading-section div .em-button-text {
        margin-right: 0.75rem;
        margin-right: var(--spacing-xsmall);
        font-size: 1.0625rem;
        font-size: var(--esp-renewal-paragraph-font-size);
    }

    .esp-renewal--coverage-summary-wrap {
        padding-bottom: 60px;
    }

    .esp-renewal--coverage-summary--heading {
        font-size: 20px;
        font-weight: 400;
        font-weight: var(--font-weight-normal);
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .esp-renewal--coverage-summary--heading-wrap {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        padding-bottom: 10px;
    }

    .esp-renewal--coverage-summary--heading-wrap button {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        font-size: 16px;
        font-size: var(--font-size-base);
        padding: 0;
    }

    .esp-renewal--coverage-summary--details p {
        max-width: 560px;
        font-size: 1.0625rem;
        font-size: var(--esp-renewal-paragraph-font-size);
        padding: 0 0 30px;
    }

    .esp-renewal--coverage-summary--coverage-options {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-bottom: 1.5rem;
        margin-bottom: var(--spacing-main);
    }

    .esp-renewal--coverage-summary--previous-term {
        margin-top: 1.5rem;
        margin-top: var(--spacing-main);
    }

    .esp-renewal--coverage-summary--pricing {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .esp-renewal--coverage-summary--pricing--items-wrap {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
    }

    .esp-renewal--coverage-summary--pricing--previous-item {
        width: 33%;
    }

    .esp-renewal--coverage-summary--pricing--item {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        padding-right: 3rem;
        padding-right: var(--spacing-large);
    }

    .esp-renewal--coverage-summary--pricing--item span:first-child {
        font-size: 16px;
        font-size: var(--esp-renewal-label-font-size-normal);
        padding-bottom: 10px;
    }

    .esp-renewal--coverage-summary--pricing--item span:last-child {
        font-size: 20px;
    }

    .esp-renewal--coverage-summary--pricing--action-buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .esp-renewal--coverage-summary--pricing--action-buttons .em-check-box {
        min-width: auto;
    }

    .esp-renewal--coverage-summary--pricing--action-buttons .em-check-box label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        padding: 0;
        font-size: 16px;
        font-size: var(--font-size-base);
        line-height: 16px;
        line-height: var(--font-size-base);
    }

    .esp-renewal--coverage-summary--pricing--action-buttons .em-check-box label:before {
        margin-right: 0;
        -ms-flex-item-align: center;
        align-self: center;
    }

    .esp-renewal--coverage-summary--pricing--action-buttons .em-check-box label span {
        white-space: nowrap;
        padding-bottom: 10px;
    }

    /**
 * ESP renewal variables
 */

    :root {
        /* colors */
        --esp-renewal-background-color: var(--em-white);

        /* text */
        --esp-renewal-paragraph-font-size: 1.0625rem; /* 17px */
        --esp-renewal-label-font-size-normal: var(--font-size-base); /* 16px */
        --esp-renewal-label-font-size-small: 0.875rem; /* 14px */

        /* borders */
        --esp-renewal-border-style: var(--border-solid-gray);
        --esp-renewal-drop-shadow: var(--border-drop-shadow);
    }

    /**
 * ESP renewal predefined classes
 * (of elements and sections in the flow)
 */

    .esp-renewal {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-direction: row;
        flex-direction: row;
        max-width: 1242px;
    }

    .esp-renewal--quotes-section {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border: 0 solid transparent;
        min-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;
        width: 100%;
        border: 1px solid #e6e8eb;
        border: var(--esp-renewal-border-style);
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: var(--esp-renewal-drop-shadow);
        box-shadow: var(--esp-renewal-drop-shadow);
        margin-right: 20px;
    }

    .esp-renewal--component {
        padding: 2.25rem 2.25rem;
        padding: var(--spacing-medium) var(--page-content-padding-sides);
    }

    .esp-renewal--navigation.em-ml-cta-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        padding: 2.25rem;
        padding: var(--page-content-padding-sides);
    }

    .esp-renewal--navigation--purchase-button-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }

    .esp-renewal--navigation--purchase-button-wrap button:first-child {
        margin-right: 30px;
    }

    .esp-renewal--page-heading-is-underlined {
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--esp-renewal-border-style);
        padding-bottom: 1.5rem;
        padding-bottom: var(--spacing-main);
    }

    .esp-renewal--page-heading-is-underlined .em-h1 {
        margin-bottom: 0;
    }

    .esp-renewal--label-is-normal {
        font-size: 16px;
        font-size: var(--esp-renewal-label-font-size-normal);
    }

    .esp-renewal--label-is-small {
        font-size: 0.875rem;
        font-size: var(--esp-renewal-label-font-size-small);
    }

    .esp-renewal--status-sidebar {
        max-width: 360px;
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: var(--border-drop-shadow);
        box-shadow: var(--border-drop-shadow);
        border: 1px solid #e6e8eb;
        border: var(--border-solid-gray);
        width: 100%;
        z-index: 1;
        z-index: var(--z-index-base);
    }

    .esp-renewal--status-sidebar--section {
        padding: 20px;
    }

    .esp-renewal--status-sidebar--section .em-field-label {
        font-size: 1.0625rem;
        font-size: var(--esp-renewal-paragraph-font-size);
        line-height: 1.0625rem;
        line-height: var(--esp-renewal-paragraph-font-size);
    }

    .esp-renewal--status-sidebar--section--heading {
        font-size: 0.875rem;
        font-size: var(--esp-renewal-label-font-size-small);
        line-height: 0.875rem;
        line-height: var(--esp-renewal-label-font-size-small);
        padding: 15px 0;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.5px;
    }

    .esp-renewal--status-sidebar--section--row,
    .esp-renewal--status-sidebar--section--row-total {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        font-size: 1.0625rem;
        font-size: var(--esp-renewal-paragraph-font-size);
        line-height: 1.0625rem;
        line-height: var(--esp-renewal-paragraph-font-size);
        padding-bottom: 12px;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }

    .esp-renewal--status-sidebar--section--row:last-child,
    .esp-renewal--status-sidebar--section--row-total:last-child {
        padding-bottom: 0;
    }

    .esp-renewal--status-sidebar--section--row-total {
        padding-top: 10px;
    }

    .esp-renewal--status-sidebar--section--row-total span:first-child {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-size: var(--esp-renewal-label-font-size-small);
        font-weight: 600;
    }

    .esp-renewal--status-sidebar--section--row input::-webkit-input-placeholder {
        font-size: 17px;
    }

    .esp-renewal--status-sidebar--section--row input:-ms-input-placeholder {
        font-size: 17px;
    }

    .esp-renewal--status-sidebar--section--row input::-ms-input-placeholder {
        font-size: 17px;
    }

    .esp-renewal--status-sidebar--section--row input::placeholder {
        font-size: 17px;
    }

    .esp-renewal--status-sidebar--section--row input {
        width: 214px;
    }

    .esp-renewal--status-sidebar--section--row-tooltip {
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    .esp-renewal--status-sidebar--section--row #em-tooltip {
        bottom: 30px;
        left: 0;
        min-width: 260px;
    }

    .esp-renewal--status-sidebar--section--row #em-tooltip:after {
        left: 10%;
    }

    .esp-renewal--status-sidebar--section--row-total {
        padding: 20px 0;
    }

    .esp-renewal--status-sidebar--section--row-total-final-amount {
        margin-top: 30px;
        padding: 0;
    }

    .esp-renewal--status-sidebar--section--row-total-final-amount
        .esp-renewal--status-sidebar--section--row-total {
        padding: 0 20px;
        line-height: 60px;
    }

    .esp-renewal--signature {
        height: 88%;
    }

    .esp-renewal--signature--start {
        padding: 2.25rem 0;
        padding: var(--spacing-medium) 0;
    }

    .esp-renewal--signature--start--dates {
        font-size: 20px;
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    .esp-renewal--signature--signatures {
        padding-bottom: 60px;
    }

    /* lpl */

    /*lpl-quote-step*/

    .lpl-quote--container {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #e6e8eb;
        border: var(--esp-streamlined-border-style);
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: var(--esp-streamlined-drop-shadow);
        box-shadow: var(--esp-streamlined-drop-shadow);
        padding: 2.25rem;
        padding: var(--spacing-medium);
        width: 100%;
    }

    .lpl-quote--page-heading-underlined {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--esp-renewal-border-style);
        padding-bottom: 1.5rem;
        padding-bottom: var(--spacing-main);
    }

    .lpl-quote--title-and-date {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: wrap;
        flex-flow: wrap;
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
        width: 50%;
    }

    .lpl-quote--title-and-date h5 {
        font-size: 1.75rem;
    }

    .lpl-quote--title-and-date > div {
        margin-top: 2.25rem;
        margin-top: var(--spacing-medium);
        margin-right: 2.25rem;
        margin-right: var(--spacing-medium);
    }

    .lpl-quote--title-and-date label {
        padding-bottom: 0.5rem;
        padding-bottom: var(--spacing-tiny);
        font-size: 16px;
    }

    .lpl-quote--download-links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 7px;
    }

    .lpl-quote--download-links--quote {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .lpl-quote--download-links a,
    .lpl-quote--download-links button {
        margin: 0 1.125rem;
        margin: 0 var(--spacing-small);
    }

    .lpl-quote--download-links a:first-child,
    .lpl-quote--download-links button:first-child {
        margin-left: 0;
    }

    .lpl-quote--download-links a:last-child,
    .lpl-quote--download-links button:last-child {
        margin-right: 0;
    }

    .lpl-quote--download-links .em-icon {
        margin-left: 10px;
    }

    .lpl-quote--effective-date-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 1.25rem;
        font-size: var(--date-picker-input-font-size);
    }

    .lpl-quote--effective-date-container--end-date {
        -ms-flex-item-align: center;
        align-self: center;
        margin: 22px 0 0 25px;
    }

    .lpl-quote--section {
        margin-top: 2.25rem;
        margin-top: var(--spacing-medium);
    }

    .lpl-quote--section--total-premium {
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: 0.5rem;
        margin-top: var(--spacing-tiny);
    }

    .lpl-quote--section--limits-and-deductible {
        margin-top: 3rem;
        margin-top: var(--spacing-large);
        max-width: 745px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .lpl-quote--section--label-and-box {
        padding: 10px 10px 10px 0;
        margin-right: 1.125rem;
        width: 354px;
    }

    .lpl-quote--section--label-and-box label {
        padding-bottom: 0.5rem;
        padding-bottom: var(--spacing-tiny);
    }

    .lpl-quote--section--label-and-box .em-select-field,
    .lpl-quote--section--label-and-box .em-select-field__control,
    .lpl-quote--section--label-and-box .em-select-field__menu-list,
    .lpl-quote--section--label-and-box .em-select-field__menu {
        max-width: 354px;
    }

    .lpl-quote--current-policy {
        margin-top: 20px;
    }

    .lpl-quote--current-policy div {
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    .lpl-quote--current-policy div:last-child {
        font-size: 1.25rem;
        font-size: var(--date-picker-input-font-size);
    }

    .lpl-quote--button-next {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 1.5rem;
        padding: 0 1.5rem;
        padding: 0 var(--spacing-main);
        height: 125px;
        border-top: 1px solid #e6e8eb;
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
        min-width: 250px;
        min-width: var(--button-large-min-width);
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-top: 3rem;
        margin-top: var(--spacing-large);
    }

    .lpl-quote--button-next .em-button-size-large {
        max-width: 250px;
        max-width: var(--button-large-min-width);
    }

    .lpl-quote--buttons-next-save-purchase {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 125px;
        border-top: 1px solid #e6e8eb;
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
        min-width: 250px;
        min-width: var(--button-large-min-width);
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 3rem;
        margin-top: var(--spacing-large);
    }

    .lpl-quote--buttons-next-save-purchase .em-lpl-button-back {
        text-align: left;
    }

    .lpl-quote--buttons-next-save-purchase .em-lpl-button-size-normal {
        max-width: 250px;
        max-width: var(--button-large-min-width);
    }

    .lpl-quote--buttons-next-save-purchase .em-lpl-buttons-save-purchase {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .lpl-quote--about-carrier {
        width: 700px;
        margin-top: 70px;
    }

    .lpl-quote--about-carrier p {
        padding: 0;
    }

    .lpl-download-quote {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-left: 0.75rem;
        margin-left: var(--spacing-xsmall);
    }

    /*loader*/

    .em-lpl-notification-container .loader-title {
        font-size: 1.75rem;
    }

    /*signature*/

    .em-lpl-quote--signature--page-heading-underlined {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: wrap;
        flex-flow: wrap;
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--esp-renewal-border-style);
        padding-bottom: 1.5rem;
        padding-bottom: var(--spacing-main);
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-lpl-quote--signature--page-heading-underlined h5 {
        font-size: 1.75rem;
        margin-bottom: 2.25rem;
        margin-bottom: var(--spacing-medium);
    }

    .em-lpl-quote--signature--effective-date {
        margin-bottom: 1.5rem;
        margin-bottom: var(--spacing-main);
    }

    .em-lpl-quote--signature--effective-date div:last-child {
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
        font-size: 1.25rem;
        font-size: var(--date-picker-input-font-size);
    }

    .em-lpl-quote--section--signature-checkbox {
        max-width: 655px;
    }

    /*thank you page*/

    .em-lpl-other-action {
        width: 350px;
    }

    .em-lpl-covered-information {
        margin-right: 180px;
    }

    .em-lpl-covered-information .em-button-size-large {
        max-width: 250px;
        max-width: var(--button-large-min-width);
    }

    .em-lpl-nextsteps {
        margin-left: 10px;
    }

    /* cna querry */

    .cna-quote--icon {
        -webkit-box-flex: 1;
        -ms-flex: 1 1;
        flex: 1 1;
        text-align: right;
        width: 32%;
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .cna-quote--icon img {
        width: 150px;
    }

    .cna-quote--edited {
        text-decoration: none;
    }

    .cna-quote--edited:hover {
        text-decoration: none;
    }

    .cna-quote--title {
        font-size: 1.75rem;
        font-size: var(--font-size-heading-h1);
        font-weight: 500;
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--esp-renewal-border-style);
        padding: 2.25rem;
        padding: var(--spacing-medium);
    }

    .cna-quote--send-detailed-quote {
        height: 18px;
        width: 291px;
        font-size: 15px;
        line-height: 18px;
        text-align: right;
    }

    .cna-quote--container {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #e6e8eb;
        border: var(--esp-streamlined-border-style);
        -webkit-box-shadow: 0 1px 3px 0 #e6e8eb;
        box-shadow: 0 1px 3px 0 #e6e8eb;
        -webkit-box-shadow: var(--esp-streamlined-drop-shadow);
        box-shadow: var(--esp-streamlined-drop-shadow);
        margin-bottom: 1.5rem;
        margin-bottom: var(--spacing-main);
        width: 100%;
    }

    .cna-quote--container--loader {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
    }

    .cna-quote--container--loader .loader-title {
        top: calc(35% - 90px);
        font-size: 1.75rem;
    }

    .cna-quote--container--loader .loader {
        top: calc(35% - 24px);
    }

    .cna-quote--container--loader .loader-subtitle {
        top: calc(35% + 60px);
    }

    .cna-quote--container--whats-difference {
        height: 24px;
        width: 129px;
        margin-left: 2.25rem;
        margin-left: var(--spacing-medium);
        font-size: 15px;
        line-height: 24px;
        font-weight: normal;
    }

    .cna-quote--container--next-and-save {
        padding: 2.25rem;
        padding: var(--spacing-medium);
        border-top: 1px solid #e6e8eb;
        border-top: var(--esp-renewal-border-style);
    }

    .cna-quote--container--row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1 1;
        flex: 1 1;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .cna-quote--container--choice-endorsement {
        padding-left: 2.25rem;
        padding-left: var(--spacing-medium);
        padding-top: 2.25rem;
        padding-top: var(--spacing-medium);
        padding-bottom: 0.5rem;
        padding-bottom: var(--spacing-tiny);
    }

    .cna-quote--container--title {
        font-size: 1.25rem;
        font-size: var(--font-size-p);
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--esp-renewal-border-style);
        padding: 2.25rem;
        padding: var(--spacing-medium);
    }

    .cna-quote--container--property {
        padding: 2.25rem;
        padding: var(--spacing-medium);
    }

    .cna-quote--container--property--warning-message {
        margin-bottom: 1.125rem;
        margin-bottom: var(--spacing-small);
    }

    .cna-quote--container--property--warning {
        border: 1px solid #e6e8eb;
        border: var(--esp-streamlined-border-style);
    }

    .cna-quote--container--property--building-and-currency {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 2.25rem;
        margin-bottom: var(--spacing-medium);
    }

    .cna-quote--container--property--building-and-currency label {
        margin-bottom: 0.5rem;
        margin-bottom: var(--spacing-tiny);
    }

    .cna-quote--container--property--building-and-currency > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 33%;
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .cna-quote--container--property--tenants {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 2.25rem;
        margin-bottom: var(--spacing-medium);
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 33%;
    }

    .cna-quote--container--property--tenants label {
        margin-bottom: 0.5rem;
        margin-bottom: var(--spacing-tiny);
    }

    .cna-quote--container--property--deductible {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .cna-quote--container--property--deductible > div {
        width: 33%;
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .cna-quote--container--property--deductible > div label {
        margin-bottom: 0.5rem;
        margin-bottom: var(--spacing-tiny);
    }

    .cna-quote--container--liability {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 2.25rem;
        padding: var(--spacing-medium);
    }

    .cna-quote--container--liability > div {
        width: 33%;
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .cna-quote--container--liability > div label {
        margin-bottom: 0.5rem;
        margin-bottom: var(--spacing-tiny);
    }

    .cna-quote--container--stop-gap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 2.25rem;
        padding: var(--spacing-medium);
        padding-top: 0;
    }

    .cna-quote--container--stop-gap > div {
        width: 33%;
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .cna-quote--container--stop-gap > div label {
        margin-bottom: 0.5rem;
        margin-bottom: var(--spacing-tiny);
    }

    .cna-quote--warning-message {
        margin-top: 0.5rem;
        margin-top: var(--spacing-tiny);
    }

    .cna-quote--button-next {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 1.5rem;
        padding: 0 1.5rem;
        padding: 0 var(--spacing-main);
        height: 125px;
        border-top: 1px solid #e6e8eb;
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
        min-width: 250px;
        min-width: var(--button-large-min-width);
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
    }

    .cna-quote--button-next .em-button-size-large {
        max-width: 250px;
        max-width: var(--button-large-min-width);
    }

    .cna-quote--buttons-next-save-purchase {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 125px;
        border-top: 1px solid #e6e8eb;
        border-top: 1px solid #e6e8eb;
        border-top: var(--border-solid-gray);
        min-width: 250px;
        min-width: var(--button-large-min-width);
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 3rem;
        margin-top: var(--spacing-large);
    }

    .cna-quote--buttons-next-save-purchase .em-cna-button-back {
        text-align: left;
        margin-left: 0.5rem;
        margin-left: var(--spacing-tiny);
    }

    .cna-quote--buttons-next-save-purchase .em-cna-button-size-normal {
        max-width: 250px;
        max-width: var(--button-large-min-width);
    }

    .cna-quote--buttons-next-save-purchase .em-cna-buttons-save-purchase {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-right: 2.25rem;
        padding-right: var(--spacing-medium);
    }

    .cna-quote--total-premium {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 2.25rem;
        padding: var(--spacing-medium);
    }

    .cna-quote--total-premium--quote {
        font-size: 1.75rem;
        font-size: var(--font-size-heading-h1);
        font-weight: 500;
    }

    .cna-quote--total-premium--quote-signature {
        font-size: 1.75rem;
        font-size: var(--font-size-heading-h1);
        font-weight: 500;
    }

    .cna-quote--total-premium--effective-date {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-top: 14px;
    }

    .cna-quote--total-premium--effective-date label {
        margin-bottom: 0.5rem;
        margin-bottom: var(--spacing-tiny);
    }

    .cna-quote--total-premium--effective-date--end-date {
        -ms-flex-item-align: center;
        align-self: center;
        margin: 22px 0 0 25px;
        font-size: 1.25rem;
        font-size: var(--date-picker-input-font-size);
    }

    .cna-quote--total-premium--title {
        font-size: 1.25rem;
        font-size: var(--font-size-p);
        padding-top: 0;
    }

    .cna-quote--total-premium a {
        font-weight: normal;
    }

    .cna-quote--radio-box {
        width: 32%;
        margin-right: 1.5rem;
        margin-right: var(--spacing-main);
    }

    .cna-quote--radio-box .em-radio-box {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-bottom: 0;
    }

    .cna-quote--radio-box .em-radio-button div {
        position: relative;
        bottom: 30%;
        font-weight: 500;
        font-size: 1.75rem;
        font-size: var(--font-size-heading-h1);
    }

    .cna-quote--radio-box .em-radio-box label:before {
        top: 100%;
        left: 45%;
    }

    .cna-quote--radio-box .em-radio-box label:after {
        top: 100%;
        left: 46.5%;
    }

    /*signature*/

    .em-cna-quote--signature--page-heading-underlined {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: wrap;
        flex-flow: wrap;
        padding-bottom: 1.5rem;
        padding-bottom: var(--spacing-main);
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .em-cna-quote--signature--page-heading-underlined h5 {
        font-size: 1.75rem;
        margin-bottom: 2.25rem;
        margin-bottom: var(--spacing-medium);
    }

    .em-cna-quote--signature--effective-date {
        margin-bottom: 1.5rem;
        margin-bottom: var(--spacing-main);
        padding-top: 14px;
    }

    .em-cna-quote--signature--effective-date div:last-child {
        margin-top: 1.125rem;
        margin-top: var(--spacing-small);
        font-size: 1.25rem;
        font-size: var(--date-picker-input-font-size);
    }

    .em-cna-quote--section--signature-checkbox {
        max-width: 655px;
        padding-left: 2.25rem;
        padding-left: var(--spacing-medium);
    }

    .cna-kickout--title-container {
        margin-bottom: 32px;
    }

    .cna-kickout--title {
        line-height: 1;
    }

    .cna-kickout--icon-container {
        width: 40%;
        -ms-flex-item-align: center;
        align-self: center;
    }

    .cna-kickout--icon {
        height: 100px;
        width: auto;
    }

    .cna-kickout--description {
        text-align: left;
    }

    /* pcoml */

    .pcoml-kickout--title-container {
        margin-bottom: 32px;
    }

    .pcoml-kickout--title {
        line-height: 1;
    }

    .pcoml-kickout--icon-container {
        width: 40%;
        -ms-flex-item-align: center;
        align-self: center;
    }

    .pcoml-kickout--icon {
        height: 100px;
        width: auto;
    }

    .pcoml-kickout--description {
        text-align: left;
    }

    /* submitting quote */

    .submitting--loader {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
    }

    .submitting--loader .loader-title {
        top: calc(35% - 90px);
        font-size: 1.75rem;
    }

    .submitting--loader .loader {
        top: calc(35% - 24px);
    }

    .submitting--loader .loader-subtitle {
        top: calc(35% + 60px);
    }

    /* grid columns */

    .grid-col-1 {
        width: calc(1 / 12 * 100%) !important;
    }

    .grid-col-2 {
        width: calc(2 / 12 * 100%) !important;
    }

    .grid-col-3 {
        width: calc(3 / 12 * 100%) !important;
    }

    .grid-col-4 {
        width: calc(4 / 12 * 100%) !important;
    }

    .grid-col-5 {
        width: calc(5 / 12 * 100%) !important;
    }

    .grid-col-6 {
        width: calc(6 / 12 * 100%) !important;
    }

    .grid-col-7 {
        width: calc(7 / 12 * 100%) !important;
    }

    .grid-col-8 {
        width: calc(8 / 12 * 100%) !important;
    }

    .grid-col-9 {
        width: calc(9 / 12 * 100%) !important;
    }

    .grid-col-10 {
        width: calc(10 / 12 * 100%) !important;
    }

    .grid-col-11 {
        width: calc(11 / 12 * 100%) !important;
    }

    .grid-col-12 {
        width: 100% !important;
    }

    /* areas of practice */

    .em-multiselect-areas-of-practice {
        margin-top: 12px;
    }

    .em-multiselect-areas-of-practice .em-multiselect-areas-of-practice.c-checkbox-group {
        max-width: 33%;
        margin-top: 12px !important;
        padding-right: 8px;
    }

    .em-multiselect-areas-of-practice .c-input-field__control {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .c-input--checkbox.c-input__label .c-text-button {
        display: inline-flex;
    }

    .em-multiselect-areas-of-practice .em-check-box {
        width: 33.33%;
    }

    .em-areas-of-practice-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        max-width: calc(9 / 12 * 100%);
        -webkit-box-flex: 2;
        -ms-flex-positive: 2;
        flex-grow: 2;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 0.75rem;
        margin-bottom: var(--spacing-xsmall);
    }

    .em-areas-of-practice-row-and-delete {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 0.75rem;
        margin-top: var(--spacing-xsmall);
    }

    .em-areas-of-practice-row-and-delete .em-icon {
        -ms-flex-item-align: center;
        align-self: center;
        margin-bottom: 0.75rem;
        margin-bottom: var(--spacing-xsmall);
        margin-left: 25px;
    }

    .em-areas-of-practice-percent input {
        border: 1px solid #e6e8eb;
        border: 1px solid var(--em-medium-gray);
        height: 2.75rem;
        height: var(--text-field-height);
        padding: 0 0.625rem;
        padding: var(--text-field-padding);
        width: 165px;
        font-size: 1.25rem;
        font-size: var(--text-field-font-size);
    }

    .em-areas-of-practice-label {
        -ms-flex-item-align: center;
        align-self: center;
        font-size: 1.0625rem;
        font-size: var(--text-field-label-font-size);
    }

    .em-aop-hidden {
        display: none;
        overflow: hidden;
    }

    .em-aop-collapsible--is-underlined {
        font-size: 20px;
        margin-bottom: 2.25rem;
        margin-bottom: var(--spacing-medium);
        border-bottom: 1px solid #e6e8eb;
        border-bottom: var(--esp-streamlined-border-style);
        padding-bottom: 1.125rem;
        padding-bottom: var(--spacing-small);
    }

    .em-aop-collapsible--is-underlined .em-see-aop {
        font-size: 1.25rem;
        font-size: var(--font-size-p);
        margin-left: 0.75rem;
        margin-left: var(--spacing-xsmall);
        text-align: left;
    }

    .em-aop-collapsible--is-underlined .em-add-selected {
        margin: 45px 0 17px 0;
    }

    .em-aop-collapsible--title-and-arrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}
