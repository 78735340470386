.c-link {
    display: inline-flex;
    display: inline-block;

    &.display-inline {
        display: inline;
    }
    &.is-disabled {
        pointer-events: none;
    }
}
.c-page__main {
    .c-link {
        text-decoration: none;
        color: $primary-500;
        &:hover {
            color: $primary-400;
            text-decoration: underline;
        }
    }
}
