html,
body {
    min-height: 100%;
    height: 100%;
    overflow-y: unset;
}

#root {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    min-height: 100vh;

    > .c-page-layout {
        min-height: 100%;
        flex: 1 1 100%;
    }
}
